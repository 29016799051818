<template>
  <div>
    <Panel>
      <slot name='header'></slot>
        <DataTable
          :cols="cols" :data="getData"
          :paginate="false"
          :searchable="false">

        <template v-slot:col-head-price>
          <span>Prix</span>
          <Tip text="Prix initial, hors code promos, turbos et remboursements"></Tip>
        </template>

        <template v-slot:status="col">
          <!-- <span>{{statuses[col.data] || col.data}}</span> -->
          <order-product-status
            :orderProduct="col.data"
            v-model="form.status[col.data.id]"></order-product-status>
          <button
            v-if="shouldDisplayCancelOrderEntryButton(col.data)"
            class="button-secondary"
            @click="cancelOrderEntry(col.data)"
          >
            Annuler (le produit et les payouts)
          </button>
          <button
            v-if="shouldDisplayRequestPayoutsButton(col.data)"
            class="button-secondary"
            @click="requestPayouts(col.data)"
          >
            Demander les virements <br><span style="color: red">(⚠️ veillez à d'abord les générer !)</span>
          </button>
          <button
            v-if="shouldDisplayRestoreOrderEntryButton(col.data)"
            class="button-secondary"
            @click="restoreOrderEntry(col.data)"
          >
            Restaurer (le produit et les payouts)
          </button>
        </template>

        <template v-slot:product="col">
          <Product :product="col.data"></Product>
        </template>

        <template v-slot:price="col">
          <Price :price="col.data"></Price>
          <br>
        </template>

        <template v-slot:quantity="col">
          <span>{{ col.data.quantity }}</span>
        </template>

        <template v-slot:asProPrice="col">
            <p style="color: #7baa86" v-if="col.data.as_pro_price && col.data.as_pro_price"> Prix pro 💼</p>
            <p v-else> -  </p>

        </template>

        <template v-slot:tracking="col">
          <TrackingCode :delivery="col.data"></TrackingCode>
        </template>

        <template v-slot:delivery="col">
          <Delivery :delivery="col.data"></Delivery>
        </template>

        <template v-slot:services="col">
          <template v-if="col.data.length">
            <OrderService
              v-for="service in col.data"
              :key="service.id"
              :service="service"></OrderService>
          </template>
          <span v-else>-</span>
        </template>

        <template v-slot:seller="col">
          <User :user="col.data"></User>
        </template>

        <template v-slot:actions="data">
          <DangerousButton
            v-if="showAction('update-status', data.row)"
            @click="updateStatus(data.row.id)">Mettre à jour</DangerousButton>
          <DangerousButton
            v-if="shouldDisplayRevertStatusButton(data.row.id) && showAction('cancel-abort', data.row)"
            @click="revertStatus(data.row.id)">Annuler l'annulation</DangerousButton>
          <a
            v-if="showAction('download-label', data.row)"
            class="button-secondary"
            :href="labelLink(data.row)"
            target="_blank">Télécharger l'étiquette</a>
<!--          <button-->
<!--            v-if="showAction('refund')"-->
<!--            class="button-secondary"-->
<!--            @click="refund('refund', data.row)">Rembourser</button>-->

<!--          <EarlyAccess>-->
            <button
              class="button-secondary"
              @click="display({ view: 'option_section', data: data.row})">Details</button>
<!--          </EarlyAccess>-->

<!--          <button-->
<!--            v-if="showAction('update-delivery')"-->
<!--            class="button-secondary"-->
<!--            @click="updateDelivery(data.row)">Modifier la livraison</button>-->
          <router-link
            v-if="showAction('view-sale')"
            :to="`/orders/${data.row.order_id}/sales/${data.row.id}`">Vue vendeur</router-link>
          <router-link
            v-if="showAction('view-order')"
            :to="`/orders/${data.row.order_id}`">Vue acheteur</router-link>
        </template>
      </DataTable>
    </Panel>
    <div class="wrapper" v-if="canDisplay('option_section')">
      <div class="container-bo option-section" >
        <div class="flex-1">
          <div class="container-bo">
            <Panel class="order-actions">

              <h4>
                {{ productTitle }} <br/>
                {{ selectedOrderProduct.product.sku }}

              </h4>
              <span class="button-primary flex-1" @click="updateDeliveryOrderProduct('update-delivery-order-product', selectedOrderProduct)">MODIFIER LA LIVRAISON</span>
              <!-- Button hide at the moment -->
<!--              <span class="button-primary background-stand-by flex-1" v-if="selectedOrderProduct.status !== 'STAND_BY'" @click="updateOrderProductStatusOrder(selectedOrderProduct.id, 'STAND_BY')">METTRE EN STAND BY</span>-->
              <span class="button-primary background-stand-by flex-1" v-if="selectedOrderProduct.status === 'STAND_BY'" @click="revertOrderProductStatus(selectedOrderProduct.id)">RELANCER LA COMMANDE</span>
              <span class="button-danger flex-1" @click="refund('new-refund', selectedOrderProduct)">REMBOURSER</span>

              <span class="button-danger flex-1"
                    v-if="selectedOrderProduct.status !== 'ABORTED'"
                    @click="refund('new-cancel-order', selectedOrderProduct)">ANNULER LA COMMANDE</span>
              <AirtableButton table="logistics"
                            :prefill="airtableLogisticsPrefill"></AirtableButton>
              <!-- <span class="button-danger flex-1" @click="display(false)">ANNULER PRODUIT</span> -->
              <!-- <span class="button-secondary flex-1" @click="display(false)">EDITER LE SPLIT</span> -->
            </Panel>
            <Panel class="flex-3 order-history">
              <money-flow-table
                :orderId="selectedOrderProduct.order_id"
                :orderProductId="selectedOrderProduct.id"></money-flow-table>
            </Panel>
            <Panel class="flex-1 order-seller-infos" v-if="seller">
              <User :user="seller" :full="true"></User> <br>
              <div>
                <b>Commission initiale (TTC)</b>
                <span>{{baseCommission || 'N/A'}}</span>
              </div>
              <div>
                <b>Facturation TVA</b>
                <span>{{vatBillingStatus}}</span>
              </div>
              <div>
                <b>Commission actuelle (TTC)</b>
                <span>{{currentCommission || 'N/A'}}</span>
              </div>
              <div>
                <b>Manager</b>
                <User :user="manager" :only="['firstname']"></User>
              </div>
              <div>
                <b>🚚 Adresse de pickup</b>
                <Address :address="pickupAddress"></Address>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
::v-deep td.col-product {
  text-align: left;
  padding-left: 40px;
  max-width: 300px;
}

.button-primary, .button-danger {
  max-height: 28px;
}

.background-stand-by {
  background: #efde9c;
}

.order-actions {
  display: flex;
  flex-direction: column;
  flex-grow: 0.10;
  padding-top: 5px;
  padding: 0;
  padding-bottom: 10px;
}

.order-actions > h4 {
  border-bottom: 1px solid #cecece;
  margin-top: 10px;
  padding: 5px;
  padding-left: 20px;
}

.order-actions > span.flex-1 {
  margin: 10px 20px;
  flex: 1;
  text-align: center;
}

::v-deep td.col-actions button, ::v-deep td.col-actions a.button-secondary {
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}
</style>

<script>
import constants from '../constants';
import DataTable from '../data-table/DataTable.vue';
import RefundModal from './order/RefundModal.vue';
import NewRefundModal from './order/NewRefundModal.vue';
import UpdateDeliveryOrderProductModal from './order/UpdateDeliveryOrderProductModal.vue';
import UpdateDeliveryModal from './sale/UpdateDeliveryModal.vue';
import OrderProductStatus from '../domain/order-product/OrderProductStatus.vue';
import OrderService from '../core/OrderService.vue';
import MoneyFlowTable from '../money-flow/MoneyFlowTable.vue';
import {hasRole} from '../auth';
import * as sales from '../api/sales';
import * as orders from '../api/orders';
import * as day from 'dayjs';

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    shopOrders: {
      type: Array,
      required: false
    },
    order: {
      type: Array,
      required: false
    },
    actions: {
      type: Array,
      required: false,
      default() {
        return ['refund', 'view-sale'];
      },
    },
  },

  data() {
    return {
      statuses: constants.orderProductStatuses,
      // shop: null,
      displayed: {
        refund_section: false,
        option_section: false
      },
      selectedOrderProduct: null,
      cols: {
        status: {
          title: "Statut",
          getter(row) {
            return row;
          }
        },
        product: {
          title: "Produit",
        },
        price: {
          title: "Prix",
        },
        asProPrice: {
          title: "Type",
          getter(row) {
            return row;
          }
        },
        delivery: {
          title: "Livraison",
        },
        quantity: {
          title: "Qté",
          getter(row) {
            return row;
          },
        },
        tracking: {
          title: "Suivi",
          getter(row) {
            return row.delivery;
          },
        },
        services: {
          title: "Services",
          getter(row) {
            return row.order_services;
          },
        },
        seller: {
          title: "Vendeur",
          getter(row) {
            return row.product.created_by;
          },
        }
      },

      form: {
        status: {},
      },
    }
  },
  computed: {
    shop() {
      console.log(this.shopOrders)
      return this.shopOrders.filter(e => {
        return e.products.filter(e => e.id === this.selectedOrderProduct.id).length > 0;
      })[0]
    },
    seller() {
      return ((this.selectedOrderProduct || {}).product || {}).created_by || false;
    },
    vatBillingStatus() {
      if (!this.shopOrder) {
        return;
      }

      return this.shopOrder.vat_billing_status;
    },
    baseCommission() {
      if (!this.shop) {
        return;
      }

      return `${(this.shop.commission * 100).toFixed(2)}%`;
    },
    manager() {
      return this.selectedOrderProduct.product.shop.account_manager;
    },
    currentCommission() {
      if (!this.selectedOrderProduct.split) {
        return;
      }

      const commission = this.selectedOrderProduct.split.marketplace.value / this.selectedOrderProduct.price.value * 100;
      return `${commission.toFixed(2)}%`;
    },
    pickupAddress() {
      try {
        return this.selectedOrderProduct.product.delivery_option_set.pickup_address;
      } catch(e) {
        return
      }
    },
    canDisplay() {
      return (value) => this.displayed[value];
    },
    airtableLogisticsPrefill() {
      return {
        'Order Num': this.selectedOrderProduct.order_number,
        'Request date': day().format('D/M'),
        'SKU': this.selectedOrderProduct.product.sku,
      };
    },
    productTitle() {
      var title = this.selectedOrderProduct.product.title.substring(0, 33);
      title += this.selectedOrderProduct.product.title.length > 33 ? ' ...' : '';
      return title;
    }
  },
  methods: {
    shouldDisplayRevertStatusButton(status) {
      const disallowedStatuses = [
        'delivered',
        'payouts-in-transit',
        'payouts-requested',
        'transfer_processed',
        'transfer_requested',
      ];

      return !disallowedStatuses.includes(status.toLowerCase());
    },
    cancelOrderEntry(orderEntry) {
      orders.cancelOrderEntry(orderEntry.id)
        .then(() => {
          window.location.reload();
        }).catch((error) => {
          alert(error.message);
        });
    },
    requestPayouts(orderEntry) {
      orders.requestPayouts(orderEntry.id)
        .then(() => {
          window.location.reload();
        }).catch((error) => {
          alert(error.message);
        });
    },
    restoreOrderEntry(orderEntry) {
      orders.restoreOrderEntry(orderEntry.id)
        .then(() => {
          window.location.reload();
        }).catch((error) => {
          alert(error.message);
        });
    },
    // Sadly, this cannot be an actual "computed". It's alright, it's alright... I'll try to breath nonetheless. Is that light that I'm seeing at the end of the tunnel ? Am I having a stroke ? Is anybody here ? Hello ?
    shouldDisplayCancelOrderEntryButton(orderEntry) {
      const currentStatus = orderEntry.status.toLowerCase();
      const allowedStatuses = [
        'delivered',
        'payouts-requested',
      ];

      return allowedStatuses.includes(currentStatus);
    },
    shouldDisplayRestoreOrderEntryButton(orderEntry) {
      const allowedStatus = 'aborted';

      return orderEntry.status.toLowerCase() === allowedStatus;
    },
    shouldDisplayRequestPayoutsButton(orderEntry) {
      const allowedStatus = 'delivered';

      return orderEntry.status.toLowerCase() === allowedStatus;
    },
    getData: async function* () {
      yield {
        rows: this.list,
      }
    },

    display(value) {
      console.log('display from order', value);
      if(typeof value === 'string') {
        this.displayed[value] = true;
      }

      if(typeof value === 'object' ) {
        console.log('the type is an object')
        this.displayed[value.view] = true;
        this.selectedOrderProduct = value.data;
      }
    },

    refund(template, data) {
      console.log('refund', template, data)
      if(template === 'new-cancel-order') {
        this.$modal.show('new-refund', {
          orderProduct: data,
          order: this.order,
          cancelOrder: true
        });
      } else {
        this.$modal.show(template, {
          orderProduct: data,
          order: this.order
        });
      }


    },

    updateDeliveryOrderProduct(template, data) {
      this.$modal.show(template, {
        orderProduct: data,
      });
    },

    updateOrderProductStatusOrder(id, isStandBy) {
      sales.updateOrderProduct(id, {
        'status' : isStandBy
      }).then(() => {
        window.location.reload()
      }).catch(() => {
        alert("erreur lors de la mise en stand by de la commande");
      })

    },

    revertOrderProductStatus(id) {
      sales.revertOrderProductStatus(id)
              .then(() => {
        window.location.reload()
      }).catch(() => {
        alert("erreur lors de la mise à joru du status de la commande");
      })
      window.location.reload()
    },

    updateDelivery(orderProduct) {
      this.$modal.show('update-delivery', {
        orderProduct,
      });
    },

    updateStatus(id) {
      sales
      .updateOrderProduct(id, {
        status: this.form.status[id],
      })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        alert("erreur lors de la mise à jour");
      })
    },

    revertStatus(id) {
      sales
      .revertOrderProductStatus(id)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        alert("erreur lors de la mise à jour");
      });
    },

    labelLink(orderProduct) {
      return orderProduct.delivery.label_link;
    },

    showAction(action, orderProduct) {
      if (action === 'cancel-abort') {
       return orderProduct.status === 'ABORTED';
      }

      if (action === 'update-status') {
        if (!(
          this.form.status[orderProduct.id]
          && this.form.status[orderProduct.id] !== orderProduct.status
        )){
          return false;
        }

        if (
          this.form.status[orderProduct.id] === 'TRANSFER_PROCESSED'
          && !hasRole('ROLE_FINANCE')
        ) {
          return false;
        }

        return true;
      }

      if (action === 'download-label' && !orderProduct.delivery.label_link) {
        return false;
      }

      return this.actions.indexOf(action) > -1;
    },
  },

  beforeMount() {
    this.$modal.register('refund', RefundModal);
    this.$modal.register('update-delivery', UpdateDeliveryModal);
    this.$modal.register('new-refund', NewRefundModal);
    this.$modal.register('update-delivery-order-product', UpdateDeliveryOrderProductModal);
  },

  components: {
    DataTable,
    OrderProductStatus,
    OrderService,
    MoneyFlowTable
  },
}
</script>

<template>
  <div>
    <span>{{product.sku}}</span>
    <span> - </span>
    <span>
      <Country :iso2="product.country"></Country>
    </span>
    <span>
      <a :href="`/#!/moderation/products/edit/${product.id}`">{{product.title}}</a>
    </span>
  </div>
</template>

<style scoped>
div {
  display: inline-block;
}
</style>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
  },

  computed: {
    title() {
      const title = this.product.i18n['fr'].title;

      if (title.length > this.max) {
        return `${title.substring(0, this.max)}...`
      }

      return title;
    }
  }
}
</script>
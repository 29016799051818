import axios from 'axios';
import { getJwtToken, getToken } from '../auth'

axios.interceptors.request.use(config => {
  if (config.url.match(/\/login_check$/)) {
    return config;
  }

  const regex = /^(https?:\/\/)?(api\.selency(-staging)?\.com|api\.selency\.local)\/.*/;

  if (regex.test(config.url)) {
    config.headers.authorization = 'Bearer ' + getJwtToken();

    return config;
  }

  const token = getToken();
  config.headers.authorization = token;

  return config;
});

import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        accent: "#4B9C91" ,
        blueCent:"#66B4D2",
        error:"#CA5C54",
        background: "#E5E5E5"
      },
    },
  },
});

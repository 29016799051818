 <template>
    <div>
        <form @change="emitData()">
            <div class="row margin-top-label">
                <div class="col-xs-12 col-md-6">
                    <span class="label">
                        Titre de la livraison
                    </span>
                    <input v-model="form.title" type="text" placeholder="Donnez un nom à la livraison">
                </div>
                <div class="col-xs-12 col-md-6">
                    <span class="label">
                        Prix
                    </span>
                    <input v-model="form.price" type="number">
                </div>
            </div>
            <div class="row margin-top-label">
                <div class="col-xs-12 col-md-6">
                    <span class="label">Distance</span>
                    <select v-model="form.distance">
                        <option></option>
                        <option value="- 15 km">- 15 km</option>
                        <option value="15 - 100 km">15 - 100 km</option>
                        <option value="+ 100 km">+ 100 km</option>
                    </select>
                </div>
                <div class="col-xs-12 col-md-6">
                    <span class="label">Délais</span>
                    <select v-model="form.delay">
                        <option></option>
                        <option value="express">express</option>
                        <option value="standard">standard</option>
                    </select>
                </div>
            </div>
            <div class="row margin-top-label">
                <div class="col-xs-12 col-md-6">
                    <span class="label">Nouvelle destination</span>
                    <vue-google-autocomplete
                            id="map"
                            types="(regions)"
                            ref="address"
                            classname="form-control"
                            placeholder="Destination"
                            v-on:placechanged="getAddressData"
                    >
                    </vue-google-autocomplete>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  components: {VueGoogleAutocomplete},
  data() {
    return {
      form: {
        title: null,
        price: null,
        distance: null,
        delay: null,
        address: null,
        placeResultData: null,
        id: null
      }
    }
  },
  methods: {
    emitData() {
      this.$emit('input', this.form)
    },

    getAddressData: function (addressData, placeResultData, id) {
      this.form.address = addressData;
      this.form.placeResultData = placeResultData;
      this.form.id = id;
    }
  },
  name: "PersonalDelivery"
}
</script>

<style scoped>
.label {
    display: block;
    font-weight: bold;
}

.margin-top-label {
    margin-top: 10px;
}

input, select {
    width: 100%;
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DataTable", {
        attrs: {
          data: _vm.getData,
          cols: _vm.cols,
          searchable: false,
          actionable: true,
          paginate: false
        },
        scopedSlots: _vm._u([
          {
            key: "amount",
            fn: function(col) {
              return [_c("Price", { attrs: { price: col.data } })]
            }
          },
          {
            key: "txn",
            fn: function(col) {
              return [
                _c(
                  "a",
                  { attrs: { href: _vm.txnLink(col.data), target: "_blank" } },
                  [_vm._v(_vm._s(col.data.txn))]
                )
              ]
            }
          },
          {
            key: "created_at",
            fn: function(col) {
              return [_c("Date", { attrs: { value: col.data } })]
            }
          },
          {
            key: "type",
            fn: function(col) {
              return [_c("span", [_vm._v(_vm._s(col.data))])]
            }
          },
          {
            key: "gateway",
            fn: function(col) {
              return [
                _c("span", [_vm._v(_vm._s(_vm.gateways[col.data] || col.data))])
              ]
            }
          },
          {
            key: "actions",
            fn: function(data) {
              return [
                _vm.editable(data.row)
                  ? _c(
                      "button",
                      {
                        staticClass: "button-secondary",
                        on: {
                          click: function($event) {
                            return _vm.open(data.row)
                          }
                        }
                      },
                      [_vm._v("Modifier")]
                    )
                  : _c("span", [_vm._v("-")])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "tbody",
      [
        _vm._l(_vm.fields, function(field, name) {
          return [
            _c("tr", { key: "heading-" + name }, [
              _c("td", { attrs: { colspan: "4" } }, [
                _c("span", [_vm._v(_vm._s(field.title))])
              ])
            ]),
            _vm._v(" "),
            _vm._l(field.value, function(split, index) {
              return _c("tr", { key: name + "-" + index }, [
                _c("td", [_c("Price", { attrs: { price: split.seller } })], 1),
                _vm._v(" "),
                _c(
                  "td",
                  [_c("Price", { attrs: { price: split.marketplace } })],
                  1
                ),
                _vm._v(" "),
                _c("td", [_c("Price", { attrs: { price: split.total } })], 1),
                _vm._v(" "),
                _c("td", [
                  split.label === "seller-booster-delivery"
                    ? _c("span", [_vm._v("Turbo")])
                    : _c("span", [_vm._v(_vm._s(split.label))])
                ])
              ])
            }),
            _vm._v(" "),
            _vm.input(name)
              ? _c("tr", { key: "input-" + name }, [
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.form["" + name].seller.value,
                          expression: "form[`${name}`].seller.value",
                          modifiers: { number: true }
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.form["" + name].seller.value },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form["" + name].seller,
                            "value",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.form["" + name].marketplace.value,
                          expression: "form[`${name}`].marketplace.value",
                          modifiers: { number: true }
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.form["" + name].marketplace.value
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form["" + name].marketplace,
                            "value",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm._m(1, true),
                  _vm._v(" "),
                  _vm._m(2, true)
                ])
              : _vm._e()
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Part vendeur")]),
        _vm._v(" "),
        _c("th", [_vm._v("Part Selency")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("input", { attrs: { type: "text", disabled: "" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("input", { attrs: { type: "text", placeholder: "Commentaire" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
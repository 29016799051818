<template>
  <div class="wrapper">
    <h1>Préférences</h1>
    <form @submit="apply">
      <div>
        <textarea
          v-model="json"
          placeholder="Coller ici un fichier de configuration" spellcheck="false"></textarea>
      </div>
      <div>
        <button class="button-primary" type="submit">Appliquer 👨‍💻</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
.wrapper {
  margin-left: 50px;
}

textarea {
  width: 700px;
  height: 400px;
  font-family: monospace;
  padding: 10px;
  margin-bottom: 30px;
}
</style>

<script>
import { get, put } from '../store';

export default {
  data() {
    return {
      json: get('_preferences', false) || '',
    }
  },

  methods: {
    apply(event) {
      event.preventDefault();

      let json = '';

      try {
        json = JSON.parse(this.json);
      } catch (exception) {
        alert('configuration invalide');
        return;
      }

      put('_preferences', json);
      window.location.href = window.location.origin;
    }
  }  
}
</script>
<template>
  <div class="sidebar" :class="collapsed ? 'collapsed':''">
    <h2 @click="collapse">
      <img id="logo" v-if="!collapsed" src='/assets/img/selency.png'/>
      <img id="logo" v-else src='/assets/img/selency_chair.png'/>
      <div v-if="warning" class="environment-warning">
        <span>{{env}}</span>
      </div>
    </h2>
    <div v-for="(props, route) in routes" :key="`route-${route}`">
      <div v-if="hasRoleFor(route)">
        <a
        :value="route"
        class="nav-1" v-bind:class="selected === route ? 'active':''"
        :dest="props._dest"
        :destType="props._dest_type"
        @click="select">{{ collapsed ? route.split(' ')[0] : route }}</a>
        <div v-if="!props._dest && selected === route && !collapsed">
          <div v-for="(props, route) in props" :key="`route-${route}`">
            <a
              v-if="route !== '_role'"
              :value="route"
              class="nav-2"
              :dest="props._dest"
              :destType="props._dest_type"
              @click="select">{{ route }}</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!collapsed" class="footer">
      <a @click="logout">déconnexion</a>
    </div>
  </div>
</template>

<style scoped>
.sidebar {
  flex: 0 0 200px;
  min-height: 100vh;
  text-align: center;
  background-color: white;
  color: #222;
  box-shadow: rgba(0, 0, 0, 0.05) 4px 0 4px;
  z-index: 1;
  user-select: none;
}

.sidebar h2 {
  cursor: pointer;
}

.sidebar.collapsed h2 .environment-warning {
  font-size: 18px;
}

.sidebar.collapsed {
  max-width: 60px;
}

.sidebar.collapsed #logo {
  width: 30px;
  height: auto;
}

.sidebar #logo {
  width: 115px;
  height: 30px;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  display: block;
  text-align: left;
}

a.nav-1 {
  padding: 15px 0;
  padding-left: 20px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  font-size: 16px;
}

a.nav-2 {
  padding: 10px 10px;
  padding-left: 40px;
  font-size: 13px;
}

.footer {
  opacity: 0.4;
  font-size: 14px;
  margin-top: 20px;
  width: 100%;
}

.footer a {
  text-align: center;
}

/* .sidebar a.nav-1.active {

} */
</style>

<script>
import Routes from './routing/sidebar-routes.json';
import { logout, getRoles } from './auth';
import * as store from './store';
import config from './config';
import Mousetrap from 'mousetrap';

export default {
  data() {
    return {
      warning: config.warning(),

      collapsed: false,

      selected: '',
    }
  },

  beforeMount() {
    const pref = store.get('_preferences');
    if (pref && pref.sidebarCollapsed !== undefined) {
      this.collapsed = pref.sidebarCollapsed;
    }
  },

  mounted() {
    Mousetrap.bind('x', () => {
      this.collapse();
    });
  },

  computed: {
    env() {
      if (config.env === 'preprod') {
        return 'PP';
      }

      if (config.env === 'production') {
        return 'PROD';
      }

      return config.env;
    },

    routes() {
      const routes = Object.keys(Routes);

      const preferences = store.get('_preferences');

      if (preferences && preferences.favoriteRoutes) {
        const order = preferences.favoriteRoutes;

        routes.sort((a) => {
          if (order.indexOf(a) > -1) {
            return -1;
          }

          return 1;
        });
      }

      const ordered = {};

      for (let route of routes) {
        ordered[route] = Routes[route];
      }

      return ordered;
    }
  },

  methods: {
    collapse() {
      this.collapsed = !this.collapsed;
    },

    hasRoleFor(route) {
      if (!Routes[route]._role) {
        return true;
      }

      if (getRoles().indexOf(Routes[route]._role) > -1) {
        return true;
      }

      return false;
    },

    select(event) {
      if (this.collapsed) {
        this.collapsed = false;
      }

      let dest = event.target.getAttribute('dest');
      const route = event.target.getAttribute('value');
      const destType = event.target.getAttribute('destType') || 'legacy-frame';

      if (dest) {
        dest = dest.replace('${admin}', config.environments[config.env].admin);
        this.$root.$emit('navigation.request', {
          dest,
          destType,
        });

        return;
      }

      if (route === this.selected) {
        this.selected = '';
      } else {
        this.selected = route;
      }
    },

    logout() {
      logout().then(() => {
        this.$root.$emit('auth.logout');
      });
    },
  }
}
</script>

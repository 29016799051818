<template>
  <Modal>
    <template v-slot:panel-content>
      <h1>Ajouter des virements</h1>
      <div v-for="(target, i) in ['orderProduct', 'delivery']" :key="i">
        <h2>{{({'orderProduct': 'Produit', 'delivery': 'Livraison'})[target]}}</h2>
        <div v-for="(party, i) in ['marketplace', 'seller']" :key="i">
          <h3>Part {{({'marketplace': 'Selency', 'seller': 'Vendeur'})[party]}}</h3>
          <div class="container-bo">
            <div>
              <label for="">Montant</label>
              <input type="number" step="0.01" v-model="form[target][party]">
            </div>
            <div>
              <label for="">Description</label>
              <input type="number" step="0.01">
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <DangerousButton :disabled="true">
          <span>Ajouter</span>
        </DangerousButton>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
.actions {
  margin: 20px 0;
  margin-top: 40px;
}
</style>

<script>
var sum = function(entity, receiver) {
  var remainingAmount = entity.split[receiver].value;

  entity.discounts.forEach(function(discount) {
    if (discount.split[receiver]) {
      remainingAmount -= discount.split[receiver].value;
    }
  });

  entity.refunds.forEach(function(refund) {
    if (refund.split[receiver]) {
      remainingAmount -= refund.split[receiver].value;
    }
  });

  entity.cashout_payments.filter(function (cashoutPayment) {
    var marketplace = receiver === 'marketplace' && cashoutPayment.recipient.is_marketplace;
    var seller = receiver === 'seller' && !cashoutPayment.recipient.is_marketplace;

    if ('offline' !== cashoutPayment.gateway && 'settled' === cashoutPayment.status) {
      return false;
    }

    return (cashoutPayment.status === 'processed' || cashoutPayment.status === 'settled') && (marketplace || seller);
  }).forEach(function(cashoutPayment) {
    remainingAmount -= cashoutPayment.amount.value;
  });

  return Math.max(0, remainingAmount);
};

export default {
  props: ['orderProduct'],

  data() {
    return {
      form: {
        orderProduct: {
          seller: sum(this.orderProduct, 'seller'),
          marketplace: sum(this.orderProduct, 'marketplace'),
        },
        delivery: {
          seller: sum(this.orderProduct.delivery, 'seller'),
          marketplace: sum(this.orderProduct.delivery, 'marketplace'),
        },
        orderServices: []
      },
      inputs: {

      }
    }
  }
}
</script>

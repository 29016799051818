<template>
  <div>
        <h2>🚚 Livraison Selency </h2>
        <div class="row" v-if="!shippingTool">
          <p>
            <span v-if="isHandledByUrbantz"><b>🚚 Géré par:</b> Urbantz</span>
            <span v-else>Non renseignée dans les services de livraisons (shipping tool, urbantz)</span>
            <br/>
            <b>🚚 Transporteur :</b>
            <template v-if="shipper">
              <span> {{ shipperDisplayName }} </span>
              <br/>
              <label for="cars">Changer de transporteur :</label>
              <i>changer le transporteur de cette commande va supprimer dans le service de livraison toutes les informations de logistique actuelles (dates d'enlèvement et de livraison, commentaires du transporteur actuel), puis recréer une tâche avec votre nouveau transporteur et les informations de la commande</i><br/>
              <select v-model="selectedShipper">
                <option></option>
                <option v-for="(shipper, key) in availableShippers" :value="shipper" :key="key">{{ shipper.toUpperCase() }}</option>
              </select>
              <input type="submit" value="Changer de transporteur" style="margin-left: 12px" @click="updateShipper()">
            </template>
            <span v-else>❌ Non affecté</span>
          </p>
        </div>
        <div class="row" v-if="shippingTool">
            <div class="col-xs-5 col-xs-offset-2">
                <h3>Pickup</h3>
                <p>
                    <b>🔎 Issue :</b>
                    <a :href="getShippingToolIssueLink(shippingTool.pickup.issue_id)" target="_blank">
                        {{ shippingTool.pickup.issue_id }}
                    </a>
                </p>
                <p><b>ℹ️ Statut :</b> {{ shippingTool.pickup.status }}</p>
                <p>
                    <b>🚚 Transporteur :</b>
                    <span v-if="shippingTool.pickup.service"> {{ shippingTool.pickup.service }} </span>
                    <span v-else>❌ Non affecté</span>
                </p>
            </div>
            <div class="col-xs-5">
                <h3>Delivery</h3>

                <p>
                    <b>🔎 Issue :</b>
                    <a :href="getShippingToolIssueLink(shippingTool.shipping.issue_id)" target="_blank">
                        {{ shippingTool.shipping.issue_id }}
                    </a>
                </p>
                <p><b>ℹ️ Statut :</b> {{ shippingTool.shipping.status }}</p>
                <p>
                    <b>🚚 Transporteur :</b>
                    <span v-if="shippingTool.shipping.service"> {{ shippingTool.shipping.service }} </span>
                    <span v-else>❌ Non affecté</span>
                </p>
            </div>
        </div>
        <div class="row" v-if="shippingTool">
            <div class="col-xs-10 col-xs-offset-1">
                <hr>
                <h3>Notes :</h3>
                <ul>
                    <li>
                        Remplacer ou annuler une livraison selency peut avoir un impact sur l'envoi des mails ou la frise dans l'interface marchand.
                        Veuillez vérifier que les issues listées ci-dessus correspondent bien aux positions utilisées actuellement.
                    </li>
                    <li>
                        <i>D'autres infos sont à venir ici...</i>
                    </li>
                </ul>
            </div>
        </div>
  </div>
</template>

<script>
import * as monolithClient from '../api/monolith'

export default {
  props: [
      'shippingTool',
      'delivery'
  ],

  data() {
    return {
      selectedShipper: '',
    }
  },

  methods: {
    getShippingToolIssueLink(issueId) {
        return 'https://shipping.selency.com/issues/'+issueId;
    },
    async updateShipper() {
      if (this.selectedShipper) {
        try {
            await monolithClient.updateOrderEntryDelivery({
              shipper: this.selectedShipper
            }, this.delivery.id);
        } catch (error) {
          console.error(`Error on delivery update: ${error}`);

          throw new Error('Error on delivery update');
        }
      }
    }
  },
  computed: {
    isHandledByUrbantz() {
        return this.delivery && this.delivery.isHandledByUrbantz;
    },
    shipper() {
        return this.delivery && this.delivery.bySelencyBestShipper;
    },
    shipperDisplayName() {
      return this.shipper.toUpperCase();
    },
    availableShippers() {
      const shippers = ['vuac', 'ccv'];

      return shippers.filter(shipper => shipper !== this.shipper);
    }
  },
  components: {
  },
}
</script>

<template>
  <div>
    <Tabs :list="tabs.list" v-model="tabs.active">
      <template v-slot:helper>
        <span @click="toggleBalances">Afficher les soldes intermédiaires</span>
      </template>
    </Tabs>
    <data-table
      v-if="transactions.length"
      :cols="cols" :data="getData"
      :searchable="false"
      :actionable="false"
      :paginate="false"
      :scrollTop="false"
      :helpers="helpers"
      :key="`money-flow-table-${tabs.active}`">
        <template v-slot:date="col">
          <Date :value="col.data"></Date>
        </template>

        <template v-slot:from="col">
          <span>{{col.data.ns}}/{{col.data.id}}</span>
        </template>

        <template v-slot:event="col">
          <span v-if="col.data.title">
            <span v-if="!col.data.delivery">{{col.data.title}}</span>
            <Delivery
              v-if="col.data.delivery"
              :delivery="col.data.delivery"
              :price="false"
              :tip="false"></Delivery>
          </span>
          <span v-else>{{col.data}}</span>
        </template>

        <template v-slot:to="col">
          <span>{{col.data.ns}}/{{col.data.id}}</span>
        </template>

        <template v-slot:target="col">
          <span>{{col.data}}</span>
        </template>

        <template v-slot:amount="col">
          <Price :price="col.data" :colors="true"></Price>
        </template>

        <template v-slot:row-helper="data">
          <div class="container-bo">
            <div class="flex-1"></div>
            <div class="flex-1 container-bo">
              <div class="flex-1">
                <b>Produit</b>
                <Price :price="getBalance(['sale'], data.index)" :colors="true"></Price>
              </div>
              <div class="flex-1">
                <b>Livraison</b>
                <Price :price="getBalance(['delivery'], data.index)" :colors="true"></Price>
              </div>
              <div class="flex-1">
                <b>Total</b>
                <Price :price="getBalance(['sale', 'delivery'], data.index)" :colors="true"></Price>
              </div>
            </div>
            <div class="flex-1"></div>
          </div>
        </template>

        <template v-slot:col-head-actions>
          <span></span>
        </template>

        <!-- <template v-slot:actions="data">
          <button class="button-text" @click="data.helper">
            <span>🧾</span>
          </button>
        </template> -->
      </data-table>
  </div>
</template>

<style scoped>
::v-deep table td.col-event {
  text-align: left;
}

@keyframes highlight {
  from {
    background: initial;
  }
  30% {
    background: rgba(255, 255, 0, 0.05);
    /* background: rgba(0, 0, 0, 0.03); */
  }
  50% {
    background: rgba(255, 255, 0, 0.05);
    /* background: rgba(0, 0, 0, 0.03); */
  }
  to {
    background: initial;
  }
}

::v-deep table tr.row-helper:not(:last-child) {
  animation: highlight 1.2s;
  /* background: rgba(0, 0, 0, 0.03); */
}
</style>

<script>
// import sample from '../test/moneyflow-1.json';
import DataTable from '../data-table/DataTable.vue';
import {getAudit} from '../api/orders';

export default {
  props: {
    orderId: {
      type: String,
      required: true,
    },
    orderProductId: {
      type: String,
      required: true,
    },
  },

  data() {
    // const party = 'marketplace';

    const data = {
      tabs: {
        list: {
          customer: "Acheteur",
          seller: "Vendeur",
          marketplace: "Selency",
        },
        active: 'customer',
      },

      helpers: 'last',

      transactions: [],

      cols: {
        date: {
          title: "Date",
          getter(row) {
            return row.ts;
          },
        },
        // from: {
        //   title: "Source",
        // },
        // to: {
        //   title: "Destination",
        // },
        // parties: {
        //   title: "Parties",
        //   getter(row) {
        //     return row.metadata.parties;
        //   },
        // },
        event: {
          title: "Évènement",
          getter(row) {
            const event = row.metadata.event;

            if (!event) {
              return;
            }

            switch (event) {
              case "payment":
                return "Paiement";
                break;

              case "split":
                return "Paiement";
                break;

              case "split-edition":
                return "Édition du split";
                break;

              case "delivery":
                return {
                  title: "Livraison",
                  delivery: row.metadata.delivery,
                };
                break;

              case "discount":
                return `Code promo "${row.metadata.discount.code}"`;
                break;

              case "booster":
                return "Turbo";
                break;

              case "refund":
                if (this.party !== 'customer') {
                  return "Remboursement";
                }

                if (row.metadata.parties.indexOf('marketplace') > -1) {
                  return "Remboursement (part Selency)";
                } else {
                  return "Remboursement (part vendeur)";
                }
                break;

              default:
                return event;
                break;
            }
          }
        },
        target: {
          title: "Cible",
          getter(row) {
            const target = row.metadata.target;

            if (!target) {
              return;
            }

            switch (target) {
              case "delivery":
                return "Livraison";
                break;

              case "sale":
                return "Produit";
                break;

              default:
                return target;
                break;
            }
          }
        },
        amount: {
          title: "Flux",
          getter(row) {
            let sign = 1;

            if (this.party === 'customer' && row.from.id === 'order') {
              sign = -1;
            }

            if (this.party === 'seller' && row.from.id === 'seller') {
              sign = -1;
            }

            if (this.party === 'seller' && row.from.id === 'delivery' && row.metadata.event === 'booster') {
              sign = -1;
            }

            if (this.party === 'marketplace' && row.from.id === 'delivery' && row.metadata.event === 'booster') {
              sign = 0;
            }

            if (this.party === 'seller' && row.from.id === 'sale' && row.metadata.event === 'refund' && row.metadata.parties.indexOf('seller') > -1) {
              sign = -1;
            }

            if (this.party === 'marketplace' && row.from.id === 'marketplace') {
              sign = -1;
            }

            if (this.party === 'marketplace' && row.from.id === 'sale' && row.metadata.event === 'refund' && row.metadata.parties.indexOf('marketplace') > -1) {
              sign = -1;
            }

            return {
              ...row.amount,
              value: row.amount.value * sign,
            };
          }
        },
      },
    };

    data.cols.amount.getter = data.cols.amount.getter.bind(this);
    data.cols.event.getter = data.cols.event.getter.bind(this);

    return data;
  },

  methods: {
    getData: async function* () {
      yield {
        rows: this.transactions.filter(e => {
          return (e.metadata.parties || []).indexOf(this.party) > -1;
        }).filter(e => e.amount.value !== 0),
      }
    },

    toggleBalances() {
      if (this.helpers === 'last') {
        this.helpers = 'all';
      } else {
        this.helpers = 'last';
      }
    },

    getBalance(targets, end) {
      return this.transactions
      .filter(e => {
        return (e.metadata.parties || []).indexOf(this.party) > -1;
      })
      .filter(e => e.amount.value !== 0)
      .slice(0, end + 1)
      .filter(e => {
        return targets.indexOf(e.metadata.target) > -1;
      })
      .map(e => this.cols.amount.getter(e))
      .reduce((acc, e) => {
        return {
          ...acc,
          value: parseFloat((acc.value + e.value).toFixed(2)),
        }
      }, {value: 0, currency: 'EUR'});
    },
  },

  computed: {
    party() {
      return this.tabs.active;
    },
  },

  async mounted() {
    // const transactions = sample.content;
    const transactions = await getAudit(this.orderId);

    this.transactions = transactions.filter(e => {
      return e.from.ns === this.orderProductId || e.to.ns === this.orderProductId;
    });
  },

  components: {
    DataTable,
  },
}
</script>

<template>
  <div class="wrapper">
    <div v-if="order">
      <div class="container-bo">
        <h1 class="flex-1">Commande {{order.order_number}}</h1>
        <div class="container-bo flex-1">
          <Panel class="flex-1">
            <Tags :list="order.tags" :editable="true" @state="updateTags"></Tags>
          </Panel>
          <Panel class="flex-1">
            <AirtableButton table="payments" :prefill="airtablePrefill"></AirtableButton>
          </Panel>
        </div>
      </div>
      <div class="container-bo">
        <div class="flex-1">
          <div class="container-bo order-infos">
            <Panel class="flex-1">
              <span><b>Statut</b></span>
              <span>{{statuses[order.status] || order.status}}</span>
              <br>
              <span><b>Date</b></span>
              <Date :value="order.created_at"></Date>
              <br>
              <span><b>Source</b></span>
              <span>{{ order.source }}</span>
              <h3>Acheteur</h3>
              <User :user="customer" :full="true"></User>
              <br><br>
            </Panel>
            <Panel class="flex-1">
              <h3>🚚 Adresse de livraison</h3>
              <Address
                v-if="order.shipping_address"
                :address="order.shipping_address"></Address>
              <span v-else>N/A</span>
            </Panel>
            <Panel class="flex-1">
              <h3>🧾 Adresse de facturation</h3>
              <Address
                v-if="order.billing_address"
                :address="order.billing_address"></Address>
              <span v-else>N/A</span>
            </Panel>
            <Panel class="flex-1" v-if="order.fee != null">
              <h3>Frais de service</h3>
              <span><b>Montant : </b></span>{{order.fee.price.value}}€<br>
              <br>
              <br>
              <DangerousButton
                v-if="order.fee.refunds.length === 0"
                @click="refundFee(order)">Rembourser</DangerousButton>
              <span v-if="order.fee.refunds.length > 0">Frais de service remboursés</span>
            </Panel>
          </div>
        </div>
      </div>
<!-- <<<<<<< Updated upstream // Rotha buttons
      <div class="container-bo">
        <div class="flex-1">
          <Panel class="flex-1">
            <div>
              <button class="button-primary"
                      @click="openUpdateDeliveryModal"
              >
                MODIFIER LA LIVRAISON
              </button>
            </div>
            <div>
              <button class="button-primary">
                METTRE LA COMMANDE EN STAND-BY
              </button>
            </div>
            <div>
              <button class="button-primary">
                REMBOURSER
              </button>
            </div>
            <div>
              <button class="button-primary">
                ANNULER LE PRODUIT DE LA COMMANDE
              </button>
            </div>
            <div>
              <button class="button-primary">
                AIRTABLE PROBLEME PAIEMENT
              </button>
            </div>
            <div>
              <button class="button-primary">
                AIRTABLE PROBLEME LOGISTIQUE
              </button>
            </div>
            <div>
              <button class="button-primary">
                VUE VENDEUR
              </button>
            </div>
          </Panel>
        </div>
      </div>

      <Panel>
        <h2>
          <span>Récap commande - total</span>
          <Prices :list="order.prices" only="total_price_current"></Prices>
        </h2>
        <OrderProducts :list="order.products"></OrderProducts>
======= -->
      <!-- <Panel> -->
        <OrderProducts :list="order.products" :shop-orders="order.shop_orders" :order="order">
<!--        <OrderProducts :list="order.products" :shop-orders="order.shop_orders">-->
          <template v-slot:header>
            <h2>
              <span>Récap commande - total</span>
              <Prices :list="order.prices" only="total_price_current"></Prices>
            </h2>
          </template>
        </OrderProducts>
      <!-- </Panel> -->
      <Panel v-if="showPaymentRequests">
        <h2>Demandes de paiement</h2>
        <div v-if="order.payment_requests.length">
          <span>Demande envoyée le </span>
          <Date :value="order.payment_requests.slice(-1)[0].created_at"></Date>
        </div>
        <div v-else>
          <DangerousButton @click="requestPayment">Envoyer la demande</DangerousButton>
        </div>
        <br>
      </Panel>
      <Panel v-if="showPaymentRequests">
        <h2>Demandes de paiement</h2>
        <div v-if="order.payment_requests.length">
          <span>Demande envoyée le </span>
          <Date :value="order.payment_requests.slice(-1)[0].created_at"></Date>
        </div>
        <div v-else>
          <DangerousButton @click="requestPayment">Envoyer la demande</DangerousButton>
        </div>
        <br>
      </Panel>
      <Panel>
        <h2>Transactions bancaires</h2>
        <OrderTransactions :transactions="order.transactions"></OrderTransactions>
      </Panel>
      <Panel>
        <h2>📝 Commentaires</h2>
        <Comments></Comments>
        <div class='global-actions'>
          <button
            class='button-secondary btn-add-comment'
            @click='addComment'
          >
            + Ajouter un commentaire
          </button>
        </div>
      </Panel>
    </div>
    <div v-else-if="!error">
      <span class="loading-dots">Chargement</span>
    </div>
    <div v-else>
      <Error details="impossible de charger cette commande"></Error>
    </div>
  </div>
</template>

<style scoped>

.wrapper {
  padding: 20px 50px;
  background: #f7f7f7;

}
.panel {
  background: #fefefe;
  box-shadow: none;
  border: 1px solid #e5e5e5;
}
.global-actions {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: right;
    padding-right: 30px;
}
.btn-add-comment {
    padding: 16px 33px;
    font-weight: 600;
}
</style>

<script>
import * as orders from '../api/orders';
import OrderTransactions from './OrderTransactions.vue';
import OrderProducts from './OrderProducts.vue';
import constants from '../constants';
import Comments from '../core/Comments.vue'
import AddCommentModal from './sale/AddCommentModal.vue'
import ViewCommentModal from './sale/ViewCommentModal.vue'
import Mousetrap from 'mousetrap'

export default {
  data() {
    return {
      statuses: constants.orderStatuses,
      order: null,
      selectedOrderProduct: {},
      error: false
    }
  },
  computed: {
    airtablePrefill() {
      return {
        'N° Commande': this.order.order_number,
        'Lien BO': window.location.href,
      };
    },

    showPaymentRequests() {
      return this.order.transactions.filter(e => {
          return e.gateway === 'sepa-ct' && e.status === 'pending';
      }).length > 0;
    },

    customer() {
      return this.order.created_by;
    },
  },

  methods: {
    addComment() {
      this.$modal.show('add-comment', {orderId: this.order.id || this.$route.params.id, orderProductId: null});
    },
    refund() {
      this.$modal.show('new-refund', {
        orderProduct: this.selectedOrderProduct,
      });
    },
    refundFee(order) {
      orders
        .createRefund(order.id, {
          'orderFeeRefund': true,
          'orderFeeId': order.fee.id
        })
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          alert("échec de la création du remboursement");
        });
    },

    updateTags(list) {
      orders
      .updateOrder(this.order.id, {
        tags: list,
      })
      .catch(e => {
        console.log(e);
      });
    },

    requestPayment() {
      orders
      .requestPayment(this.order.id)
      .catch(() => {
        alert("échec de la demande de paiement");
      });
    },

    updateDelivery() {
      this.$modal.show('update-delivery', {
        orderProduct: this.selectedOrderProduct,
      })
    }
  },

  mounted() {
    const id = this.$route.params.id;

    this.$modal.register('add-comment', AddCommentModal);
    this.$modal.register('view-comment', ViewCommentModal);

    this.$root.$on('comment-added', () => {
      this.$store.dispatch('loadComments', {orderId: this.order.id, orderProductId: null})
    });

    Mousetrap.bind(['shift+space'], () => {
      this.$modal.show('add-comment', {orderId: this.order.id || this.$route.params.id, orderProductId: null});
    });

    orders
    .getOrder(id)
    .then(order => {
      this.order = order;
      this.$store.dispatch('loadComments', {orderId: this.order.id, orderProductId: null})
    })
    .catch(() => {
      this.error = true;
    });
  },

  components: {
    Comments,
    OrderTransactions,
    OrderProducts,
  },
}
</script>

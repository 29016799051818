import Vue from 'vue';
import Vuex from 'vuex';
import tree from './modules/tree';
import config from "../config";
import * as monolithClient from '../api/monolith'

Vue.use(Vuex);

const state = {
  snackbar: null,
  text: "",
  color: "",
  icon: "",
  details: "",
  category: {},
  comments: []
};
const mutations = {
  setSnackbar(state, isDisplay) {
    state.snackbar = isDisplay;
  },
  setText(state, isDisplay) {
    state.text = isDisplay;
  },
  setColor(state, isDisplay) {
    state.color = isDisplay;
  },
  setIcon(state, isDisplay) {
    state.icon = isDisplay;
  },
  setDetails(state, details) {
    state.details = details;
  },
  setCategories(state, category) {
    state.category = category;
  },
  setComments(state, comments) {
    state.comments = comments;
  },
};

const types = {
  success: { color: "success", icon: "mdi-check" },
  info: { color: "info", icon: "mdi-info" },
  warning: { color: "warning" },
  error: { color: "error", icon: "mdi-alert-outline" },
};

const actions = {
  show({ commit }, { text, type, details }) {
    commit("setText", text);
    commit("setColor", types[`${type}`].color);
    commit("setIcon", types[`${type}`].icon);
    commit("setDetails", details);
    commit("setSnackbar", true);
  },
  loadCategories({commit}) {
    axios.get(config.url(`/api/v1/categories/tree?locale=fr&version=1&facets=true`)).then(result => {
      commit("setCategory", result.data);
    }).catch(error => {
      throw new Error(`API ${error}`);
    });
  },
  loadComments({commit}, {orderId, orderProductId}) {
    monolithClient.getComments(orderId, orderProductId).then(result => {
      commit("setComments", result.items);
    }).catch(error => {
      throw new Error(`API ${error}`);
    });
  }
};
const getters = {
  snackbar: (state) => state.snackbar,
  text: (state) => state.text,
  color: (state) => state.color,
  icon: (state) => state.icon,
  details: (state) => state.details,
  categories: (state) => state.categories,
  comments: (state) => state.comments,
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    tree
  },
});

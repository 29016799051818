<template>
  <status-selector :entity="cashoutPayment" :states="states" @input="input"></status-selector>
</template>

<script>
import StatusSelector from '../state/StatusSelector.vue';
import states from './states';

export default {
  props: ['cashoutPayment'],

  data() {
    return {
      states,
    };
  },

  methods: {
    input(e) {
      this.$emit('input', e);
    },
  },

  components: {
    StatusSelector,
  },
}
</script>

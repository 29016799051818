<template>
  <div v-if="price">
    <span :class="colors ? sign : ''">
      <span>{{fixed ? value.toFixed(2) : value}}</span>
      <span>{{price.currency}}</span>
    </span>
  </div>
  <div v-else>
    <span>0</span>
    <span>EUR</span>
  </div>
</template>

<style scoped>
div {
  display: inline;
}

.positive {
  color: var(--classic-green);
}
/*
.positive::before {
  content: "+ ";
} */

.negative {
  color: var(--classic-pink);
}

/* .negative::before {
  content: "- ";
} */
</style>

<script>
export default {
  props: [
    'price',
    'fixed',
    'colors',
  ],
  computed: {
    sign() {
      if (this.price.value > 0) {
        return 'positive';
      } else if (this.price.value < 0) {
        return 'negative';
      }
    },
    value() {
      return this.price.value || 0;
    },
  }
}
</script>

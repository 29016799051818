<template>
  <Modal>
    <template v-slot:panel-content>
      <h2 class="text-center">{{ modalTitle }}</h2>
      <div class="refund-resume new-split-table">
        <div class="block flex-1 ">
          <h3>Récapulatif</h3>
        </div>
        <div class="block flex-3 inline-box">
          <div class="flex-1 bg-gray first">
            <a :href="`/#!/moderation/products/edit/${orderProduct.product.id}`">{{ productTitle }}</a> <br>
            {{ orderProduct.product.sku }}
          </div>
          <div class="flex-1 bg-gray">
            Prix produit <br>
            {{ amountProduct }} {{ orderProduct.price.currency }}
          </div>
          <div class="flex-1 bg-gray">
            Livraison {{ deliveryLabel }} <br>
            {{ amountDelivery }} {{ orderProduct.delivery.price.currency }}
          </div>
          <div class="flex-2 bg-gray">
            Total <br>
            {{ amountTotal }} {{ orderProduct.price.currency }}
          </div>
        </div>
      </div>

      <NewSplitTable :entity="orderProduct"
                     :type="'product'"
                     :inputs="['refunds']"
                     :displayDetails="displayDetails"
                     :refund="isRefund.products"
                     v-model="form.orderProduct"></NewSplitTable>

       <NewSplitTable :entity="orderProduct.delivery"
                      :type="'delivery'"
                      :inputs="['refunds']"
                      :displayDetails="displayDetails"
                      :refund="isRefund.deliveries"
                      v-model="form.delivery"></NewSplitTable>

        <div v-for="service in orderProduct.order_services" :key="service.id">
          <NewSplitTable  :entity="service"
                          :type="'service'"
                          :inputs="['refunds']"
                          v-model="form.orderServices[service.id]"
                          :refund="isRefund.services"></NewSplitTable>
        </div>
      <form @submit="e => e.preventDefault()">
        <div v-if="!cancelOrder">
          <label>Notifier le remboursement par email</label>
          <input type="checkbox" v-model="notify">
        </div>
        <div v-if="cancelOrder">
          <label>Notifier le Vendeur de l'annulation par email</label>
          <input type="checkbox" v-model="notify_seller">
        </div>
        <div v-if="cancelOrder">
          <label>Notifier  l'acheteur de l'annulation par email</label>
          <input type="checkbox" v-model="notify_customer">
        </div>
        <div>
          <button class="button-secondary" @click="refund('products')">Tout Rembourser (Sans livraison)</button>
          <!-- <button class="button-primary" @click="displayDetails = !displayDetails">Plus de details</button> -->
          <br />
          <button class="button-secondary" @click="refund('products,deliveries,services', 'ABORTED')">Tout Rembourser (Avec livraison)</button> <br />
          <label for="remb">Raison de remboursement </label><br>
          <select name="remb" v-if="options" v-model="comment">
            <option value="">Aucun</option>
            <option v-for="(option, key) in options" v-bind:value="option" :key="`new_${key}`">
              {{ option.reason }}
            </option>
            <option value="" disabled>---- Anciennes options ----</option>
            <option v-for="(option, key) in optionsOld" v-bind:value="option" :key="`old_${key}`">
              {{ option.reason }}
            </option>
          </select>

          <div v-if="cancelOrder">
            <label for="remb">New Product Status </label><br>
            <select name="remb" v-model="productNewStatus">
              <option value="">Aucun</option>
              <option v-for="(option, key) in productStatus" v-bind:value="option.value" :key="key">
                {{ option.label }}
              </option>
            </select>
          </div>

          <!-- <h1>{{ seller.email }} {{ customer.email }}</h1> -->
        </div>

        <div style="text-align: right;">
          <!-- {{ options }} -->
          <div style="margin-bottom: 10px">
            <label v-if="!cancelOrder">j'ai bien lu et relu, je suis prêt.e, à confirmer <br> definitivement le remboursement</label>
            <label v-if="cancelOrder">j'ai bien lu et relu, je suis prêt.e, à confirmer <br> definitivement l'annulation</label>
            <input type="checkbox" v-model="confirm_refund"> <br>
            <span v-for="(value, k) in error" :key="k" class="text-error">
              {{ value }} <br>
            </span>
          </div>

          <button class="button-secondary" @click="close">
            <span>Annuler</span>
          </button>
          <button class="button-primary" :disabled="!confirm_refund && loading" @click="confirm">
            <span v-if="!cancelOrder && !loading">Rembourser</span>
            <span v-if="cancelOrder && !loading">Annuler la commande</span>
            <span class="loading-dots" v-if="loading" >.</span>
          </button>

        </div>
      </form>
    </template>
  </Modal>
</template>

<style scoped>
form {
  margin-top: 20px;
}

.inline-box {
  display: flex;
  flex-direction: row;
  /* padding: 5px; */
}
.text-error {
  color: rgb(129, 46, 46);
}
.first {
  padding-left: 10px;
}
/* ::v-deep .new-split-table > .block {
  background: red;
} */

.refund-resume {
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.bg-gray {
  padding-top: 5px;
  background-color: #efefef;
}

button.dangerous-button {
  width: 100%;
  margin-top: 20px;
}

</style>

<script>
import { getCurrentUser } from '../../auth';
import * as orders from '../../api/orders';
import * as sales from '../../api/sales';
import * as productFactory from '../../api/product';
import refundOptions from './refund-options.json';
import NewSplitTable from './NewSplitTable.vue';

export default {
  // props: ['orderProduct'],
  props: {
    order: {
      type: Object,
      require: false
    },
    orderProduct: {
      type: Object,
      required: true
    },
    cancelOrder: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      modalTitle: this.cancelOrder ? 'ANNULER LA COMMANDE' : 'EFFECTUER UN REMBOURSEMENT 💸',
      productNewStatus: 'published',
      loading: false,
      notify: true,
      notify_customer: true,
      notify_seller: true,
      statusToUpdate: false,
      options: refundOptions['options'],
      optionsOld: refundOptions['optionsOld'],
      productStatus: [
        {value: 'new', label: 'Nouveau'},
        // {value: 'pending_review', label: 'En attente'},
        // {value: 'awaiting_changes', label: 'awaiting_changes'},
        // {value: 'accepted', label: 'accepted'},
        {value: 'rejected', label: 'Rejeté'},
        // {value: 'awaiting_crop', label: 'awaiting_crop'},
        {value: 'published', label: 'Publié'},
        {value: 'sold_out', label: 'Epuisé'},
        {value: 'removed_by_seller', label: 'Supprimé par le vendeur'},
        {value: 'removed', label: 'Supprimé'},
        // {value: 'pending_expertize', label: 'pending_expertize'},
      ],
      displayDetails: false,
      comment: false,
      isRefund: {
        products: false,
        deliveries: false,
        services: false
      },
      confirm_refund: false,
      error: {},
      form: {
        orderProduct: null,
        delivery: null,
        orderServices: {}
      },
    }
  },
  created () {
    // console.log(this.orderProduct);
  },
  computed: {
    user() {
      return getCurrentUser();
    },
    productTitle() {
      var title = this.orderProduct.product.title.substring(0, 33);
      title += this.orderProduct.product.title.length > 33 ? ' ...' : '';
      return title;
    },
    customer() {
      return (this.order || {}).created_by || {};
    },
    seller() {
      return (this.orderProduct.product || {}).created_by || {};
    },
    deliveryLabel() {
      switch (this.orderProduct.delivery.delivery_offer.provider_type) {
        case 'CUSTOM':
            return 'par Vendeur'
          break;
        case 'SELENCY':
            return 'par Selency'
          break;

        default:
            return ''
          break;
      }
    },
    amountProduct() {
      return this.sum(this.orderProduct, 'split') + this.sum(this.orderProduct, 'discounts') ;
    },
    amountDelivery() {
      return this.orderProduct.delivery.delivery_offer.price.value
    },
    amountTotal() {
      return this.amountProduct + this.amountDelivery;
    }
  },
  methods: {
    updateProductStatus(data) {
      return new Promise((resolve, reject) => {
        if(data.status === '') {
          return resolve();
        }
        return productFactory
          .updateStatus(data)
          .then(() => resolve())
          .catch(() => reject());
      })
    },
    updateOrderStatus() {

    },
    close() {
      this.$root.$emit('modal.close');
    },
    refund(type, status = false) {
      //apply refund
      type.split(',').map(field => {
        this.isRefund[field] = true;
      })

      if(status) {
        this.statusToUpdate = status;
      }

    },
    sum(data, field, sign = 1) {
      if(field !== 'split') {
        return (sign ? 1 : -1) * data[field].reduce((acc, e) => ['marketplace', 'seller'].includes(e) ? acc + data[field][e].value : acc, 0)
      }

      return Object.keys(data[field]).reduce((acc, e) => ['marketplace', 'seller'].includes(e) ? acc + data[field][e].value : acc, 0)
    },

    shouldRefundFees() {
      var countValidOrderProduct = this.order.products.filter(x => x.status !== "ABORTED" && x !== 'UNAVAILABLE').length;

      if (countValidOrderProduct !== 1) {
        console.log("shoud not refund fees : countValidOrderProduct = " + countValidOrderProduct);
        return false;
      }

      if (!this.cancelOrder && this.statusToUpdate !== 'ABORTED') {
        console.log("should not refund fees : cancelOrder = " + this.cancelOrder + ", statusToUpdate = " + this.statusToUpdate);
        return false;
      }

      if (
        !this.form.orderProduct ||
        this.form.orderProduct.refunds.seller.value !== this.orderProduct.split.seller.value ||
        this.form.orderProduct.refunds.marketplace.value !== this.orderProduct.split.marketplace.value
      ) {
        console.log("shoud not refund fees : product refund amount is not full ");
        return false;
      }

      return true;
    },

    confirm() {
      this.loading = true;
      if(((this.comment || {}).reason || '') === '') {
        this.loading = false;
        this.$set(this.error, 'reason', 'Vous devez sélectionner une raison.')
        return;
      }
      var notifyCancel = {};
      var data = {};

      let comment = JSON.stringify({
        comment: this.comment.reason,
        type: this.comment.type,
        ops_user: this.user.user_id,
        ops_name: this.user.firstname + ' .' + (this.user.lastname[0] || ''),
      });

      if (this.form.orderProduct && (this.form.orderProduct.refunds.seller.value > 0 || this.form.orderProduct.refunds.marketplace.value)) {
        data.orderProducts = [];
        data.orderProducts.push({
          currentCount: (this.orderProduct.refunds || []).length,
          id: this.orderProduct.id,
          split: {
            seller: {
              value: this.form.orderProduct.refunds.seller.value,
              currency: 'EUR',
            },
            marketplace: {
              value: this.form.orderProduct.refunds.marketplace.value,
              currency: 'EUR',
            },
          },
          comment: comment,
          toNotify: this.notify,
        });
      }

      if (this.form.delivery && (this.form.delivery.refunds.seller.value > 0 || this.form.delivery.refunds.marketplace.value > 0)) {
        data.deliveries = [];
        data.deliveries.push({
          currentCount: (this.orderProduct.delivery.refunds || []).length,
          id: this.orderProduct.delivery.id,
          split: {
            seller: {
              value: this.form.delivery.refunds.seller.value > 0 ? this.form.delivery.refunds.seller.value : 0,
              currency: 'EUR',
            },
            marketplace: {
              value: this.form.delivery.refunds.marketplace.value > 0 ? this.form.delivery.refunds.marketplace.value : 0,
              currency: 'EUR',
            },
          },
          comment: comment,
          toNotify: this.notify,
        });
      }

      if (Object.keys(this.form.orderServices).length > 0) {
        var orderservices = Object.keys(this.form.orderServices).map(id => {
          var service = this.form.orderServices[id];
          if (service.refunds.marketplace.value === 0) {
            this.loading = false;
            return false;
          }
          return {
            currentCount: (service.refunds || []).length,
            id: id,
            split: {
              marketplace: {
                value: service.refunds.marketplace.value > 0 ? service.refunds.marketplace.value : 0,
                currency: 'EUR',
              },
              seller: {
                value: 0,
                currency: 'EUR',
              },
            },
            comment: comment,
            toNotify: this.notify,
          }
        }).filter(s => s);

        if(Array.isArray(orderservices) && orderservices.length > 0) {
          data.orderServices = orderservices;
        }
      }

      if(this.statusToUpdate) {
        data.status = this.statusToUpdate;
      }

      if (this.shouldRefundFees() && this.order.fee) {
        console.log("should refund fees !");
        data.orderFeeRefund = true;
        data.orderFeeId = this.order.fee.id;
      }

      if(this.cancelOrder && (this.notify_customer || this.notify_seller)) {
        notifyCancel = {
          order_number: this.order.order_number,
          order_id: this.order.id,
          order_product_id: this.orderProduct.id,
          notify_seller: this.notify_seller,
          notify_customer: this.notify_customer,
          customer_email: this.customer.email || '',
          seller_email: this.seller.email || '',
          customer_locale: this.customer.locale || 'fr',
          seller_locale: this.seller.locale || 'fr'
        }
      }

      if(Object.keys(data).length > 0) {
        orders
        .createRefund(this.orderProduct.order_id, data)
        .then(() => {
          if(this.cancelOrder || this.statusToUpdate) {

            sales.updateOrderProduct(this.orderProduct.id, Object.assign(notifyCancel, {
              status: this.cancelOrder ? 'ABORTED' : this.statusToUpdate
            })).then(() => {
              this.updateProductStatus({
                sku: this.orderProduct.product.sku,
                status: this.productNewStatus
              }).then(() => {
                window.location.reload();
              }).catch(() => {
                alert('Impossible de mettre a jours le status du produit');
                window.location.reload();
              })
            });
          } else {
            window.location.reload();
          }
        })
        .catch(() => {
          this.loading = false;
          alert("échec de la création du remboursement");
        });
      } else if(Object.keys(data).length === 0 && (this.cancelOrder || this.statusToUpdate)) {

        sales.updateOrderProduct(this.orderProduct.id, Object.assign(notifyCancel, {
              status: this.cancelOrder ? 'ABORTED' : this.statusToUpdate
        })).then(() => {
          this.updateProductStatus({
            sku: this.orderProduct.product.sku,
            status: this.productNewStatus
          }).then(() => {
            window.location.reload();
          }).catch(() => {
            alert('Impossible de mettre a jours le status du produit');
            window.location.reload();
          })
        });
      }
    }
  },

  components: {
    NewSplitTable
  },
}
</script>

<template>
  <div>
    <div v-if="!auth.logged">
      <Login></Login>
    </div>
    <div v-else class="container-bo">
      <Sidebar></Sidebar>

      <Error
        v-if="!connection.online"
        icon="👴"
        text="Il semblerait que vous ne soyez pas connecté à internet"></Error>

      <div class="content" v-if="connection.online">
        <router-view v-if="navigation.currentType === 'new-page'"></router-view>

        <ModalView></ModalView>

        <LegacyFrame
          v-if="navigation.currentType === 'legacy-frame'"
          :page="navigation.current"></LegacyFrame>

        <Unicorn></Unicorn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  width: 100%;
}
</style>

<script>
import Login from './Login.vue';
import Sidebar from './Sidebar.vue';
import LegacyFrame from './LegacyFrame.vue';
import routes from './routing/routes';
import Unicorn from './routing/Unicorn.vue';

const NavigationTypes = {
  LegacyFrame: 'legacy-frame',
  NewPage: 'new-page',
};

export default {

  data() {
    return {
      connection: {
        online: navigator.onLine,
      },
      auth: {
        logged: false,
      },
      navigation: {
        current: '',
        currentType: '',
      },
    }
  },

  created() {
    this.$root.$on('auth.login', () => {
      this.auth.logged = true;
    });

    this.$root.$on('auth.logout', () => {
      this.auth.logged = false;
    });

    this.$root.$on('auth.unauthorized', () => {
      window.location.reload();
    });

    window.addEventListener('offline', () => {
      this.connection.online = false;
    });

    window.addEventListener('online', () => {
      this.connection.online = true;
    });

    this.$router.addRoutes(routes);
  },

  beforeMount() {
    if (window.location.pathname !== '/') {
      this.navigation.current = window.location.pathname;
      this.navigation.currentType = NavigationTypes.NewPage;
      return;
    }

    if (window.location.hash) {
      this.navigation.current = window.location.hash;
      this.navigation.currentType = NavigationTypes.LegacyFrame;
      return;
    }

    this.navigation.currentType = NavigationTypes.NewPage;
    this.$router.push('/dashboards');
  },

  mounted() {
    this.$root.$on('navigation.request', e => {
      if (e.destType && e.destType === 'replace-href') {
        window.location.href = e.dest;
        return;
      }

      if (e.destType && e.destType === NavigationTypes.NewPage) {
        this.navigation.currentType = e.destType;
        // this.navigation.current = e.dest;
        // window.history.pushState(e, '', e.dest);
        this.$router.push(e.dest);
        return;
      }

      this.navigation.current = e.dest;
      this.navigation.currentType = NavigationTypes.LegacyFrame;
      // window.history.pushState(e, '', e.dest);
      this.$router.push(e.dest);
    });

    this.$root.$on('navigation.legacy_frame_event', e => {
      if (e.hash !== window.location.hash) {
        window.history.pushState({
          dest: e.hash,
        }, '', e.hash);
      }
    });

    window.onpopstate = e => {
      this.navigation.current = e.state.dest;
    };
  },

  components: {
    Login,
    Sidebar,
    LegacyFrame,
    Unicorn,
  }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Tabs", {
        attrs: { list: _vm.tabs.list },
        scopedSlots: _vm._u([
          {
            key: "helper",
            fn: function() {
              return [
                _c("span", { on: { click: _vm.toggleBalances } }, [
                  _vm._v("Afficher les soldes intermédiaires")
                ])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.tabs.active,
          callback: function($$v) {
            _vm.$set(_vm.tabs, "active", $$v)
          },
          expression: "tabs.active"
        }
      }),
      _vm._v(" "),
      _vm.transactions.length
        ? _c("data-table", {
            key: "money-flow-table-" + _vm.tabs.active,
            attrs: {
              cols: _vm.cols,
              data: _vm.getData,
              searchable: false,
              actionable: false,
              paginate: false,
              scrollTop: false,
              helpers: _vm.helpers
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "date",
                  fn: function(col) {
                    return [_c("Date", { attrs: { value: col.data } })]
                  }
                },
                {
                  key: "from",
                  fn: function(col) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(col.data.ns) + "/" + _vm._s(col.data.id))
                      ])
                    ]
                  }
                },
                {
                  key: "event",
                  fn: function(col) {
                    return [
                      col.data.title
                        ? _c(
                            "span",
                            [
                              !col.data.delivery
                                ? _c("span", [_vm._v(_vm._s(col.data.title))])
                                : _vm._e(),
                              _vm._v(" "),
                              col.data.delivery
                                ? _c("Delivery", {
                                    attrs: {
                                      delivery: col.data.delivery,
                                      price: false,
                                      tip: false
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(col.data))])
                    ]
                  }
                },
                {
                  key: "to",
                  fn: function(col) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(col.data.ns) + "/" + _vm._s(col.data.id))
                      ])
                    ]
                  }
                },
                {
                  key: "target",
                  fn: function(col) {
                    return [_c("span", [_vm._v(_vm._s(col.data))])]
                  }
                },
                {
                  key: "amount",
                  fn: function(col) {
                    return [
                      _c("Price", { attrs: { price: col.data, colors: true } })
                    ]
                  }
                },
                {
                  key: "row-helper",
                  fn: function(data) {
                    return [
                      _c("div", { staticClass: "container-bo" }, [
                        _c("div", { staticClass: "flex-1" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-1 container-bo" }, [
                          _c(
                            "div",
                            { staticClass: "flex-1" },
                            [
                              _c("b", [_vm._v("Produit")]),
                              _vm._v(" "),
                              _c("Price", {
                                attrs: {
                                  price: _vm.getBalance(["sale"], data.index),
                                  colors: true
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-1" },
                            [
                              _c("b", [_vm._v("Livraison")]),
                              _vm._v(" "),
                              _c("Price", {
                                attrs: {
                                  price: _vm.getBalance(
                                    ["delivery"],
                                    data.index
                                  ),
                                  colors: true
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-1" },
                            [
                              _c("b", [_vm._v("Total")]),
                              _vm._v(" "),
                              _c("Price", {
                                attrs: {
                                  price: _vm.getBalance(
                                    ["sale", "delivery"],
                                    data.index
                                  ),
                                  colors: true
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-1" })
                      ])
                    ]
                  }
                },
                {
                  key: "col-head-actions",
                  fn: function() {
                    return [_c("span")]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2866489027
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
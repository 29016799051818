<template>
  <div class="wrapper">
    <Panel>
      <DataTable :data="data" :cols="cols" :error="handleError">
        <template v-slot:order_product_status="col">
          <span>{{c.orderProductStatuses[col.data] || col.data}}</span>
        </template>
        <template v-slot:created_by="col">
          <User :user="col.data"></User>
        </template>
        <template v-slot:shipping_address="col">
          <Country :iso2="col.data.country"></Country>
        </template>
        <template v-slot:prices="col">
          <Prices :list="col.data" only="total_price_current"></Prices>
        </template>
        <template v-slot:tags="col">
          <Tags :list="col.data"></Tags>
        </template>
        <template v-slot:created_at="col">
          <Date :value="col.data"></Date>
        </template>
        <template v-slot:marketplace="col">
          <b> {{ col.data }} </b>
        </template>
        <template v-slot:seller="col">
          <b> {{ col.data }} </b>
        </template>
        
        <template v-slot:orderNumber="col">
          <router-link :to="`/orders/${col.data}`" class="button-icon">{{ col.data }}</router-link>
          <!-- <a :href="`/#!/clients/orders/edit/${data.row.id}`" class="button-icon"><span>➜</span></a> -->
        </template>

        <template v-slot:actions="data">
          <select @change="transactionChange($event, data.row.refund)" v-if="data.row.has_cashout">
            <!-- <option value="" :selected="!getRefundStatus(data.row)">Aucun</option> -->
            <option value="cashout" :selected="getRefundStatus(data.row) === 'cashout'">Pending</option>
            <option value="pending" :selected="getRefundStatus(data.row) === 'success'">Recu</option>
          </select>
          <span v-if="!data.row.has_cashout"> - </span>
          <!-- <router-link :to="`/orders/${col.data}`" class="button-icon">{{ col.data }}</router-link> -->
          <!-- <a :href="`/#!/clients/orders/edit/${data.row.id}`" class="button-icon"><span>➜</span></a> -->
        </template>

        <template v-slot:pagination="pagination">
          <div class="data-table-pagination">
            <!-- TODO: Update the pagination for this page (remove the v-if)-->
            <div class="info" v-if="false">
              <span>affichage de </span>
              <span>{{pagination.size}}</span>
              <span> résultats sur </span>
              <span>
                <span v-if="pagination.total === Infinity" title="Un très très grand nombre">♾️</span>
                <span v-else-if="pagination.total === undefined"><i>un nombre bien mystérieux</i></span>
                <span v-else>{{pagination.total}}</span>
              </span>
            </div>
            <div class="buttons">
              <button
                v-if="pagination.hasBack()"
                @click="pagination.back"
                class="button-secondary">Précédent</button>
              <button
                v-if="pagination.hasNext()"
                @click="pagination.next"
                class="button-secondary">Suivant</button>
            </div>
          </div>
        </template>

        <template v-slot:loading-status="status">
          <div class="data-table-loading-status">
            <span v-if="!status.errored" class="loading">Chargement</span>
            <span v-else class="errored">Impossible de charger ces données 💥</span>
          </div>
        </template>
      </DataTable>
    </Panel>
  </div>
</template>

<style scoped>
.wrapper {
  padding: 20px 50px;
}

.status-dot {
  display: inline-block;
  margin: 0 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
}

.status-dot-paid {
  background-color: var(--classic-green);
}

.status-dot-canceled {
  background-color: var(--classic-pink);
}

.status-dot-pending_payment {
  background-color: var(--classic-yellow);
}

.loading-status {
  text-align: center;
  padding: 40px;
}

.loading-status .loading {
  opacity: 0.5;
}
</style>

<script>
import Panel from '../Panel.vue';
import DataTable from '../data-table/DataTable.vue';
import User from '../core/User.vue';
import Prices from '../core/Prices.vue';
import Date from '../core/Date.vue';
import * as orders from '../api/orders';
import constants from '../constants';
import flag from 'country-to-emoji-flag';

async function* getOrders(page, search) {
  const perPage = 25;

  do {
    const data = await orders.getOrderProductRefund(page * perPage, perPage, search);
    const total = data.total > 1000 ? Infinity : data.total;
    console.log('This IS data', data, search);
    let list = [];
    for (let value of Object.keys(data.list || {})) {
        
        if (data.list[value].product) {
          let p = data.list[value].product;
          p.transaction_count = (data.list[value].transaction || {}).length;
          p.transaction = (data.list[value].transaction || {});
          list.push(p);
        }
        if (data.list[value].delivery) {
          let d = data.list[value].delivery;
          d.transaction_count = (data.list[value].transaction || {}).length;
          d.transaction = (data.list[value].transaction || {});
          list.push(d);
        }
      }
    console.log('This IS data', list);
    yield {
      rows: list,
      total,
      perPage,
    };

    page += 1;
  } while (true);
}

export default {
  data() {
    var self = this;
    return {
      c: constants,

      orders: [],
      data: getOrders,
      cols: {
        type: {
          title: 'Type',
        },
        responsible: {
          title: 'Responsable',
          getter(row) {
            return self.getResponsible(row.refund.comment);
          }
        },
        ops: {
          title: "Ops",
          getter(row) {
            return self.getOps(row.refund.comment)
          }
        },
        sku: {
          title: "sku",
        },
        orderNumber: {
          title: "Numéro de commande",
          search: {
            type: "text",
          }
        },
        comment: {
          title: "Comment",
          getter(row) {
            return self.getComment(row.refund.comment);
          },
          search: {
            type: "select",
            options: {
              ...constants.refundReason,
              '---- Anciennes options ----': '---- Anciennes options ----',
              ...constants.refundReasonOld,
            },
          }
        },
        marketplace: {
          title: 'Selency',
          getter(row) {
            return (row.refund.split.marketplace || {}).value ? row.refund.split.marketplace.value + ' ' + row.refund.split.marketplace.currency : '-'
          }
        },
        seller: {
          title: 'Vendeur',
          getter(row) {
            return (row.refund.split.seller || {}).value ? row.refund.split.seller.value + ' ' + row.refund.split.seller.currency : '-'
          }
        },
        created_at: {
          title: "Date",
          getter(row) {
            return row.refund.created_at;
          },
        }
      },
    };
  },

  methods: {
    transactionChange(value, refund) {
      if(this.isJSON(refund.comment)) {
        let comment = JSON.stringify(Object.assign(JSON.parse(refund.comment), {status: value.target.value}));
        orders.updateRefund(refund.id, {
          comment
        }).then(r => {
          console.log(r);
        }).catch(e => {
          console.log(e)
        })
      }
    },
    flag(iso2) {
      return flag(iso2);
    },
    isJSON(str) {
      try {
        return (JSON.parse(str) && !!str);
      } catch (error) {
        return false;
      }
    },
    getResponsible(comment) {
      return this.getField(comment, 'type');
    },
    getOps(comment) {
      return this.getField(comment, 'ops_name');
    },
    getComment(comment) {
      return this.getField(comment, 'comment');
    },
    getRefundStatus(row) {
      var v =  this.getField(row.refund.comment, 'status', false);
      if(row.has_cashout && !v) {
        return 'cashout';
      }

      return v;
    },
    getField(data, field, defaultValue = '-') {
      if(this.isJSON(data)) {
        var json = JSON.parse(data)
        return json[field] || defaultValue;
      }

      return data || defaultValue;
    },

    handleError(e) {
      if (e.status && e.status === 401) {
        this.$root.$emit('auth.unauthorized');
      }
    },
  },

  components: {
    Panel,
    DataTable,
    User,
    Prices,
    Date,
  },
}
</script>


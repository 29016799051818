<template>
  <div>
<!--    {{ offers }}-->
  </div>
</template>

<script>
import * as delivery from '../../api/delivery';

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    params() {
      return {
        deliveryOptionSetId: this.product.delivery_option_set.id,
        productPrice: this.product.pricing.price.value,
        categoryPath: this.product.category.path,
        pickupZipcode: this.product.zipcode,
        pickupCountry: this.product.country,
      };
    }
  },
  data() {
    return {
      offers: [],
    };
  },

  mounted() {
    delivery
    .getDeliveryOffers(this.params)
    .then(data => {
      this.offers = data;
    });
  }
}
</script>

<template>
  <button class="dangerous-button button-danger" @click="click" :disabled="disabled" :state="state">
    <slot v-if="state === 0">
      <span>Confirmer</span>
    </slot>
    <slot name="confirm" v-if="state === 1 || state === 2 && !timeout">
      <span>{{ textConfirm || "Vraiment ?" }}</span>
    </slot>
    <slot name="timeout" v-if="state === 2 && timeout">
      <span>{{ textTimeout || "Un instant..." }}</span>
    </slot>
    <slot name="done" v-if="state === 3">
      <span>{{ textDone || "Effectué" }}</span>
    </slot>
  </button>
</template>

<script>
export default {
  props: {
    confirm: {
      type: Boolean,
      required: false,
      default: true,
    },
    textConfirm: {
      type: String,
      require: false,
    },
    timeout: {
      type: Number,
      required: false,
      default: 0,
    },
    textTimeout: {
      type: String,
      required: false,
    },
    textDone: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      state: 0,
      secondsLeft: 0,
    };
  },

  computed: {
    disabled() {
      return this.state === 3;
    }
  },

  methods: {
    click() {
      if (this.state === 0) {
        this.state++;
        return;
      }

      if (this.state === 1) {
        this.state++;

        setTimeout(() => {
          if (this.state === 2) {
            this.state++;
            this.$emit('click');
          }
        }, this.timeout * 1000);

        return;
      }

      if (this.state === 2) {
        this.state = 0;
        return;
      }
    },
  },
}
</script>

<template>
  <span
    class="seller-badge"
    :class="{
      'bg-pro': badgeLevel === 10,
      'bg-super-pro': badgeLevel === 20,
      'bg-pro-ambassador': badgeLevel === 30,
    }"
  >
    <img
      :src="`/assets/img/seller_${badgeImage}.svg`"
      class="seller-badge-image"
    >
    <span class="seller-badge-label">
      {{ badgeLabel }}
    </span>
  </span>
</template>

<style scoped>
.seller-badge {
  font-size: 0.825rem;
  line-height: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  padding: 3px 0.5rem;
  position: relative;
  top: 3px;
}

.bg-pro {
  background-color: #D3AE9D;
}

.bg-super-pro {
  background-color: #D3DFE2;
}

.bg-pro-ambassador {
  background-color: #EADAA9;
}

.seller-badge-image {
  height: 16px;
  width: 16px;
}

.seller-badge-label {
  line-height: 0.75rem;
  font-size: 0.875rem;
  margin-left: 0.25rem;
  margin-bottom: 0.125rem;
}
</style>

<script>

export default {
  props: {
    badgeLevel: {
      type: Number,
      required: true
    },
  },
  computed: {
    badgeLabel() {
      const badgeLabels = {
        10: 'Professionnel',
        20: 'Super Pro',
        30: 'Pro Ambassadeur',
      }

      return badgeLabels[this.badgeLevel]
    },
    badgeImage() {
      const badgeImage = {
        10: 'pro',
        20: 'super_pro',
        30: 'pro_ambassador',
      }

      return badgeImage[this.badgeLevel];
    }
  },
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-wrapper" }, [
    _c("div", { class: _vm.hasRole("ROLE_ADMIN") ? "admin" : "user" }, [
      _c("div", [
        _c(
          "a",
          { attrs: { href: "/#!/clients/list/edit/" + _vm.safeUser.user_id } },
          [
            _vm.show("firstname")
              ? _c("span", [_vm._v(_vm._s(_vm.safeUser.firstname) + " ")])
              : _vm._e(),
            _vm.show("lastname")
              ? _c("span", [_vm._v(_vm._s(_vm.safeUser.lastname))])
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.full && _vm.safeUser.email
          ? _c("span", [_vm._v(_vm._s(_vm.safeUser.email))])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    scopedSlots: _vm._u([
      {
        key: "panel-content",
        fn: function() {
          return [
            _c("h2", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.modalTitle))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "refund-resume new-split-table" }, [
              _c("div", { staticClass: "block flex-1 " }, [
                _c("h3", [_vm._v("Récapulatif")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "block flex-3 inline-box" }, [
                _c("div", { staticClass: "flex-1 bg-gray first" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/#!/moderation/products/edit/" +
                          _vm.orderProduct.product.id
                      }
                    },
                    [_vm._v(_vm._s(_vm.productTitle))]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.orderProduct.product.sku) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-1 bg-gray" }, [
                  _vm._v("\n          Prix produit "),
                  _c("br"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.amountProduct) +
                      " " +
                      _vm._s(_vm.orderProduct.price.currency) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-1 bg-gray" }, [
                  _vm._v(
                    "\n          Livraison " + _vm._s(_vm.deliveryLabel) + " "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.amountDelivery) +
                      " " +
                      _vm._s(_vm.orderProduct.delivery.price.currency) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-2 bg-gray" }, [
                  _vm._v("\n          Total "),
                  _c("br"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.amountTotal) +
                      " " +
                      _vm._s(_vm.orderProduct.price.currency) +
                      "\n        "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("NewSplitTable", {
              attrs: {
                entity: _vm.orderProduct,
                type: "product",
                inputs: ["refunds"],
                displayDetails: _vm.displayDetails,
                refund: _vm.isRefund.products
              },
              model: {
                value: _vm.form.orderProduct,
                callback: function($$v) {
                  _vm.$set(_vm.form, "orderProduct", $$v)
                },
                expression: "form.orderProduct"
              }
            }),
            _vm._v(" "),
            _c("NewSplitTable", {
              attrs: {
                entity: _vm.orderProduct.delivery,
                type: "delivery",
                inputs: ["refunds"],
                displayDetails: _vm.displayDetails,
                refund: _vm.isRefund.deliveries
              },
              model: {
                value: _vm.form.delivery,
                callback: function($$v) {
                  _vm.$set(_vm.form, "delivery", $$v)
                },
                expression: "form.delivery"
              }
            }),
            _vm._v(" "),
            _vm._l(_vm.orderProduct.order_services, function(service) {
              return _c(
                "div",
                { key: service.id },
                [
                  _c("NewSplitTable", {
                    attrs: {
                      entity: service,
                      type: "service",
                      inputs: ["refunds"],
                      refund: _vm.isRefund.services
                    },
                    model: {
                      value: _vm.form.orderServices[service.id],
                      callback: function($$v) {
                        _vm.$set(_vm.form.orderServices, service.id, $$v)
                      },
                      expression: "form.orderServices[service.id]"
                    }
                  })
                ],
                1
              )
            }),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function(e) {
                    return e.preventDefault()
                  }
                }
              },
              [
                !_vm.cancelOrder
                  ? _c("div", [
                      _c("label", [
                        _vm._v("Notifier le remboursement par email")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.notify,
                            expression: "notify"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.notify)
                            ? _vm._i(_vm.notify, null) > -1
                            : _vm.notify
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.notify,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.notify = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.notify = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.notify = $$c
                            }
                          }
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.cancelOrder
                  ? _c("div", [
                      _c("label", [
                        _vm._v("Notifier le Vendeur de l'annulation par email")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.notify_seller,
                            expression: "notify_seller"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.notify_seller)
                            ? _vm._i(_vm.notify_seller, null) > -1
                            : _vm.notify_seller
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.notify_seller,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.notify_seller = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.notify_seller = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.notify_seller = $$c
                            }
                          }
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.cancelOrder
                  ? _c("div", [
                      _c("label", [
                        _vm._v("Notifier  l'acheteur de l'annulation par email")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.notify_customer,
                            expression: "notify_customer"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.notify_customer)
                            ? _vm._i(_vm.notify_customer, null) > -1
                            : _vm.notify_customer
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.notify_customer,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.notify_customer = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.notify_customer = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.notify_customer = $$c
                            }
                          }
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "button-secondary",
                      on: {
                        click: function($event) {
                          return _vm.refund("products")
                        }
                      }
                    },
                    [_vm._v("Tout Rembourser (Sans livraison)")]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button-secondary",
                      on: {
                        click: function($event) {
                          return _vm.refund(
                            "products,deliveries,services",
                            "ABORTED"
                          )
                        }
                      }
                    },
                    [_vm._v("Tout Rembourser (Avec livraison)")]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "remb" } }, [
                    _vm._v("Raison de remboursement ")
                  ]),
                  _c("br"),
                  _vm._v(" "),
                  _vm.options
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.comment,
                              expression: "comment"
                            }
                          ],
                          attrs: { name: "remb" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.comment = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Aucun")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.options, function(option, key) {
                            return _c(
                              "option",
                              {
                                key: "new_" + key,
                                domProps: { value: option }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(option.reason) +
                                    "\n          "
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "", disabled: "" } }, [
                            _vm._v("---- Anciennes options ----")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.optionsOld, function(option, key) {
                            return _c(
                              "option",
                              {
                                key: "old_" + key,
                                domProps: { value: option }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(option.reason) +
                                    "\n          "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cancelOrder
                    ? _c("div", [
                        _c("label", { attrs: { for: "remb" } }, [
                          _vm._v("New Product Status ")
                        ]),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.productNewStatus,
                                expression: "productNewStatus"
                              }
                            ],
                            attrs: { name: "remb" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.productNewStatus = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("Aucun")
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.productStatus, function(option, key) {
                              return _c(
                                "option",
                                { key: key, domProps: { value: option.value } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(option.label) +
                                      "\n            "
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { "text-align": "right" } }, [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      !_vm.cancelOrder
                        ? _c("label", [
                            _vm._v(
                              "j'ai bien lu et relu, je suis prêt.e, à confirmer "
                            ),
                            _c("br"),
                            _vm._v(" definitivement le remboursement")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cancelOrder
                        ? _c("label", [
                            _vm._v(
                              "j'ai bien lu et relu, je suis prêt.e, à confirmer "
                            ),
                            _c("br"),
                            _vm._v(" definitivement l'annulation")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.confirm_refund,
                            expression: "confirm_refund"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.confirm_refund)
                            ? _vm._i(_vm.confirm_refund, null) > -1
                            : _vm.confirm_refund
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.confirm_refund,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.confirm_refund = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.confirm_refund = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.confirm_refund = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm._l(_vm.error, function(value, k) {
                        return _c(
                          "span",
                          { key: k, staticClass: "text-error" },
                          [
                            _vm._v("\n            " + _vm._s(value) + " "),
                            _c("br")
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button-secondary",
                      on: { click: _vm.close }
                    },
                    [_c("span", [_vm._v("Annuler")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button-primary",
                      attrs: { disabled: !_vm.confirm_refund && _vm.loading },
                      on: { click: _vm.confirm }
                    },
                    [
                      !_vm.cancelOrder && !_vm.loading
                        ? _c("span", [_vm._v("Rembourser")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cancelOrder && !_vm.loading
                        ? _c("span", [_vm._v("Annuler la commande")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.loading
                        ? _c("span", { staticClass: "loading-dots" }, [
                            _vm._v(".")
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import states from './states.json';

function getPossibleStates(current) {
  const results = [];

  for (let [, declaration] of Object.entries(states.transitions)) {
    if (declaration.from.indexOf(current) > -1) {
      results.push(declaration.to);
    }
  }

  // may be needed:
  // .filter((element, index, self) => self.indexOf(element) === index);
  return results;
}

export {
  getPossibleStates,
}

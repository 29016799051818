/**
 * Translate routes between legacy app and new app
 */

function toLegacy() {
  let match;

  if (match = window.location.href.match(/\/orders\/(\w+)$/), match) {
    return {
      href: `${window.location.origin}/#!/clients/orders/edit/${match[1]}`,
    };
  }

  if (match = window.location.href.match(/\/orders\/(\w+)\/sales\/(\w+)$/), match) {
    return {
      href: `${window.location.origin}/#!/trades/orders/edit/${match[1]}/product/${match[2]}`,
    };
  }
}

function toNew() {
  let match;

  if (match = window.location.href.match(/\/#!\/clients\/orders\/edit\/(\w+)$/), match) {
    return {
      href: `${window.location.origin}/orders/${match[1]}`,
      early: false,
    };
  }

  if (match = window.location.href.match(/\/#!\/trades\/orders\/edit\/(\w+)\/product\/(\w+)$/), match) {
    return {
      href: `${window.location.origin}/orders/${match[1]}/sales/${match[2]}`,
      early: false,
    };
  }
}

export default {
  toLegacy,
  toNew,
}

<template>
  <div class="panel">
    <div
      style="float:right; font-size: 0.8em; font-style: italic;"
      v-if="adminNote && showAdminNote"
    >
      {{ adminNote }}
    </div>
    <slot></slot>
  </div>
</template>

<style scoped>
.panel {
  margin: 20px;
  padding: 10px 20px;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: rgba(0, 0, 0, 0.05) 4px 4px 4px;
}

h2 {
  font-size: 20px;
}
</style>

<script>

import { hasRole } from './auth';

export default {
  props: {
    adminNote: {
      type: String,
      required: false,
    },
  },
   data() {
    return {
      showAdminNote: hasRole("ROLE_ADMIN")
    }
  }
}

</script>

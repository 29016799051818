<template>
  <div>
    <div>
      <select v-model="form.selected" style="padding: 5px 0; height: 47px" @change="optionSelected()">
        <option value="">Sélectionner une nouvelle livraison</option>
        <option v-for="(offer, index) in offers" :key="index" :value="offer">
          <slot name="offer" :offer="offer"></slot>
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import * as delivery from '../../api/delivery';

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    deliveryOfferId: {
      type: String,
    },
  },

  data() {
    return {
      offers: [],
      form: {
        selected: null,
      }
    };
  },

  methods: {
    optionSelected() {
      if(this.form.selected) {
        this.$emit('optionSelected', this.form.selected)
      } else {
        this.$emit('optionSelected', null)
      }
    },
  },

  computed: {
    params() {
      if(
          Object.prototype.hasOwnProperty.call(this.product, 'boosters')
          && this.product.boosters[0] !== undefined
          && true === this.product.boosters[0].enabled
      ) {
        return {
          deliveryOptionSetId: this.product.delivery_option_set.id,
          productPrice: Object.prototype.hasOwnProperty.call(this.product.pricing, "special_price") && null !== this.product.pricing.special_price ? this.product.pricing.special_price.value : this.product.pricing.price.value,
          categoryPath: this.product.category.path,
          pickupZipcode: this.product.zipcode,
          pickupCountry: this.product.country,
          boosted: true,
          boostedType: 'delivery',
          boostedPrice: this.product.boosters[0].price.value,
          allDeliveryOffers: true
        };
      }

      return {
        deliveryOptionSetId: this.product.delivery_option_set.id,
        productPrice: Object.prototype.hasOwnProperty.call(this.product.pricing, "special_price") && null !== this.product.pricing.special_price ? this.product.pricing.special_price.value : this.product.pricing.price.value,
        categoryPath: this.product.category.path,
        pickupZipcode: this.product.zipcode,
        pickupCountry: this.product.country,
        allDeliveryOffers: true
      };
    }
  },

  mounted() {
    delivery
    .getDeliveryOffers(this.params)
    .then(data => {
      this.offers = data;
      this.offers.forEach(function (offer) {
        if(offer.id === this.deliveryOfferId) {
          this.form.selected = offer
        }}
      , this);
    });
  }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DataTable", {
        attrs: {
          cols: _vm.cols,
          data: _vm.getData,
          paginate: false,
          searchable: false,
          actionable: false
        },
        scopedSlots: _vm._u([
          {
            key: "from_state",
            fn: function(col) {
              return [_c("span", [_vm._v(_vm._s(_vm.translate(col.data)))])]
            }
          },
          {
            key: "to_state",
            fn: function(col) {
              return [_c("span", [_vm._v(_vm._s(_vm.translate(col.data)))])]
            }
          },
          {
            key: "date",
            fn: function(col) {
              return [_c("Date", { attrs: { value: col.data } })]
            }
          },
          {
            key: "no-data",
            fn: function() {
              return [
                _c("i", { staticClass: "no-data" }, [_vm._v("Aucun évènement")])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <Modal>
    <template v-slot:panel-content>
      <div class="wrapper">
        <h1>Modifier la livraison</h1>
        <div class="current-offer">
          <h2>Livraison actuelle</h2>
          <span><DeliveryOffer :offer="orderProduct.delivery.delivery_offer"></DeliveryOffer></span>
        </div>
        <div class="offers">
          <h2>Nouvelle livraison</h2>
          <DeliveryOffers :product="product">
            <template v-slot:offer="data">
              <input
                type="radio"
                :id="data.offer.id"
                :value="data.offer.id"
                v-model="form.selected">
              <label :for="data.offer.id">
                <DeliveryOffer :offer="data.offer"></DeliveryOffer>
              </label>
            </template>
          </DeliveryOffers>
        </div>
        <div class="helpers">
          <span>Vous pouvez si besoin <a :href="`/#!/moderation/products/edit/${product.id}`">ajouter une option de livraison</a> sur le produit</span>
        </div>
        <div class="infos">
          <span v-for="(info, key) in infos" :key="key">{{info}}</span>
        </div>
        <div class="actions">
          <DangerousButton :disabled="!form.selected" @click="confirm">Confirmer la modification</DangerousButton>
        </div>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
.wrapper {
  padding: 20px;
  padding-top: 0px;
}

.current-offer {
  margin-bottom: 20px;
}

label {
  display: inline-block;
  padding: 5px;
}

.helpers {
  margin-top: 20px;
}

.infos {
  margin-top: 20px;
}

.infos span {
  display: block;
}

.infos span::before {
  content: "👉 ";
}

.actions {
  text-align: center;
  margin-top: 40px;
}
</style>

<script>
import * as sales from '../../api/sales';
import DeliveryOffers from '../../core/delivery/DeliveryOffers.vue';

const infos = {
  "SPLIT_UNTOUCHED": "Le split actuel (répartition de l'agent) sera conservé.",

  "SERVICES_UNTOUCHED": "Les services de livraison seront conservés. Pensez à les rembourser si nécessaire.",

  "HIGHER_PRICE": "Le nouveau prix est inférieur à l'ancien, pensez à effectuer les remboursements correspondants si nécessaire.",

  "LOWER_PRICE": "Le nouveau prix est supérieur à l'ancien, pensez à lancer un paiement supplémentaire auprès du service customer.",

  "GENERIC_PRICE": "Pensez également à effectuer remboursements ou paiements supplémentaires si nécéssaire",
};

export default {
  props: {
    orderProduct: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        selected: null,
      }
    }
  },

  methods: {
    confirm() {
      const data = {
        deliveryOfferId: this.form.selected,
      };

      sales
      .updateOrderProduct(this.orderProduct.id, data)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        alert("échec de la mise à jour");
      });
    },
  },

  computed: {
    product() {
      return this.orderProduct.product;
    },

    infos() {
      const res = [
        infos["SPLIT_UNTOUCHED"],
      ];

      if (this.orderProduct.order_services.length > 0) {
        res.push(infos["SERVICES_UNTOUCHED"]);
      }

      res.push(infos["GENERIC_PRICE"]);

      return res;
    },

    current() {
      return this.orderProduct.delivery.delivery_offer;
    },
  },

  components: {
    DeliveryOffers,
  }
}
</script>

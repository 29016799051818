<template>
  <Modal>
    <template v-slot:panel-content>
      <p>{{ comment.createdBy.firstName }} - {{ dateFormatted }}</p>
      <hr>
      <p>{{ comment.content }}</p>
    </template>
  </Modal>
</template>

<script>
export default {
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dateFormatted() {
      const date = new Date(this.comment.createdAt);
      return date.toLocaleDateString('fr-FR');
    },
  }
}
</script>

<template>
  <Modal>
    <template v-slot:panel-content>
      <h1>Détails du virement</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th>Réf</th>
              <th>Réf transfert</th>
              <th>Réf retrait</th>
              <th>Libellé</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{cashout.id}}</td>
              <td>{{cashout.transfer_txn}}</td>
              <td>{{cashout.withdrawal_txn}}</td>
              <td>{{cashout.label}}</td>
            </tr>
          </tbody>
        </table>
        <StatusHistory :history="cashout.history"></StatusHistory>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
table {
  border-collapse: collapse;
  margin: 20px;
}

th, td {
  border: solid 1px rgba(0, 0, 0, 0.2);
  padding: 10px;
}
</style>

<script>
export default {
  props: ['cashout'],

  data() {
    return {}
  }
}
</script>

<template>
  <div v-if="editable">
    <Tag
      v-for="(tag, key) in all" :key="key"
      :value="key" :editable="editable"
      :active="active(key)"
      @state="stateHandler"></Tag>
  </div>
  <div v-else>
    <Tag
      v-for="(tag, key) in tags" :key="key"
      :value="tag" :editable="editable"
      :active="active(key)"
      @state="stateHandler"></Tag>
  </div>
</template>

<script>
import Tag from './Tag.vue';
import constants from '../constants';

export default {
  props: {
    list: {
      type: Array,
    },
    editable: {
      type: Boolean,
    }
  },

  data() {
    return {
      state: [...this.list || []],
      all: constants.tags,
    };
  },

  methods: {
    active(tag) {
      return this.tags.indexOf(tag) > -1;
    },

    stateHandler(state) {
      let update = [...this.state].filter(e => e !== state.tag);

      if (state.active) {
        update.push(state.tag);
      }

      this.state = update;
      this.$emit('state', this.state);
    },
  },

  computed: {
    tags() {
      // remove duplicates if any
      return this.state.filter((element, index, self) => self.indexOf(element) === index);
    },
  },

  components: {
    Tag,
  },
}
</script>

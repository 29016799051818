<template>
  <select v-model="target" @change="change">
    <option :value="current">{{statuses[current] || current}}</option>
    <option v-for="status in possibilites" :key="status" :value="status">{{statuses[status] || status}}</option>
  </select>
</template>

<style scoped>
select {
  width: 100%;
}
</style>

<script>
import constants from '../../constants';
import {getPossibleStates} from './order-product';

export default {
  props: {
    orderProduct: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      statuses: constants.orderProductStatuses,
      possibilites: [],
      target: this.orderProduct.status,
    };
  },

  computed: {
    current() {
      return this.orderProduct.status;
    }
  },

  methods: {
    change() {
      this.$emit('input', this.target);
    }
  },

  mounted() {
    this.possibilites = getPossibleStates(this.orderProduct.status);
  }
}
</script>

<template>
  <div>
    <Price
      v-for="(value, key) in prices"
      :key="key"
      :price="value"></Price>
  </div>
</template>

<style scoped>
div {
  display: inline;
}
</style>

<script>
import Price from './Price.vue';

export default {
  props: ['list', 'only'],

  computed: {
    prices() {
      if (!this.only) {
        return this.list;
      }

      return this.list.filter(e => {
        return e.label === this.only;
      });
    }
  },
  
  components: {
    Price,
  }
}
</script>
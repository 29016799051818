import config from './config';
import * as axios from 'axios';
import * as store from './store';

const RolesList = [
  "ROLE_SUPER_ADMIN",
  "ROLE_FINANCE",
  "ROLE_ADMIN_CHECKOUT",
  "ROLE_ADMIN_CHECKOUT_LIGHT",
  "ROLE_PRODUCT_EXPERT",
  "ROLE_ADMIN_SHOP_SENSITIVE_DATA",
  "ROLE_ACCOUNT_MANAGER",
  "ROLE_MODERATION",
  "ROLE_MODERATION_FREELANCE",
  "ROLE_RETAIL",
  "ROLE_USER",
  "ROLE_ADMIN",
  "ROLE_BO_TESTER",
  "ROLE_ADMIN",
  "ROLE_B2B_PRO"
];

function getToken() {
  return store.get('_auth_token')
}

function getJwtToken() {
  return store.get('_auth_jwt_token')
}

function getRoles() {
  return store.get('_roles');
}

function hasRole(role) {
  return store.get('_roles').indexOf(role) > -1;
}

function getCurrentUser() {
  return store.get('_current_user');
}

function tryAuth() {
  const promise = new Promise((resolve, reject) => {
    if (!getToken() || !getJwtToken() || !getRoles()) {
      reject();
      return;
    }

  axios
    .get(config.url(`/api/v1/me/${getToken()}`), {
      headers: {
        authorization: getToken(),
      }
    })
    .then(res => {
      const roles = res.data.content.roles;

      store.put('_roles', roles);
      store.put('_current_user', res.data.content);

      resolve();
    })
    .catch(() => {
      reject();
    })
  });

  return promise;
}

function login(email, password) {
  const promise = new Promise((resolve, reject) => {
    axios
      .post(config.url('/login_check'), {
        _username: email,
        _password: password,
      })
      .then(res => {
        const token = res.data.token;

        store.put('_auth_token', token);
        store.put('_auth_jwt_token', res.data.jwtToken);

        axios
          .get(config.url(`/api/v1/me/${token}`), {
            headers: {
              authorization: token,
            }
          })
          .then(res => {
            const roles = res.data.content.roles;

            store.put('_roles', roles);
            store.put('_current_user', res.data.content);

            resolve({
              token,
              roles,
            });
          })
          .catch(() => {
            reject();
          })
      })
      .catch(() => {
        reject();
      });
  });

  return promise;
}

function logout() {
  const promise = new Promise((resolve) => {
    delete localStorage['_auth_token'];
    delete localStorage['_auth_jwt_token'];

    resolve();
  });

  return promise;
}

export {
  RolesList,
  getToken,
  getJwtToken,
  getRoles,
  hasRole,
  getCurrentUser,
  tryAuth,
  login,
  logout,
}

<template>
  <div>
    <DataTable
      :cols="cols" :data="getData"
      :paginate="false"
      :searchable="false"
      :actionable="false">

      <template v-slot:from_state="col">
        <span>{{translate(col.data)}}</span>
      </template>

      <template v-slot:to_state="col">
        <span>{{translate(col.data)}}</span>
      </template>

      <template v-slot:date="col">
        <Date :value="col.data"></Date>
      </template>

      <template v-slot:no-data>
        <i class="no-data">Aucun évènement</i>
      </template>
    </DataTable>
  </div>
</template>

<style scoped>
i.no-data {
  display: inline-block;
  padding: 30px;
  opacity: 0.5;
}
</style>

<script>
import DataTable from '../data-table/DataTable.vue';

export default {
  props: {
    history: {
      type: Array,
      required: true,
    },
    translator: {
      type: Function,
      required: false,
    },
  },

  data() {
    return {
      cols: {
        from_state: {
          title: "Statut initial",
        },
        transition: {
          title: "Transition"
        },
        to_state: {
          title: "Statut final",
        },
        date: {
          title: "Date",
        },
      }
    }
  },

  methods: {
    getData: async function* () {
      yield {
        rows: this.history,
      }
    },

    translate(status) {
      if (!this.translator) {
        return status;
      }

      return this.translator(status);
    }
  },

  components: {
    DataTable,
  },
}
</script>

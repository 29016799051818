<template>
  <div class="modal-backdrop" ref="modal">
    <div class="modal-content-wrapper">
      <Panel>
        <div class="modal-close">
          <span @click="close">Fermer</span>
        </div>
        <slot name="panel-content"></slot>
      </Panel>
    </div>
  </div>
</template>

<style scoped>
@keyframes appear {
  from {
    transform: scale(0.9) translateY(50px);
    opacity: 0.7;
  }

  to {
    transform: none;
    opacity: 1;
  }
}

.modal-backdrop {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 5, 0.7);
  backdrop-filter: blur(1px) grayscale(80%);
  overflow-y: scroll;
}

.modal-close {
  opacity: 0.5;
  position: relative;
  right: 10px;
  top: 6px;
  text-align: right;
  cursor: pointer;
}

.modal-content-wrapper {
  max-width: 960px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  animation: appear 0.1s;
}
</style>

<script>
import Mousetrap from 'mousetrap';

export default {
  methods: {
    close() {
      this.$root.$emit('modal.close');
    }
  },

  mounted() {
    document.querySelector('body').style.overflowY = 'hidden';

    this.$root.$emit('modal.mounted', this);

    Mousetrap.bind('esc', () => {
      this.close();
    });
  },

  beforeDestroy() {
    document.querySelector('body').style.overflowY = null;
  }
}
</script>

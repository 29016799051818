<template>
  <div class="wrapper">
    <Panel>
      <DataTable :data="data" :cols="cols" :error="handleError">
        <template v-slot:status="col">
          <div class="status">
            <span class="status-dot" :class="`status-dot-${col.data}`"></span>
            <span>{{c.orderStatuses[col.data] || col.data}}</span>
          </div>
        </template>
        <template v-slot:created_by="col">
          <User :user="col.data"></User>
        </template>
        <template v-slot:shipping_address="col">
          <Country :iso2="col.data.country"></Country>
        </template>
        <template v-slot:payment_gateway="col">
          <img height="18" src="/assets/img/paypal.png" v-if="col.data === 'paypal'">
          <span>{{ {
            'stripe': '💳',
            'sepa-ct': '🏦',
            'emv': '🛍️',
            'buybox': '🎁',
            }[col.data] || '' }}</span>
          <span>{{c.paymentGateways[col.data] || col.data}}</span>
        </template>
        <template v-slot:prices="col">
          <Prices :list="col.data" only="total_price_current"></Prices>
        </template>
        <template v-slot:tags="col">
          <Tags :list="col.data"></Tags>
        </template>
        <template v-slot:created_at="col">
          <Date :value="col.data"></Date>
        </template>
        <template v-slot:actions="data">
          <router-link :to="`/orders/${data.row.id}`" class="button-icon">➜</router-link>
          <!-- <a :href="`/#!/clients/orders/edit/${data.row.id}`" class="button-icon"><span>➜</span></a> -->
        </template>

        <template v-slot:pagination="pagination">
          <div class="data-table-pagination">
            <div class="info">
              <span>affichage de </span>
              <span>{{pagination.size}}</span>
              <span> résultats sur </span>
              <span>
                <span v-if="pagination.total === Infinity" title="Un très très grand nombre">♾️</span>
                <span v-else-if="pagination.total === undefined"><i>un nombre bien mystérieux</i></span>
                <span v-else>{{pagination.total}}</span>
              </span>
            </div>
            <div class="buttons">
              <button
                v-if="pagination.hasBack()"
                @click="pagination.back"
                class="button-secondary">Précédent</button>
              <button
                v-if="pagination.hasNext()"
                @click="pagination.next"
                class="button-secondary">Suivant</button>
            </div>
          </div>
        </template>

        <template v-slot:loading-status="status">
          <div class="data-table-loading-status">
            <span v-if="!status.errored" class="loading">Chargement</span>
            <span v-else class="errored">Impossible de charger ces données 💥</span>
          </div>
        </template>
      </DataTable>
    </Panel>
  </div>
</template>

<style scoped>
.wrapper {
  padding: 20px 50px;
}

.status-dot {
  display: inline-block;
  margin: 0 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
}

.status-dot-paid {
  background-color: var(--classic-green);
}

.status-dot-canceled {
  background-color: var(--classic-pink);
}

.status-dot-pending_payment {
  background-color: var(--classic-yellow);
}

.loading-status {
  text-align: center;
  padding: 40px;
}

.loading-status .loading {
  opacity: 0.5;
}
</style>

<script>
import Panel from '../Panel.vue';
import DataTable from '../data-table/DataTable.vue';
import User from '../core/User.vue';
import Prices from '../core/Prices.vue';
import Date from '../core/Date.vue';
import * as orders from '../api/orders';
import constants from '../constants';
import flag from 'country-to-emoji-flag';

async function* getOrders(page, search) {
  const perPage = 25;

  do {
    const data = await orders.getOrders(page * perPage, perPage, search);
    const total = data.total > 1000 ? Infinity : data.total;

    yield {
      rows: data.list,
      total,
      perPage,
    };

    page += 1;
  } while (true);
}

export default {
  data() {
    return {
      c: constants,

      orders: [],
      data: getOrders,
      cols: {
        status: {
          title: "Statut",
          search: {
            type: "select",
            options: {
              ...constants.orderStatuses,
            },
          }
        },
        order_number: {
          title: "N° de Commande",
          search: {
            type: "text",
          }
        },
        payment_gateway: {
          title: "Paiement",
          search: {
            type: "select",
            options: {
              ...constants.paymentGateways,
            },
          }
        },
        // shipping_address: {
        //   title: "Pays",
        // },
        created_by: {
          title: "Client",
          search: {
            type: "text",
            placeholder: "Nom de famille",
          }
        },
        shipping_address: {
          title: "Pays",
        },
        created_at: {
          title: "Date",
        },
        prices: {
          title: "Prix",
        },
        tags: {
          title: "Tags",
          search: {
            type: "select",
            options: {
              ...constants.tags,
            }
          }
        },
      },
    };
  },

  methods: {
    flag(iso2) {
      return flag(iso2);
    },

    handleError(e) {
      if (e.status && e.status === 401) {
        this.$root.$emit('auth.unauthorized');
      }
    },
  },

  components: {
    Panel,
    DataTable,
    User,
    Prices,
    Date,
  },
}
</script>


<template>
  <table>
    <thead>
      <tr>
        <th>Part vendeur</th>
        <th>Part Selency</th>
        <th>Total</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(field, name) in fields">
        <tr :key="`heading-${name}`">
          <td colspan="4">
            <span>{{field.title}}</span>
          </td>
        </tr>
        <tr v-for="(split, index) in field.value" :key="`${name}-${index}`">
          <td>
            <Price :price="split.seller"></Price>
          </td>
          <td>
            <Price :price="split.marketplace"></Price>
          </td>
          <td>
            <Price :price="split.total"></Price>
          </td>
          <td>
            <span v-if="split.label === 'seller-booster-delivery'">Turbo</span>
            <span v-else>{{split.label}}</span>
          </td>
        </tr>
        <tr v-if="input(name)" :key="`input-${name}`">
          <td>
            <input type="text" v-model.number="form[`${name}`].seller.value">
          </td>
          <td>
            <input type="text" v-model.number="form[`${name}`].marketplace.value">
          </td>
          <td>
            <input type="text" disabled>
          </td>
          <td>
            <input type="text" placeholder="Commentaire">
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<style scoped>
table {
  border-collapse: collapse;
  font-family: 'Courier New', Courier, monospace;
}

th, td {
  padding: 10px;
  border: solid 1px black;
  font-weight: normal;
}

td[colspan="4"] {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
}
</style>

<script>
import date from '../../core/date/date';

/**
 * The wrap function returns a split that is
 * "safe" to use:
 * - not referenced anywhere else
 * - exempt of any vue observer
 * - without any missing field
 * - with a total added
 */
function wrap(s, label) {
  const split = {};

  for (let party of ["seller", "marketplace"]) {
    split[party] = s[party] ? {
      value: parseFloat(parseFloat(s[party].value || 0).toFixed(2)),
      currency: s[party].currency
    } : {
      value: 0,
      currency: 'EUR'
    };
  }

  split.total = {
    value: split.seller.value + split.marketplace.value,
    currency: split.seller.currency,
  };

  split.label = label;

  return split;
}

const fields = {
  initial: {
    title: "Prix initial",
    get(entity) {
      return [wrap(entity.split)];
    },
  },
  discounts: {
    title: "Code promos",
    get(entity) {
      return (entity.discounts || []).map(e => wrap(e.split, e.code || e.type));
    },
  },
  refunds: {
    title: "Remboursements",
    get(entity) {
      return (entity.refunds || []).map(e => wrap(e.split, `${e.comment || ''} (${date.human(e.created_at)})`));
    }
  },
  final: {
    title: "Prix final",

    get(entity, inputs = {}) {
      let split = wrap(entity.split);

      for (let party of ["seller", "marketplace"]) {
        for (let target of ["discounts", "refunds"]) {
          split[party].value -= (
            this[target]
            .get(entity)
            .reduce((acc, e) => acc + e[party].value, 0)
          );

          if (inputs[target]) {
            split[party].value = (split[party].value - inputs[target][party].value).toFixed(2);
          }
        }
      }

      split = wrap(split);

      return [split];
    },
  },
};

fields.final.get = fields.final.get.bind(fields);

function compute(entity, inputs = {}) {
  let currentFields = {};

  for (let [key] of Object.entries(fields)) {
    currentFields[key] = {
      title: fields[key].title,
      value: fields[key].get(entity, inputs),
    };
  }

  return currentFields;
}

export default {
  props: {
    entity: {
      type: Object,
      required: true,
    },
    inputs: {
      type: Array,
      required: false,
      default: () => [],
    },
    headings: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    const data = {
      fields: compute(this.entity),
      form: {
      },
    };

    for (let e of this.inputs) {
      data.form[`${e}`] = {
        seller: {
          value: 0,
        },
        marketplace: {
          value: 0,
        },
      };
    }

    return data;
  },

  methods: {
    input(field) {
      return this.inputs.indexOf(field) > -1;
    },
    editable(field) {
      return this.editables.indexOf(field) > -1;
    },
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.fields = compute(this.entity, this.form);
        this.$emit('input', this.form);
      },
    }
  }
}
</script>

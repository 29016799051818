import { render, staticRenderFns } from "./AddDelivery.vue?vue&type=template&id=2a31e300&scoped=true&"
import script from "./AddDelivery.vue?vue&type=script&lang=js&"
export * from "./AddDelivery.vue?vue&type=script&lang=js&"
import style0 from "./AddDelivery.vue?vue&type=style&index=0&id=2a31e300&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a31e300",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/bo/bo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a31e300')) {
      api.createRecord('2a31e300', component.options)
    } else {
      api.reload('2a31e300', component.options)
    }
    module.hot.accept("./AddDelivery.vue?vue&type=template&id=2a31e300&scoped=true&", function () {
      api.rerender('2a31e300', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/order/AddDelivery.vue"
export default component.exports
import * as day from 'dayjs';
import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
day.extend(relativeTime);
day.extend(localizedFormat);
day.locale('fr');

export default {
  human(value) {
    // safari doesn't respect iso 8601
    const ts = value.replace(/([+-]\d\d)(\d\d)$/, "$1:$2");
    const d = day(ts);

    if (d.isBefore(day().subtract(10, 'minute'))) {
      return d.format('L LT');
    }

    return d.fromNow();
  }
};

import config from '../config';
import * as axios from 'axios';

function getSales(offset = 0, limit = 30, search = {}) {
  const p = new Promise((resolve, reject) => {
    const query = {
      ...search,
      offset,
      limit,
    };

    if (query.order_number) {
      query.reference = query.order_number;
      delete query.order_number;
    }

    const params = new URLSearchParams(query);

    axios
    .get(config.url(`/api/v1/order-products?${params}`))
    .then((res) => {
      resolve({
        list: res.data.slice(0, res.data.length - 1),
        total: res.data.pop(),
      });
    })
    .catch((err) => {
      reject(err.response);
    });
  });

  return p;
}

function updateOrderProduct(id, data) {
  const p = new Promise((resolve, reject) => {
    axios.put(config.url(`/api/v1/order-products/${id}`), data)
    .then((response) => {
      console.log(response.data.content.listing.elements)
      resolve({
        data: response.data.content.listing.elements
      });
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

function revertOrderProductStatus(id) {
  const p = new Promise((resolve, reject) => {
    axios.put(config.url(`/api/v1/order-products/${id}/revert-status`))
    .then(() => {
      resolve();
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

function updateSplits(orderProductId, data) {
  const p = new Promise((resolve, reject) => {
    axios.put(config.url(`/api/v1/order-products/${orderProductId}/splits`), data)
    .then(() => {
      resolve();
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

export {
  getSales,
  updateOrderProduct,
  revertOrderProductStatus,
  updateSplits,
};

<template>
  <Modal>
    <template v-slot:panel-content>
      <h3>Ajouter des virements manuels ({{ orderProduct.product.title }})</h3>
      <form @submit.prevent="handleSubmit">
        <div style="display:flex; flex-wrap: nowrap; gap: 16px;">
          <div style="width: 50%;">
            <h4>Seller product <button
              type="button"
              class="button-secondary"
              style="padding: 4px 8px;"
              @click="emptySellerProduct"
            >Vider</button></h4>
            <div style="display: flex; flex-direction: column; gap: 8px;">
              <label>Prix</label>
              <input
                type="number"
                step="any"
                v-model="sellerProduct.amount"
              >
              <label>ID de la transaction</label>
              <input
                type="text"
                :required="!!sellerProduct.amount"
                placeholder="po_1234abc"
                v-model="sellerProduct.providerTxId"
              >
            </div>
          </div>
          <div style="width: 50%;">
            <h4>Seller delivery <button
              type="button"
              class="button-secondary"
              style="padding: 4px 8px;"
              @click="emptySellerDelivery"
            >Vider</button></h4>
            <div style="display: flex; flex-direction: column; gap: 8px;">
              <label>Prix</label>
              <input
                type="number"
                step="any"
                v-model="sellerDelivery.amount"
              >
              <label>ID de la transaction</label>
              <input
                type="text"
                :required="!!sellerDelivery.amount"
                placeholder="po_1234abc"
                v-model="sellerDelivery.providerTxId"
              >
            </div>
          </div>
        </div>
        <p style="font-size: 12px; font-style: italic;">⚠️ Ajouter des virements manuels va annuler tous les virements vendeurs en erreur et passer le produit de la commande en "virements demandés".</p>
        <p v-if="errorMessage" style="color: red;">{{ errorMessage }}</p>
        <button
          type="submit"
          :disabled="isLoading"
          class="button-primary"
        >
          <span v-if="isLoading">Ajout en cours...</span>
          <span v-else>Ajouter des virements manuels</span>
        </button>
      </form>
    </template>
  </Modal>
</template>

<script>
import * as orders from '../../api/orders';


export default {
  props: ['orderProduct', 'payouts'],
  data() {
    return {
      errorMessage: null,
      isLoading: false,
      sellerProduct: {
        amount: null,
        providerTxId: null,
      },
      sellerDelivery: {
        amount: null,
        providerTxId: null,
      }
    }
  },
  mounted() {
    for (const payout of this.payouts) {
      if (payout.item.type === 'seller_product') {
        this.sellerProduct.amount = payout.item.price.amount;
      } else if (payout.item.type === 'seller_delivery') {
        this.sellerDelivery.amount = payout.item.price.amount;
      }
    }
  },
  methods: {
    handleSubmit() {
      this.errorMessage = null;
      this.isLoading = true;

      const payouts = [];

      if (this.sellerProduct.amount) {
        payouts.push({
          type: 'seller_product',
          providerTxId: this.sellerProduct.providerTxId,
          amount: parseFloat(this.sellerProduct.amount),
          currency: "EUR"
        })
      }

      if (this.sellerDelivery.amount) {
        payouts.push({
          type: 'seller_delivery',
          providerTxId: this.sellerDelivery.providerTxId,
          amount: parseFloat(this.sellerDelivery.amount),
          currency: "EUR"
        })
      }

      orders.createManualPayouts(this.orderProduct.id, { payouts })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          this.errorMessage = error.data.message;
        });
    },
    emptySellerProduct() {
      this.sellerProduct.amount = null;
      this.sellerProduct.providerTxId = null;
    },
    emptySellerDelivery() {
      this.sellerDelivery.amount = null;
      this.sellerDelivery.providerTxId = null;
    }
  },
}
</script>

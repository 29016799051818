import config from '../config';
import * as axios from 'axios';

function getDeliveryOffers(params) {
  const p = new Promise((resolve, reject) => {
    axios
    .get(config.url(`/api/v1/delivery-offers`), {
      params,
    })
    .then((res) => {
      resolve(res.data.content.listing.elements);
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

function getDelivery(id) {
  const p = new Promise((resolve, reject) => {
    axios
    .get(config.url(`/api/v1/delivery/` + id))
    .then((res) => {
      resolve(res.data.content.listing.elements);
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

function getShippingTool(deliveryId) {
  const p = new Promise((resolve, reject) => {
    axios
    .get(config.url(`/api/v1/shipping-tool/` + deliveryId))
    .then((res) => {
      resolve(res.data.content.listing.elements);
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

function updateDeliveryOptionSetSelencyGridActivated(id, setSelencyGridActivated = true) {
  const p = new Promise((resolve, reject) => {
    axios
    .put(config.url(`/api/v1/delivery-option-set/`+ id +`/brocantelab-grid-activated/` + setSelencyGridActivated))
    .then(() => {
      resolve();
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

function getPacklinkTracking(id) {
  const p = new Promise((resolve, reject) => {
    axios
      .get(config.url(`/api/v1/packlink/get-tracking/${id}`))
      .then((res) => {
        resolve(res.data.content.listing.elements);
      })
      .catch(() => {
        reject();
      });
  });

  return p;
}

export {
  getDeliveryOffers,
  getDelivery,
  getShippingTool,
  updateDeliveryOptionSetSelencyGridActivated,
  getPacklinkTracking,
};

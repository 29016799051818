<template>
  <div>
    <h1>{{ icon || '💥' }}</h1>
    <h2>{{ text || 'Une erreur est survenue' }}</h2>
    <p v-if="details">{{details}}</p>
    <div class="help">
      <AirtableButton table="allo-la-tech" :prefill="prefill"></AirtableButton>
    </div>
  </div>
</template>

<style scoped>
div:first-child {
  text-align: center;
  margin: auto;
  margin-top: 200px;
}

div.help {
  margin-top: 40px;
}

h1 {
  user-select: none;
}
</style>

<script>
export default {
  props: [
    'icon',
    'text',
    'details'
  ],

  computed: {
    prefill() {
      return {
        "Category": "BO",
        "Personne concernée": "Selency",
        "Lien BO": window.location.href,
      };
    },
  }
}
</script>

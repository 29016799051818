<template>
  <Modal>
    <template v-slot:panel-content>
      <h3>Historique <small style="color:lightslategrey;font-size: 14px;">({{ payoutId }})</small></h3>
      <div v-if="isLoading">Chargement...</div>
      <div v-else-if="!payoutHistory.length">Aucun historique pour ce virement</div>
      <div v-else>
        <table>
          <thead>
            <tr>
              <th>Transition</th>
              <th>Statut précédent</th>
              <th>Statut</th>
              <th>Motif</th>
              <th>Date</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="entry in payoutHistory"
              :key="entry.item.id"
            >
              <td style="text-align: left;">{{ entry.item.transition }}</td>
              <td>{{ entry.item.state.from }}</td>
              <td>{{ entry.item.state.to }}</td>
              <td>{{ entry.item.comment }}</td>
              <td>{{ entry.item.createdAt ? new Date(entry.item.createdAt).toLocaleString(undefined,{ dateStyle: 'short', timeStyle: 'short' }) : '' }}</td>
              <td>{{ entry.item.id }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Modal>
</template>

<script>
import * as orders from '../../api/orders';

export default {
  props: ['payoutId'],
  data() {
    return {
      isLoading: false,
      payoutHistory: [],
    };
  },
  mounted() {
    this.fetchPayoutHistory();
  },
  methods: {
    fetchPayoutHistory() {
      this.isLoading = true;

      orders.getPayoutHistory(this.payoutId).then((response) => {
        this.payoutHistory = response;

        this.isLoading = false;
      });
    }
  }
}
</script>

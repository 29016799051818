import ModalComponent from './Modal.vue';
import ModalView from './ModalView.vue';

class ModalManager {
  constructor() {
    this.modals = {};
  }

  inject(root) {
    this.$root = root;
  }

  register(name, component) {
    this.modals[name] = component;
  }

  show(name, props = {}) {
    const component = this.modals[name];

    this.$root.$emit('modal.mount_request', {
      component,
      props,
    });
  }
}

export default {
  install(Vue) {
    Vue.prototype.$modal = new ModalManager();

    Vue.component('Modal', ModalComponent);
    Vue.component('ModalView', ModalView);
  }
};

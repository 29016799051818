<template>
  <span class="country" :title="iso2 || 'Non défini'">{{iso2 ? flag : '-'}}</span>
</template>

<style scoped>
.country {
  user-select: none;
}
</style>

<script>
import flag from 'country-to-emoji-flag';

export default {
  props: ['iso2'],

  computed: {
    flag() {
      return flag(this.iso2);
    },
  }
}
</script>
<script>
export default {
  data() {
    return {
      current: null,
    };
  },

  mounted() {
    this.$modal.inject(this.$root);

    this.$root.$on('modal.mount_request', (data) => {
      if (this.current) {
        return;
      }

      this.current = data;
    });

    this.$root.$on('modal.close', () => {
      this.current = null;
    });
  },

  render(h) {
    if (this.current) {
      return h(this.current.component, {props: this.current.props});
    }

    return null;
  }
}
</script>

<template>
  <!--
    Loading the Legacy BO is expensive, we should try to keep an iframe alive
    and recycle it when we change the URL
  -->
  <iframe
    :src="fetchSrc()"
    frameborder="0"
    ref="frame"
    @load="load"></iframe>
</template>

<style scoped>
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

iframe {
  width: 100%;
  height: 100%;
  max-height: 100vh;
}
</style>

<script>
import { getToken, getJwtToken, getRoles, getCurrentUser } from './auth';
import config from './config';

export default {
  props: [
    'page'
  ],

  data() {
    return {
      host: config.legacy.origin,
      token: getToken(),
    };
  },

  mounted() {
    window.addEventListener('message', (e) => {
      if (e.data.event === '_blank') {
        window.open(e.data.hash);
        return;
      }

      if (e.data.hash) {
        this.$root.$emit('navigation.legacy_frame_event', {
          ...e.data,
        });
      }
    });
  },

  methods: {
    fetchSrc() {
      const rand = (new Date()).getTime() + Math.floor(Math.random() * 1000000)
      const url = new URL(`${this.host}${this.page}`);

      url.searchParams.append('rand', rand);

      return url.toString();
    },
    load() {
      this.$refs.frame.contentWindow.postMessage({
        token: getToken(),
        jwtToken: getJwtToken(),
        roles: getRoles(),
        currentUser: getCurrentUser(),
      }, '*');

      this.$refs.frame.focus();
    }
  },

  watch: {
    page() {
      this.$refs.frame.focus();
    },
  }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.price
    ? _c("div", [
        _c("span", { class: _vm.colors ? _vm.sign : "" }, [
          _c("span", [
            _vm._v(_vm._s(_vm.fixed ? _vm.value.toFixed(2) : _vm.value))
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.price.currency))])
        ])
      ])
    : _c("div", [
        _c("span", [_vm._v("0")]),
        _vm._v(" "),
        _c("span", [_vm._v("EUR")])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
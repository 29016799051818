var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    ref: "frame",
    attrs: { src: _vm.fetchSrc(), frameborder: "0" },
    on: { load: _vm.load }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
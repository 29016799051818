import config from '../config';
import * as axios from 'axios';
import { getToken } from '../auth';

function updateRoles(userId, roles) {
  const promise = new Promise((resolve, reject) => {
    axios
      .put(config.url(`/api/v1/users/${userId}/roles`), {
        roles,
      }, {
        headers: {
          authorization: getToken(),
        }
      })
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject();
      })
  });

  return promise;
}

function getByEmail(email) {
  const promise = new Promise((resolve, reject) => {
    axios
    .get(config.url(`/api/v1/shops?email=${email}`), {
      headers: {
        authorization: getToken(),
      },
    })
    .then((res) => {
      if (res.data[0]) {
        resolve(res.data[0].user);
      }

      reject();
    })
    .catch(() => {
      reject();
    });
  });

  return promise;
}

function getUser(id) {
  const p = new Promise((resolve, reject) => {
    axios.get(config.url(`/api/v1/users?id=${id}`))
    .then((res) => {
      resolve(res.data[0]);
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

export {
  updateRoles,
  getByEmail,
  getUser,
};

<template>
  <Modal>
    <template v-slot:panel-content>
      <iframe :src="url" frameborder="0"></iframe>
    </template>
  </Modal>
</template>

<style scoped>
iframe {
  width: 100%;
  height: 600px;
}
</style>

<script>
export default {
  props: ['url', 'prefill'],
}
</script>
<template>
  <span>{{human}}</span>
</template>

<script>
import date from './date/date';

export default {
  props: ['value'],

  computed: {
    human() {
      return date.human(this.value);
    }
  }
}
</script>

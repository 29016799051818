<template>
    <div>
        {{ deliveryTitle }}
    </div>
</template>

<script>
const providers = {
  'BROCANTE_LAB': {
    title: "Livraison par Selency",
    info: "Argent pour Selency",
  },
  'PERSONAL_DELIVERY': {
    title: "Par le vendeur",
    info: "Argent pour le vendeur"
  },
  'WITHDRAWAL_AT_HOME': {
    title: "Retrait chez le vendeur",
    info: "Gratuit",
  },
  'COLISSIMO': {
    title: "Colissimo",
    title_psa: "Colissimo (PSA)",
  },
  'QUOTATION': {
    title: "Devis",
    info: "Argent pour le vendeur",
  },
  'CUSTOM': {
    title: "Personnalisée",
    info: "Argent pour le vendeur"
  },
  'COCOLIS': {
    title: "COCOLIS",
    info: "COCOLIS"
  },
};
export default {
  name: "DeliveryTitle",
  props: {
    title: {
      type: String,
    }
  },
  computed: {
    deliveryTitle() {
      return providers[this.title] ? providers[this.title].title : null
    }
  }
}
</script>

<style scoped>

</style>

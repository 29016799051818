<template>
  <div>
    <DataTable
      :cols="cols" :data="getData"
      :paginate="false"
      :searchable="false">

      <template v-slot:status="col">
        <cashout-payment-status
          :cashoutPayment="col.data"
          v-model="form.status[col.data.id]"></cashout-payment-status>
      </template>

      <template v-slot:amount="col">
        <Price :price="col.data"></Price>
      </template>

      <template v-slot:recipient="col">
        <span v-if="col.data.is_marketplace">Selency</span>
        <span v-else>Marchand</span>
      </template>

      <template v-slot:actions="data">
        <DangerousButton
          v-if="form.status[data.row.id] && form.status[data.row.id] !== data.row.status"
          @click="updateStatus(data.row.id)">
          <span>Mettre à jour</span>
        </DangerousButton>
        <button class="button-text" @click="inspect(data.row)">détails</button>
      </template>

      <template v-slot:no-data>
        <i class="no-data">Aucun virement</i>
      </template>
    </DataTable>
  </div>
</template>

<style scoped>
i.no-data {
  display: inline-block;
  padding: 30px;
  opacity: 0.5;
}

::v-deep td.col-actions {
  max-width: 80px;
}

::v-deep td.col-actions button, ::v-deep td.col-actions a.button-secondary {
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}
</style>

<script>
import DataTable from '../../data-table/DataTable.vue';
import CashoutDetailModal from './CashoutDetailModal.vue';
import CashoutPaymentStatus from '../../domain/cashout-payment/CashoutPaymentStatus.vue';
import * as cashout from '../../api/cashout';

export default {
  props: ['list'],

  data() {
    return {
      form: {
        status: {},
      },
      cols: {
        status: {
          title: "Statut",
          getter(row) {
            return row;
          }
        },
        gateway: {
          title: "Moyen de paiement",
        },
        recipient: {
          title: "Bénéficiaire",
        },
        id: {
          title: "Réf",
        },
        amount: {
          title: "Montant",
        },
        date: {
          title: "Créé le",
          getter(row) {
            return row.created_at;
          },
        },
        attempts: {
          title: "Tentatives",
          getter(row) {
            return (row.history || [])
              .filter(e => e.to_state === 'error').length;
          }
        }
      }
    }
  },

  methods: {
    getData: async function* () {
      yield {
        rows: this.list,
      }
    },

    updateStatus(id) {
      cashout
      .updateCashoutPayment(id, {
        status: this.form.status[id],
      })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        alert("échec de la mise à jour");
      });
    },

    inspect(cashout) {
      this.$modal.show('inspect-cashout', {
        cashout,
      });
    }
  },

  mounted() {
    this.$modal.register('inspect-cashout', CashoutDetailModal);
  },

  components: {
    DataTable,
    CashoutPaymentStatus,
  },
}
</script>

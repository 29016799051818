<template>
  <div>
    <div
      v-if='comments.length > 0'
      class='comments'
    >
      <table class='data-table'>
        <thead>
        <tr>
          <th
            :key='`col-${name}`'
            v-for='(name) in cols'
          >
            <span>{{ name }}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for='(comment, index) in comments'
          :key='`row-${index}`'
        >
          <td v-if='cols.includes("Sku")'>{{ comment.item.productSku }}</td>
          <td>
            <Date :value='comment.item.createdAt'></Date>
          </td>
          <td>{{ comment.item.createdBy.firstName }}</td>
          <td>{{ truncateText(comment.item.content, 110) }}</td>
          <td>
            <button class='button-text' @click='viewCommentModal({row: comment.item})'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M14.7 12C14.7 13.4912 13.4912 14.7 12 14.7C10.5088 14.7 9.3 13.4912 9.3 12C9.3 10.5088 10.5088 9.30001 12 9.30001C13.4912 9.30001 14.7 10.5088 14.7 12Z'
                  stroke='#14181F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
                <path
                  d='M3 12C4.44012 8.31239 7.80243 5.70001 12 5.70001C16.1976 5.70001 19.5599 8.31239 21 12C19.5599 15.6876 16.1976 18.3 12 18.3C7.80243 18.3 4.44012 15.6876 3 12Z'
                  stroke='#14181F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
              </svg>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <i class='no-data'>Aucun commentaire</i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Comments',
  computed: {
    cols() {
      const cols = [
        "Sku",
        'Date',
        'Nom',
        'Commentaire',
        'Action',
      ]

      if (Object.keys(this.$route.params).includes('orderProductId')) {
        cols.splice(0, 1)
      }

      return cols
    },
    comments() {
      return this.$store.getters['comments']
    },
  },
  methods: {
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...'
      }
      return text
    },
    viewCommentModal(comment) {
      this.$modal.show('view-comment', {
        comment: comment.row,
      })
    },
  },
}
</script>



<template>
    <div>
        <form @change="emitData()">
            <div class="row margin-top-label">
                <div class="col-xs-12 col-md-6">
                    <span style="display: inline-block; font-weight: bold;">
                        Activer la grille Selency
                    </span>
                    <input style="display: inline-block; width: auto" type="radio" value="activated_grid" v-model="form.choice">
                </div>
                <div class="col-xs-12 col-md-6">
                    <span style="display: inline-block; font-weight: bold">
                        Créer une livraison Selency personnalisée
                    </span>
                    <input style="display: inline-block; width: auto" type="radio" value="personal_delivery" v-model="form.choice">
                </div>
            </div>
        </form>

        <div v-if="form.choice === 'personal_delivery'">
            <form @change="emitData()">
                <div class="row margin-top-label">
                    <div class="col-xs-12 col-md-6">
                    <span class="label">
                        Titre
                    </span>
                        <input v-model="form.title" type="text" placeholder="Titre">
                    </div>
                    <div class="col-xs-12 col-md-6">
                    <span class="label">
                        Prix
                    </span>
                        <input v-model="form.price" type="number">
                    </div>
                </div>
                <div class="row margin-top-label">
                    <div class="col-xs-12 col-md-4">
                    <span class="label">
                        Continent de livraison
                    </span>
                        <select name="continent" id="continent" v-model="form.continent">
                            <option value="">--Please choose an option--</option>
                            <option v-for="(continent, index) in continents" :key="index" :value="index">
                                {{ continent }}
                            </option>
                        </select>
                    </div>
                    <div class="col-xs-12 col-md-4">
                    <span class="label">
                        Pays de livraison
                    </span>
                        <select name="country" id="country" v-model="form.country">
                            <option value="">--Please choose an option--</option>
                            <option v-for="(country, index) in countries" :key="index" :value="index">
                                {{ country }}
                            </option>
                        </select>
                    </div>
                    <div class="col-xs-12 col-md-4">
                    <span class="label">
                        Région de livraison
                    </span>
                        <select name="region" id="region" v-model="form.region">
                            <option value="">--Please choose an option--</option>
                            <option v-for="(region, index) in regions" :key="index" :value="index">
                                {{ region }}
                            </option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
const continents = {
    "AF": "Afrique",
    "AN": "Antartique",
    "AS": "Asie",
    "EU": "Europe",
    "NA": "Amérique du Nord",
    "OC": "Océanie",
    "SA": "Afrique du Sud",
}

const countries = {
    "FR": "France",
    "BE": "Belgique",
    "US": "Etats-Unis",
    "LU": "Luxembourg",
    "DE": "Allemagne",
    "NL": "Pays-Bas",
    "GB": "Royaume-Uni",
    "AT": "Autriche",
    "ES": "Espagne",
    "PT": "Portugal",
    "IT": "Italie",
    "GR": "Grèce",
    "CH": "Suisse",
    "MC": "Monaco",
    "SE": "Suède",
    "CZ": "République Tchèque",
    "DK": "Danemark",
    "PL": "Pologne",
}

const regions = {
    "ALSACE": "Alsace",
    "AQUITAINE": "Aquitaine",
    "AUVERGNE": "Auvergne",
    "BASSE_NORMANDIE": "Basse Normandie",
    "BOURGOGNE": "Bourgogne",
    "BRETAGNE": "Bretagne",
    "CENTRE": "Centre",
    "CHAMPAGNE_ARDENNE": "Champagne Ardenne",
    "CORSE": "Corse",
    "DOM": "DOM",
    "FRANCHE_CONTE": "Franche Conte",
    "HAUTE_NORMANDIE": "Haute Normandie",
    "ILE_DE_FRANCE": "Ile de France",
    "LANGUEDOC_ROUSSILLON": "Languedoc Roussillon",
    "LIMOUSIN": "Limousin",
    "LORRAINE": "Lorraine",
    "MIDI_PYRENEES": "Midi Pyrénées",
    "NORD_PAS_DE_CALAIS": "Nord pas de Calais",
    "PAYS_DE_LA_LOIRE": "Pays de la Loire",
    "PICARDIE": "Picardie",
    "POITOU_CHARENTES": "Poitou Charentes",
    "PROVENCE_ALPES_COTE_D_AZUR": "Provence Alpes Côte d'Azur",
    "RHONE_ALPES": "Rhône Alpes"
}

export default {
  data() {
    return {
      countries: null,
      regions: null,
      continents: null,
      form: {
        choice: null,
        title: null,
        price: null,
        country: null,
        region: null,
        continent: null,
      }
    }
  },
  methods: {
    emitData() {
        console.log(this.form)
      this.$emit('input', this.form)
    },
  },
  mounted() {
    this.countries = countries;
    this.regions = regions;
    this.continents = continents;
  }
}

</script>

<style scoped>
.label {
    display: block;
    font-weight: bold;
}

.margin-top-label {
    margin-top: 10px;
}

input, select {
    width: 100%;
}
</style>
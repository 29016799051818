<template>
  <div>
    <div
      v-if="beta">
      <a :href="beta.href">
        <div
          class="goto unicorn"
          title="cette page est disponible en version bêta">
          <span>🦄</span>
        </div>
      </a>
    </div>
    <div v-if="legacy">
      <a :href="legacy.href">
        <div
          class="goto horse"
          title="cette page est disponible en version plus classique">
          <span>🐴</span>
        </div>
      </a>
    </div>
  </div>
</template>

<style scoped>
.goto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: rgb(2,0,36);
  background: linear-gradient(17deg, rgba(2,0,36,1) 0%, rgba(251,34,255,1) 35%, rgba(255,229,0,1) 100%);
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 4px;
  font-size: 22px;
  padding-top: 5px;
  box-sizing: border-box;
  text-align: center;
  line-height: 30px;
  opacity: 0.6;
  cursor: pointer;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 4px;
}

.goto:hover {
  opacity: 1;
}

.unicorn {
  background: rgb(2,0,36);
  background: linear-gradient(17deg, rgba(2,0,36,1) 0%, rgba(251,34,255,1) 35%, rgba(255,229,0,1) 100%);
}

.horse {
  background: rgb(2,0,36);
  background: linear-gradient(17deg, rgba(2,0,36,1) 0%, rgba(255,124,70,1) 35%, rgba(255,242,124,1) 100%);
}
</style>

<script>
import translator from './route-translator';
import {hasRole} from '../auth';

export default {
  data() {
    return {
      beta: null,
      legacy: null,
    }
  },

  methods: {
    poll() {
      const beta = translator.toNew();
      if (beta && (!beta.early || beta.early && hasRole("ROLE_BO_TESTER"))) {
        this.beta = beta;
      } else {
        this.beta = null;
      }

      this.legacy = translator.toLegacy();
    },
  },

  mounted() {
    setInterval(() => {
      this.poll();
    }, 500);
  },
}
</script>

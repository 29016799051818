var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    scopedSlots: _vm._u([
      {
        key: "panel-content",
        fn: function() {
          return [
            _c("p", [
              _vm._v("Commentaire "),
              _c("small", { staticStyle: { color: "gainsboro" } }, [
                _vm._v(
                  "#" +
                    _vm._s(_vm.orderId ? _vm.orderId : "") +
                    "\n      " +
                    _vm._s(_vm.orderProductId ? "- " + _vm.orderProductId : "")
                )
              ])
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.comment,
                  expression: "comment"
                }
              ],
              attrs: {
                placeholder: "Ajouter un commentaire",
                rows: "5",
                cols: "106",
                maxlength: "5000"
              },
              domProps: { value: _vm.comment },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.comment = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "global-actions" }, [
              _c(
                "button",
                {
                  class:
                    "button-primary " +
                    (_vm.comment ? "btn-submit" : "btn-submit disabled"),
                  attrs: { disabled: !_vm.comment || _vm.loading },
                  on: {
                    "~click": function($event) {
                      return _vm.addComment($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.loading ? "Envoi..." : "Ajouter") +
                      "\n      "
                  )
                ]
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div :class="decorate && allowed ? 'early-wrapper' : ''">
    <div class="early-label">
      <span>accès anticipé pour {{name}}</span>
    </div>
    <slot v-if="allowed"></slot>
  </div>
</template>

<style scoped>
.early-wrapper {
  border: dashed 3px rgba(220, 10, 220, 0.2);
}

.early-label {
  display: none;
}

.early-wrapper .early-label {
  display: block;
  padding: 5px 10px;
  padding-bottom: 8px;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: bold;
  color: rgba(155, 22, 155, 0.575);
  background: rgba(220, 10, 220, 0.1);
}
</style>

<script>
import {hasRole, getCurrentUser} from '../auth';

export default {
  props: {
    role: {
      type: String,
      required: false,
      default: "ROLE_BO_TESTER",
    },
    decorate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    allowed() {
      return hasRole(this.role);
    },
    name() {
      return getCurrentUser().firstname;
    }
  },
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "Panel",
        [
          _c("DataTable", {
            attrs: { data: _vm.data, cols: _vm.cols, error: _vm.handleError },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(col) {
                  return [
                    _c("div", { staticClass: "status" }, [
                      _c("span", {
                        staticClass: "status-dot",
                        class: "status-dot-" + col.data
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.c.orderStatuses[col.data] || col.data)
                        )
                      ])
                    ])
                  ]
                }
              },
              {
                key: "created_by",
                fn: function(col) {
                  return [_c("User", { attrs: { user: col.data } })]
                }
              },
              {
                key: "shipping_address",
                fn: function(col) {
                  return [_c("Country", { attrs: { iso2: col.data.country } })]
                }
              },
              {
                key: "payment_gateway",
                fn: function(col) {
                  return [
                    col.data === "paypal"
                      ? _c("img", {
                          attrs: { height: "18", src: "/assets/img/paypal.png" }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          {
                            stripe: "💳",
                            "sepa-ct": "🏦",
                            emv: "🛍️",
                            buybox: "🎁"
                          }[col.data] || ""
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.c.paymentGateways[col.data] || col.data)
                      )
                    ])
                  ]
                }
              },
              {
                key: "prices",
                fn: function(col) {
                  return [
                    _c("Prices", {
                      attrs: { list: col.data, only: "total_price_current" }
                    })
                  ]
                }
              },
              {
                key: "tags",
                fn: function(col) {
                  return [_c("Tags", { attrs: { list: col.data } })]
                }
              },
              {
                key: "created_at",
                fn: function(col) {
                  return [_c("Date", { attrs: { value: col.data } })]
                }
              },
              {
                key: "actions",
                fn: function(data) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "button-icon",
                        attrs: { to: "/orders/" + data.row.id }
                      },
                      [_vm._v("➜")]
                    )
                  ]
                }
              },
              {
                key: "pagination",
                fn: function(pagination) {
                  return [
                    _c("div", { staticClass: "data-table-pagination" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("span", [_vm._v("affichage de ")]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(pagination.size))]),
                        _vm._v(" "),
                        _c("span", [_vm._v(" résultats sur ")]),
                        _vm._v(" "),
                        _c("span", [
                          pagination.total === Infinity
                            ? _c(
                                "span",
                                {
                                  attrs: { title: "Un très très grand nombre" }
                                },
                                [_vm._v("♾️")]
                              )
                            : pagination.total === undefined
                            ? _c("span", [
                                _c("i", [_vm._v("un nombre bien mystérieux")])
                              ])
                            : _c("span", [_vm._v(_vm._s(pagination.total))])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        pagination.hasBack()
                          ? _c(
                              "button",
                              {
                                staticClass: "button-secondary",
                                on: { click: pagination.back }
                              },
                              [_vm._v("Précédent")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        pagination.hasNext()
                          ? _c(
                              "button",
                              {
                                staticClass: "button-secondary",
                                on: { click: pagination.next }
                              },
                              [_vm._v("Suivant")]
                            )
                          : _vm._e()
                      ])
                    ])
                  ]
                }
              },
              {
                key: "loading-status",
                fn: function(status) {
                  return [
                    _c("div", { staticClass: "data-table-loading-status" }, [
                      !status.errored
                        ? _c("span", { staticClass: "loading" }, [
                            _vm._v("Chargement")
                          ])
                        : _c("span", { staticClass: "errored" }, [
                            _vm._v("Impossible de charger ces données 💥")
                          ])
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import translator from '../../compat/translator';
import date from '../date/date';

const $filter = function(filter) {
    if (filter === 'date') {
        return date.human;
    }

    return translator;
};

function deliveryOfferLabel() {

    function computeWithdrawalAtHome(pickupAddress) {
        if (pickupAddress) {
            return $filter('translate')('ACCOUNT.EDIT_DELIVERY_OFFERS.WITHDRAWAL_AT_HOME.LABEL_WITH_ADDR', { city: pickupAddress.city, cp: pickupAddress.postcode });
        } else {
            return $filter('translate')('ACCOUNT.EDIT_DELIVERY_OFFERS.WITHDRAWAL_AT_HOME.LABEL');
        }
    }

    function computeQuotation(deliveryOffer) {
        if (!deliveryOffer.quotation_created_at) {
            return 'Devis';
        }

        var formattedDate = $filter('date')(deliveryOffer.quotation_created_at, "dd/MM/yyyy");

        return $filter('translate')('ACCOUNT.EDIT_DELIVERY_OFFERS.QUOTATION.LABEL', { date: formattedDate, userId: deliveryOffer.user_id, destinationCity: deliveryOffer.destination_city, destinationZipcode: deliveryOffer.destination_zipcode });
    }

    function computeDistanceString(min, max) {
        var distanceString = '';
        if (min && max) {
            distanceString = min + "km - " + max + 'km';
        } else if (!min) {
            distanceString = "-" + max + 'km';
        } else if (!max) {
            distanceString = "+" + min + 'km';
        }
        return distanceString;
    }

    function computeGmapsDestinationString(deliveryOffer) {
        var translation = '';

        var component = deliveryOffer.gmaps_destination_components[0];

        translation += component.value;

        return translation;
    }

    function computeDestinationString(continent, country, region) {
        var destinationString = '';

        if (continent) {
            destinationString += (continent || '').toUpperCase();
        }

        if (country) {
            if (destinationString) {
                destinationString += ' - ';
            }
            destinationString += (country || '').toUpperCase();
        }

        if (region) {
            if (destinationString) {
                destinationString += ' - ';
            }
            destinationString += (region || '').toUpperCase();
        }

        return destinationString;
    }

    return function (deliveryOffer, pickupAddress) {
        // clean value object of any hidden property
        deliveryOffer = JSON.parse(JSON.stringify(deliveryOffer));

        console.log(deliveryOffer);

        if (deliveryOffer.title) {
            return deliveryOffer.title;
        }

        if ('COLISSIMO' === deliveryOffer.provider_type) {
            if ('EU' === deliveryOffer.destination_continent &&
                null === deliveryOffer.destination_country &&
                null === deliveryOffer.destination_region) {
                return $filter('translate')('ACCOUNT.EDIT_DELIVERY_OFFERS.COLISSIMO.EUROPE');
            }

            if ('FR' === deliveryOffer.destination_country &&
                null === deliveryOffer.destination_region) {
                return $filter('translate')('ACCOUNT.EDIT_DELIVERY_OFFERS.COLISSIMO.FRANCE');
            }

            if (null === deliveryOffer.destination_continent &&
                null === deliveryOffer.destination_country &&
                null === deliveryOffer.destination_region) {
                return $filter('translate')('ACCOUNT.EDIT_DELIVERY_OFFERS.COLISSIMO.WORLD');
            }

            return $filter('translate')(
                'ACCOUNT.EDIT_DELIVERY_OFFERS.COLISSIMO.CUSTOM',
                {
                    'destination' : computeDestinationString(
                        deliveryOffer.destination_continent,
                        deliveryOffer.destination_country,
                        deliveryOffer.destination_region
                    )
                }
            );
        }

        if ('BROCANTE_LAB' === deliveryOffer.provider_type) {
            if (deliveryOffer.destination_department) {
                return $filter('translate')('ACCOUNT.EDIT_DELIVERY_OFFERS.BROCANTE_LAB.LABEL_' + deliveryOffer.destination_department);
            } else if (deliveryOffer.destination_region) {
                return $filter('translate')('ACCOUNT.EDIT_DELIVERY_OFFERS.BROCANTE_LAB.LABEL_' + deliveryOffer.destination_region);
            } else if (deliveryOffer.destination_country) {
                return $filter('translate')('ACCOUNT.EDIT_DELIVERY_OFFERS.BROCANTE_LAB.LABEL_' + deliveryOffer.destination_country);
            } else {
                return $filter('translate')(
                    'ACCOUNT.EDIT_DELIVERY_OFFERS.BROCANTE_LAB.LABEL_CUSTOM',
                    {
                        'destination':
                            computeDestinationString(
                                deliveryOffer.destination_continent,
                                deliveryOffer.destination_country,
                                deliveryOffer.destination_region
                            )
                    }
                );
            }
        }

        if ('PERSONAL_DELIVERY' === deliveryOffer.provider_type) {
            if (deliveryOffer.gmaps_destination_components && deliveryOffer.gmaps_destination_components.length) {
                var destinationString = computeGmapsDestinationString(deliveryOffer);

                return $filter('translate')('ACCOUNT.EDIT_DELIVERY_OFFERS.PERSONAL_DELIVERY.LABEL_W_DESTINATION', {
                    destination: destinationString,
                });
            }

            var distanceString = computeDistanceString(
                deliveryOffer.min_distance,
                deliveryOffer.max_distance
            );

            return $filter('translate')('ACCOUNT.EDIT_DELIVERY_OFFERS.PERSONAL_DELIVERY.LABEL_W_DISTANCE', {distance: distanceString});
        }

        if ('WITHDRAWAL_AT_HOME' === deliveryOffer.provider_type) {
            return computeWithdrawalAtHome(pickupAddress);
        }

        if ('QUOTATION' === deliveryOffer.provider_type) {
            return computeQuotation(deliveryOffer);
        }

        if ('CUSTOM' === deliveryOffer.provider_type) {
            return "Livraison personnalisée";
        }

        return '';
    };
}

export default deliveryOfferLabel();

import fr from './fr.json';

import path from 'object-path';

function translate(key, values = {}) {
  let str = path.get(fr, key);

  for (let [key, value] of Object.entries(values)) {
    const pattern = `{{${key}}}`;

    str = str.replace(pattern, value);
  }

  return str;
}

export default translate;

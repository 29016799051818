var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    scopedSlots: _vm._u([
      {
        key: "panel-content",
        fn: function() {
          return [
            _c("h1", [_vm._v("Détails du virement")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("table", [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Réf")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Réf transfert")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Réf retrait")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Libellé")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.cashout.id))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.cashout.transfer_txn))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.cashout.withdrawal_txn))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.cashout.label))])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("StatusHistory", { attrs: { history: _vm.cashout.history } })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
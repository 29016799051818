<template>
  <div class="wrapper">
    <h1>Stripe Tool</h1>
    <h2>Dissocier</h2>
    <form @submit="submit">
      <label>Shop ID</label><br>
      <input v-model="shopId" type="text" placeholder="81723796960db68ce4c867dd51ae">
      <br><br>
      <label>Stripe Account ID</label><br>
      <input v-model="accountId" type="text" placeholder="acct_3eQiOaGbPIfPwZU6">
      <br>
      <p>👉 Attention, le marchand devra recommencer tout le process de création de compte Stripe.</p>
      <button class="button-danger" type="submit">Dissocier 💣</button>
    </form>
  </div>
</template>

<style scoped>
.wrapper {
  margin-left: 20px;
}

input {
  padding: 5px;
  margin: 5px;
  margin-left: 0px;
}

p {
  max-width: 500px;
  background-color: lightyellow;
  padding: 20px;
}

button[type="submit"] {
  margin-top: 20px;
}
</style>

<script>
import * as axios from 'axios';
import config from '../config';

export default {
  data() {
    return {
      shopId: '',
      accountId: '',
    };
  },

  methods: {
    submit(event) {
      event.preventDefault();

      if (!this.shopId || !this.accountId) {
        return;
      }

      axios.post(config.url(`/api/v1/stripe/shops/${this.shopId}/unlink`), {
        accountId: this.accountId,
      }).then(() => {
        alert('Dissociation réussie');
        this.shopId = '';
        this.accountId = '';
      }).catch(() => {
        alert('Échec de la dissociation');
      });
    }
  }
}
</script>

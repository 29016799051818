import { render, staticRenderFns } from "./CocolisDeliveryInfo.vue?vue&type=template&id=513784ac&scoped=true&"
import script from "./CocolisDeliveryInfo.vue?vue&type=script&lang=js&"
export * from "./CocolisDeliveryInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "513784ac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/bo/bo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('513784ac')) {
      api.createRecord('513784ac', component.options)
    } else {
      api.reload('513784ac', component.options)
    }
    module.hot.accept("./CocolisDeliveryInfo.vue?vue&type=template&id=513784ac&scoped=true&", function () {
      api.rerender('513784ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/CocolisDeliveryInfo.vue"
export default component.exports
<template>
    <div>
        <h3>📦 Livraison Cocolis</h3>

        <div v-if="delivery">
            <div class="row" style="padding-bottom:20px">
                <div class="col-xs-5 col-xs-offset-1">
                    <div>
                        <b>Packages : </b>
                        <span v-if="delivery.delivery_offer.packages.length === 0">⚠️ 0 found ⚠️</span>
                        <div v-for="pckg in delivery.delivery_offer.packages" v-bind:key="pckg">
                            -
                            <b>Depth :</b>  {{ pckg.depth }} cm
                            <b>Height :</b> {{ pckg.height }} cm
                            <b>Width :</b>  {{ pckg.width }} cm
                        </div>

                        <a
                                :href="this.delivery.cocolis_ride.seller_tracking"
                                target="_blank"
                        >
                            <button class="button-secondary">
                                🔎 Suivi sur le site de Cocolis
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as deliveries from '../api/delivery';
    import { hasRole } from '../auth';

    export default {
        props: ['deliveryId', 'shippingAddress', 'trackingRole'],
        name: "CocolisDeliveryInfo",
        data() {
            return {
                delivery: null,
                showAdminInfo: hasRole("ROLE_ADMIN"),
                displayIssueForm: false,
                form : {
                    reason: "",
                    message: ""
                }
            }
        },
        created() {
            deliveries.getDelivery(this.deliveryId).then(deliveries => {
                if (Array.isArray(deliveries) && deliveries.length > 0) {
                    this.delivery = deliveries[0];
                }
            });
        },
    }
</script>

<style scoped>

</style>

<template>
  <span v-if="name === 'HANDLING'">
    <span>Livraison à l'étage</span>
    <span>💪</span>
    <template v-if="price">
      <br>
      <Price :price="service.price"></Price>
    </template>
  </span>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
    price: {
      type: Boolean,
      required: false,
      default: true,
    }
  },

  computed: {
    name() {
      if (this.service.delivery_service) {
        return this.service.delivery_service.name;
      }
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <Panel>
      <DataTable :data="data" :cols="cols" :error="handleError">
        <template v-slot:status="col">
          <div class="status">
            <span class="status-dot" :class="`status-dot-${col.data}`"></span>
            <span>{{c.orderProductStatuses[col.data] || col.data}}</span>
          </div>
        </template>
        <template v-slot:seller="col">
          <div class="seller">
            <User :user="col.data"></User>
          </div>
        </template>
        <template v-slot:manager="col">
          <User :user="col.data" :only="['firstname']"></User>
        </template>
        <template v-slot:created_at="col">
          <Date :value="col.data"></Date>
        </template>
        <template v-slot:product="col">
          <div class="product">
            <Product :product="col.data" :max="40"></Product>
          </div>
        </template>
        <template v-slot:price="col">
          <Price :price="col.data"></Price>
        </template>
        <template v-slot:actions="data">
          <router-link :to="`/orders/${data.row.order_id}/sales/${data.row.id}`" class="button-icon">
            <span>➜</span>
          </router-link>
          <!-- <a :href="`/#!/trades/orders/edit/${data.row.order_id}/product/${data.row.id}`" class="button-icon">
            <span>➜</span>
          </a> -->
        </template>
        <template v-slot:pagination="pagination">
          <div class="data-table-pagination">
            <div class="info">
              <span>affichage de </span>
              <span>{{pagination.size}}</span>
              <span> résultats sur </span>
              <span>
                <span v-if="pagination.total === Infinity" title="Un très très grand nombre">♾️</span>
                <span v-else-if="pagination.total === undefined"><i>un nombre bien mystérieux</i></span>
                <span v-else>{{pagination.total}}</span>
              </span>
            </div>
            <div class="buttons">
              <button
                v-if="pagination.hasBack()"
                @click="pagination.back"
                class="button-secondary">Précédent</button>
              <button
                v-if="pagination.hasNext()"
                @click="pagination.next"
                class="button-secondary">Suivant</button>
            </div>
          </div>
        </template>

        <template v-slot:loading-status="status">
          <div class="data-table-loading-status">
            <span v-if="!status.errored" class="loading">Chargement</span>
            <span v-else class="errored">Impossible de charger ces données 💥</span>
          </div>
        </template>
      </DataTable>
    </Panel>
  </div>
</template>

<style scoped>
.wrapper {
  padding: 20px 50px;
}

.status {
  min-width: 100px;
}

.status-dot {
  display: inline-block;
  margin: 0 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
}

.status-dot-CONFIRMED {
  background-color: var(--classic-green);
}

.status-dot-TRANSFER_PROCESSED {
  background-color: var(--classic-green);
}

.status-dot-PENDING {
  background-color: var(--classic-yellow);
}

.status-dot-SHIPPED {
  background-color: var(--light-blue);
}

.status-dot-DELIVERED {
  background-color: var(--classic-green);
}

.status-dot-CASHOUT_PREPARED {
  background-color: var(--light-blue);
}

.status-dot-TRANSFER_REQUESTED {
  background-color: var(--light-blue);
}

.status-dot-UNAVAILABLE {
  background-color: var(--classic-wine);
}

.seller {
  text-align: left;
}

.product {
  text-align: left;
  text-overflow: ellipsis;
}

::v-deep table.data-table td.col-product {
  max-width: 250px;
}
</style>

<script>
import Panel from '../Panel.vue';
import DataTable from '../data-table/DataTable.vue';
import User from '../core/User.vue';
import Date from '../core/Date.vue';
import Product from '../core/Product.vue';
import Price from '../core/Price.vue';
import * as sales from '../api/sales';
import constants from '../constants';

async function* getSales(page, search) {
  const perPage = 25;

  do {
    const data = await sales.getSales(page * perPage, perPage, search);
    const total = data.total > 1000 ? Infinity : data.total;

    yield {
      rows: data.list,
      total,
      perPage,
    };

    page += 1;
  } while (true);
}

export default {
  data() {
    return {
      c: constants,
      cols: {
        status: {
          title: "Statut",
          search: {
            type: "select",
            options: {
              ...constants.orderProductStatuses,
            },
          },
        },
        order_number: {
          title: "N° de Commande",
          search: {
            type: "text",
          },
        },
        seller: {
          title: "Vendeur",
          search: {
            type: "text",
          },
          getter(row) {
            return row.product.created_by;
          },
        },
        product: {
          title: "Produit",
          search: {
            type: "text",
          }
        },
        created_at: {
          title: "Date",
        },
        price: {
          title: "Price",
        },
        manager: {
          title: "Manager",
          getter(row) {
            return row.product.account_manager;
          },
        },
        offer: {
          title: "Négo",
          getter(row) {
            return row.offer_auto_accepted ? '✔️': '-';
          },
        },
      },
      data: getSales,
    }
  },

  methods: {
    handleError(e) {
      console.log(e);
    },
  },

  components: {
    Panel,
    DataTable,
    User,
    Date,
    Price,
    Product,
  },
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.beta
      ? _c("div", [_c("a", { attrs: { href: _vm.beta.href } }, [_vm._m(0)])])
      : _vm._e(),
    _vm._v(" "),
    _vm.legacy
      ? _c("div", [_c("a", { attrs: { href: _vm.legacy.href } }, [_vm._m(1)])])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "goto unicorn",
        attrs: { title: "cette page est disponible en version bêta" }
      },
      [_c("span", [_vm._v("🦄")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "goto horse",
        attrs: { title: "cette page est disponible en version plus classique" }
      },
      [_c("span", [_vm._v("🐴")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
import './global.css';

import './api/axios';

import config from './config';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

if (!window.location.host.match(/^localhost/)) {
  Sentry.init({
    environment: config.env,
    dsn: 'https://16632e293fb34b08b87bdaf362649fd7@o107511.ingest.sentry.io/5356384',
    integrations: [new VueIntegration({Vue, attachProps: true})],
  });
}

import Vue from 'vue';
import Vuex from 'vuex';
import vuetify from "./plugins/vuetify";
import VueRouter from 'vue-router';
import App from './App.vue'
import store from './store/index.js';

import Price from './core/Price.vue';
import Prices from './core/Prices.vue';
import FriendlyDate from './core/Date.vue';
import Panel from './Panel.vue';
import Product from './core/Product.vue';
import User from './core/User.vue';
import Delivery from './core/Delivery.vue';
import TrackingCode from './core/TrackingCode.vue';
import Tags from './core/Tags.vue';
import AirtableButton from './core/airtable/AirtableButton.vue';
import Tip from './core/Tip.vue';
import Modal from './core/modal/modal';
import Country from './core/Country.vue';
import DangerousButton from './core/DangerousButton.vue';
import Address from './core/Address.vue';
import StatusHistory from './core/StatusHistory.vue';
import DeliveryOffer from './core/delivery/DeliveryOffer.vue';
import Tabs from './core/Tabs.vue';
import EarlyAccess from './core/EarlyAccess.vue'
import ErrorWrapper from './Error.vue';

Vue.component('Price', Price);
Vue.component('Prices', Prices);
Vue.component('Date', FriendlyDate);
Vue.component('Panel', Panel);
Vue.component('Product', Product);
Vue.component('User', User);
Vue.component('Delivery', Delivery);
Vue.component('TrackingCode', TrackingCode);
Vue.component('Tags', Tags);
Vue.component('AirtableButton', AirtableButton);
Vue.component('Tip', Tip);
Vue.component('Country', Country);
Vue.component('Address', Address);
Vue.component('DangerousButton', DangerousButton);
Vue.component('StatusHistory', StatusHistory);
Vue.component('DeliveryOffer', DeliveryOffer);
Vue.component('Tabs', Tabs);
Vue.component('Error', ErrorWrapper);
Vue.component('EarlyAccess', EarlyAccess);

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
});

Vue.use(Modal);

new Vue({
  el: '#app',
  store,
  router,
  vuetify,
  render: h => h(App),
});

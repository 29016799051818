 <template>
  <div class="wrapper">
    <div>
      <h1>Gestion des admins</h1>
    </div>
    <h2>Ajout</h2>
    <div class="add-admin">
      <form @submit="search">
        <input
          v-model="form.search"
          type="email"
          autocomplete="false"
          placeholder="Chercher par email...">
      </form>
      <div v-if="user" class="admin">
        <b><span>{{ user.firstname }} {{ user.lastname }}</span></b>
        <span class="user-id">{{ user.user_id }}</span>
        <button
          v-if="user.isAdmin"
          class="button-primary button-small make-admin" disabled>Déjà admin 🤴</button>
        <button
          v-if="!user.isAdmin"
          class="button-primary button-small make-admin"
          @click="makeAdmin(user)">Passer admin 👑</button>
      </div>
    </div>
    <h2>Liste</h2>
    <!-- <div v-if="loading" class="loader">🏋️</div> -->
    <div v-if="loading">
      <i class="loading-dots">Chargement des admins</i>
    </div>
    <div class="admin" v-for="admin in admins" :key="`admin-${admin.id}`">
      <b><span>{{ admin.firstname }} {{ admin.lastname }}</span></b>
      <span class="user-id">{{ admin.id }}</span>
      <br>
      <span>{{ admin.email }}</span>
      <div class="roles">
        <span
          class="role"
          :class="isRemoving(admin.id, role) ? 'removing':''"
          v-for="role in admin.roles" 
          :key="`admin-${admin.id}-${role}`"
          @click="remove(admin, role)">
            <span>{{role}} </span>
            <span v-if="isRemoving(admin.id, role)">| enlever?</span>
        </span>
        <span
          class="edit-admin"
          v-if="!(form.editing && form.id === admin.id)"
          :value="admin.id"
          @click="edit">+ Ajouter</span>
      </div>
      <div class="edit-admin-form" v-if="form.editing && form.id === admin.id">
        <form @submit="(e) => {e.preventDefault(); save(admin);}">
          <select v-model="form.roleSelected">
            <option
              v-for="role in filterRoles(admin.roles)"
              :value="role"
              :key="role">{{ role }}</option>
          </select>
          <button class="button-primary button-small" type="submit">Valider</button>
          <a
            class="cancel-edit"
            @click="cancel">Annuler</a>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  margin-left: 50px;
}

.add-admin input[type="email"] {
  padding: 5px;
  min-width: 300px;
  margin-left: 20px;
}

.admin {
  margin: 20px;
  padding: 20px;
  width: 700px;
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 4px 4px 4px;  
}

.admin:last-of-type {
  margin-bottom: 100px;
}

.user-id {
  font-size: 12px;
  opacity: 0.7;
  margin-left: 8px;
}

button.make-admin {
  margin-left: 20px;
}

.edit-admin {
  cursor: pointer;
}

.cancel-edit {
  cursor: pointer;
  font-size: 14px;
  opacity: 0.7;
}

.edit-admin-form {
  margin-top: 10px;
}

.edit-admin-form button[type="submit"] {
  margin-right: 5px;
}

select {
  padding: 6px;
  margin-right: 10px;
}

.role {
  font-size: 14px;
  font-family: monospace;
  background: hsl(127, 100%, 97%);
  color: hsl(153, 46%, 56%);
  padding: 5px;
  margin: 5px 10px 5px 0;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.role.removing {
  background: hsla(351, 110%, 97%, 1);
  color: hsla(358, 46%, 56%, 1);
}
</style>

<script>
import * as axios from 'axios';
import { RolesList, getToken } from '../auth';
import { updateRoles, getByEmail } from '../api/users';
import config from '../config';

export default {
  data() {
    return {
      loading: true,

      admins: [],

      user: null,

      form: {
        search: '',

        editing: false,
        id: '',
        roleSelected: '',
        removing: '',
      },
    }
  },

  methods: {
    load() {
      this.loading = true;
      this.admins = [];

      axios
      .get(config.url('/api/v1/users/admin'), {
        headers: {
          authorization: getToken(),
        }
      })
      .then(res => {
        this.admins = [...res.data];
      })
      .finally(() => {
        this.loading = false;
      });
    },

    search(e) {
      e.preventDefault();

      if (!this.form.search) {
        this.user = null;
        return;
      }

      getByEmail(this.form.search)
      .then((user) => {
        this.user = user;

        const id = user.user_id;

        for (let admin of this.admins) {
          if (admin.id === id) {
            user.isAdmin = true;
            break;
          }
        }
      })
      .catch(() => {
        alert("email non trouvé");
      });
    },

    makeAdmin(user) {
      const roles = ['ROLE_USER', 'ROLE_ADMIN'];

      updateRoles(user.user_id, roles)
      .then(() => {
        this.user = null;
        this.form.search = '';
        setTimeout(() => {
          this.load();
        }, 1000);
      })
      .catch(() => {
        alert('erreur lors de la mise à jour');
      });
    },
    
    edit(event) {
      const id = event.target.getAttribute('value');

      this.form.editing = true;
      this.form.id = id;
    },

    filterRoles(against) {
      const roles = [];

      for (let role of RolesList) {
        if (against.indexOf(role) > -1) {
          continue;
        }

        roles.push(role);
      }

      return roles;
    },

    remove(admin, role) {
      if (!this.isRemoving(admin.id, role)) {
        this.form.removing = `${admin.id}.${role}`;
        return;
      }

      const roles = [
        ...admin.roles
      ].filter(e => {
        return e !== role;
      });

      updateRoles(admin.id, roles)
      .then(() => {
        admin.roles = roles;
      })
      .catch(() => {
        alert('erreur lors de la mise à jour');
      })
      .finally(() => {
        this.form.removing = '';
      });
    },

    isRemoving(id, role) {
      return this.form.removing === `${id}.${role}`;
    },

    save(admin) {
      const roles = [
        ...admin.roles,
        this.form.roleSelected,
      ];

      updateRoles(admin.id, roles)
      .then(() => {
        admin.roles = roles;
      })
      .catch(() => {
        alert("erreur lors de la mise à jour");
      })
      .finally(() => {
        this.form.editing = false;
      });
    },

    cancel() {
      this.form.editing = false;
    },
  },

  mounted() {
    this.load();
  }
}
</script>
function put(key, value) {
  localStorage[key] = JSON.stringify(value, null, 2);
}

function get(key, parse = true) {
  const value = localStorage[key];

  if (value === undefined) {
    return;
  }

  if (!parse) {
    return value;
  }

  return JSON.parse(value);
}

export {
  put,
  get,
}
<template>
  <div class="tabs-wrapper">
    <div class="tabs">
      <ul>
        <li v-for="(text, key) in list" :key="key" :class="active === key ? 'active': ''" @click="click(key)">
          <span>{{text}}</span>
        </li>
      </ul>
    </div>
    <div class="helper">
      <slot name="helper"></slot>
    </div>
  </div>
</template>

<style scoped>
.tabs-wrapper {
  display: flex;
  align-items: center;
  border-bottom: solid 3px black;
}

.tabs {
  flex-grow: 1;
}

.helper {
  flex-grow: 1;
  text-align: right;
  padding-right: 20px;
}

.helper *:first-child {
  opacity: 0.5;
  cursor: pointer;
}

.helper *:first-child:hover {
  opacity: 1;
}

ul {
  list-style-type: none;
  display: flex;
  padding: 0;
  user-select: none;
  margin-bottom: 0;
}

li {
  display: block;
  /* flex-grow: 1; */
  text-align: center;
  padding: 14px 24px;
  border: solid 3px #e8e8e8;
  border-bottom: none;
  /* border-bottom: solid 3px black; */
  opacity: 0.9;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
}

li:not(:first-child) {
  border-left: none;
}

li.active {
  /* border: none; */
  border-color: black;
  background: black;
  color: white;
  opacity: 1;
  cursor: initial;
}

li:not(.active):hover {
  background: rgba(0, 0, 0, 0.03);
  /* transform: scale(1.1); */
}
</style>

<script>
export default {
  props: {
    list: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      active: Object.keys(this.list)[0],
    }
  },

  methods: {
    click(e) {
      this.active = e;
      this.$emit('input', this.active);
    },
  }
}
</script>

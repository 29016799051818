import config from '../config';
import * as axios from 'axios';

function updateTransaction(id, data) {
  const p = new Promise((resolve, reject) => {
    axios.put(config.url(`/api/v1/transactions/${id}`), data)
    .then(() => {
      resolve();
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

export {
  updateTransaction,
};

<template>
  <Modal>
    <template v-slot:panel-content>
      <h2 class="text-center">Rembourser 💸</h2>
      <h3>
        <Product :product="orderProduct.product"></Product>
      </h3>
      <SplitTable :entity="orderProduct" :inputs="['refunds']" v-model="form.orderProduct"></SplitTable>
      <h3>
        <Delivery :delivery="orderProduct.delivery" :price="false"></Delivery>
      </h3>
      <SplitTable :entity="orderProduct.delivery" :inputs="['refunds']" v-model="form.delivery"></SplitTable>
      <div v-for="service in orderProduct.order_services" :key="service.id">
        <h3>
          <order-service :service="service" :price="false"></order-service>
        </h3>
        <SplitTable :entity="service" :inputs="['refunds']" v-model="form.orderServices[service.id]"></SplitTable>
      </div>
      <form @submit="e => e.preventDefault()">
        <div>
          <label>Notifier le client par email</label>
          <input type="checkbox" v-model="notify">
        </div>
        <DangerousButton :timeout="2" @click="confirm">
          <span>Rembourser</span>
        </DangerousButton>
      </form>
    </template>
  </Modal>
</template>

<style scoped>
form {
  margin-top: 20px;
}

button.dangerous-button {
  width: 100%;
  margin-top: 20px;
}
</style>

<script>
import * as orders from '../../api/orders';
import SplitTable from './SplitTable.vue';
import OrderService from '../../core/OrderService.vue';

export default {
  props: ['orderProduct'],

  data() {
    return {
      notify: true,
      form: {
        orderProduct: null,
        delivery: null,
        orderServices: {},
      },
    }
  },

  methods: {
    confirm() {
      const data = {
        orderProducts: [],
        deliveries: [],
        orderServices: [],
      };

      if (this.form.orderProduct) {
        data.orderProducts.push({
          currentCount: (this.orderProduct.refunds || []).length,
          id: this.orderProduct.id,
          split: {
            seller: {
              value: this.form.orderProduct.refunds.seller.value,
              currency: 'EUR',
            },
            marketplace: {
              value: this.form.orderProduct.refunds.marketplace.value,
              currency: 'EUR',
            },
          },
          toNotify: this.notify,
        });
      }

      if (this.form.delivery) {
        data.deliveries.push({
          currentCount: (this.orderProduct.delivery.refunds || []).length,
          id: this.orderProduct.delivery.id,
          split: {
            seller: {
              value: this.form.delivery.refunds.seller.value,
              currency: 'EUR',
            },
            marketplace: {
              value: this.form.delivery.refunds.marketplace.value,
              currency: 'EUR',
            },
          },
          toNotify: this.notify,
        });
      }

      for (let [id, e] of Object.entries(this.form.orderServices)) {
        data.orderServices.push({
          currentCount: (this.orderProduct.order_services || []).length,
          id,
          split: {
            seller: {
              value: e.refunds.seller.value,
              currency: 'EUR',
            },
            marketplace: {
              value: e.refunds.marketplace.value,
              currency: 'EUR',
            },
          },
          toNotify: this.notify,
        });
      }

      orders
      .createRefund(this.orderProduct.order_id, data)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        alert("échec de la création du remboursement");
      });
    }
  },

  components: {
    SplitTable,
    OrderService,
  },
}
</script>

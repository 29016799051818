var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("h1", [_vm._v("Préférences")]),
    _vm._v(" "),
    _c("form", { on: { submit: _vm.apply } }, [
      _c("div", [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.json,
              expression: "json"
            }
          ],
          attrs: {
            placeholder: "Coller ici un fichier de configuration",
            spellcheck: "false"
          },
          domProps: { value: _vm.json },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.json = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        { staticClass: "button-primary", attrs: { type: "submit" } },
        [_vm._v("Appliquer 👨‍💻")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
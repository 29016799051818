<template>
  <div v-if="available">
    <a v-for="(link, index) in links" :key="index" :href="link" target="_blank">
      <span v-if="code">{{code}}</span>
      <span v-else>Voir</span>
    </a>
  </div>
  <span v-else>-</span>
</template>

<script>
export default {
  props: [
      'delivery',
      'isSellerView'
  ],

  computed: {
    available() {
      return this.links.length > 0;
    },

    code() {
      return this.delivery.tracking_number;
    },

    links() {
      if (this.delivery.isHandledByUrbantz) {
        return [this.delivery.pickupTrackingLink, this.delivery.shippingTrackingLink].filter((link) => link !== null);
      }

      if (this.providerType === 'BROCANTE_LAB') {
        return [`https://shipping.selency.com/projects/selency/issues?utf8=✓&set_filter=1&sort=created_on&f[]=status_id&op[status_id]=o&f[]=cf_52&op[cf_52]=%3D&v[cf_52][]=${this.code}&f[]=&c[]=tracker&c[]=status&c[]=assigned_to&c[]=cf_22&c[]=cf_5&c[]=cf_6&c[]=cf_54&c[]=cf_38&c[]=cf_2&c[]=cf_21&c[]=cf_23&c[]=cf_24&c[]=cf_8&c[]=cf_9&c[]=cf_25&c[]=cf_10&c[]=cf_11&c[]=cf_12&c[]=cf_14&c[]=cf_13&c[]=cf_3&c[]=cf_4&c[]=cf_7&c[]=cf_15&c[]=cf_16&c[]=cf_17&c[]=cf_39&c[]=cf_18&c[]=cf_19&c[]=cf_48&c[]=cf_49&c[]=cf_1&group_by=&t[]=`];
      }

      const trackingLinks = this.delivery.tracking_links;

      if (trackingLinks.length === 0 && this.providerType === 'COLISSIMO') {
        return ['https://www.laposte.fr/outils/suivre-vos-envois?code='+this.code];
      }

      return trackingLinks || [];
    },
    providerType() {
      return this.delivery.delivery_offer.provider_type
      || this.delivery.delivery_offer.delivery_option ? this.delivery.delivery_offer.delivery_option.provider_type : '';
    },
  },
}
</script>

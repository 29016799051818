<template>
  <div>
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Part vendeur</th>
          <th>Part Selency</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <!-- OrderProduct -->
        <tr>
          <td>
            <b>Prix produit</b>
          </td>
          <td>
            <input v-if="editing" type="number" step="1" v-model.number="form.product.seller">
            <Price v-else :price="orderProduct.split.seller"></Price>
          </td>
          <td>
            <input v-if="editing" type="number" step="1" v-model.number="form.product.marketplace">
            <Price v-else :price="orderProduct.split.marketplace"></Price>
          </td>
          <td :class="danger('product') ? 'danger': ''">
            <Price :fixed="true" :price="total('product')"></Price>
          </td>
        </tr>
        <tr v-for="(refund, index) in orderProduct.refunds || []" :key="`refund-${index}`">
          <td>Remboursement</td>
          <td>
            <Price :price="refund.split.seller"></Price>
          </td>
          <td>
            <Price :price="refund.split.marketplace"></Price>
          </td>
          <td>
            <Price :price="sum(refund.split.seller, refund.split.marketplace)"></Price>
          </td>
        </tr>
        <tr v-for="(discount, index) in orderProduct.discounts || []" :key="`discount-${index}`">
          <td>
            <span v-if="discount.type !== 'coupon'">Turbo</span>
            <span v-else>Code promo ({{discount.code}})</span>
          </td>
          <td>
            <Price :price="discount.split.seller"></Price>
          </td>
          <td>
            <Price :price="discount.split.marketplace"></Price>
          </td>
          <td>
            <Price :price="sum(discount.split.seller, discount.split.marketplace)"></Price>
          </td>
        </tr>
        <!-- Delivery -->
        <tr>
          <td>
            <b>Prix livraison</b>
          </td>
          <td>
            <input v-if="editing" type="number" step="1" v-model.number="form.delivery.seller">
            <Price v-else :price="delivery.split.seller"></Price>
          </td>
          <td>
            <input v-if="editing" type="number" step="1" v-model.number="form.delivery.marketplace">
            <Price v-else :price="delivery.split.marketplace"></Price>
          </td>
          <td :class="danger('delivery') ? 'danger': ''">
            <Price :fixed="true" :price="total('delivery')"></Price>
          </td>
        </tr>
        <tr v-for="(discount, index) in delivery.discounts || []" :key="`delivery-discount-${index}`">
          <td>
            <span v-if="discount.type !== 'coupon'">Turbo</span>
            <span v-else>Code promo ({{discount.code}})</span>
          </td>
          <td>
            <Price :price="discount.split.seller"></Price>
          </td>
          <td>
            <Price :price="discount.split.marketplace"></Price>
          </td>
          <td>
            <Price :price="sum(discount.split.seller, discount.split.marketplace)"></Price>
          </td>
        </tr>
        <tr v-for="(refund, index) in delivery.refunds || []" :key="`delivery-refund-${index}`">
          <td>Remboursement</td>
          <td>
            <Price :price="refund.split.seller"></Price>
          </td>
          <td>
            <Price :price="refund.split.marketplace"></Price>
          </td>
          <td>
            <Price :price="sum(refund.split.seller, refund.split.marketplace)"></Price>
          </td>
        </tr>
        <!-- Order Services -->
        <template v-for="service in orderProduct.order_services">
          <tr :key="service.id">
            <td>
              <b>
                <order-service :service="service" :price="false"></order-service>
              </b>
            </td>
            <td>
              <input v-if="editing" type="number" step="1" v-model.number="form.services[service.id].seller">
              <Price v-else :price="service.split.seller"></Price>
            </td>
            <td>
              <input v-if="editing" type="number" step="1" v-model.number="form.services[service.id].marketplace">
              <Price v-else :price="service.split.marketplace"></Price>
            </td>
            <td :class="danger('service', service.id) ? 'danger': ''">
              <Price :fixed="true" :price="total('service', service.id)"></Price>
            </td>
          </tr>
          <tr v-for="(refund, index) in service.refunds || []" :key="`service-${service.id}-refund-${index}`">
            <td>Remboursement</td>
            <td>
              <Price :price="refund.split.seller"></Price>
            </td>
            <td>
              <Price :price="refund.split.marketplace"></Price>
            </td>
            <td>
              <Price :price="sum(refund.split.seller, refund.split.marketplace)"></Price>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="actions">
      <div v-if="editing">
        <div>
          <div>
            <b>Modifier la commission</b>
          </div>
          <div>
            <button
              v-for="(commission, index) in commissions"
              :key="`commission-${index}`"
              class="button-secondary"
              @click="updateCommission(commission)">
              <span>{{(commission * 100)}}%</span>
            </button>
          </div>
        </div>
        <div>
          <button class="button-secondary" @click="switchDelivery">
            <span>Basculer la livraison</span>
          </button>
        </div>
        <div class="confirm">
          <button class="button-text" @click="cancel">
            <span>Annuler</span>
          </button>
          <DangerousButton :disabled="!form.dirty" @click="confirm">
            <span>Enregistrer</span>
          </DangerousButton>
        </div>
      </div>
      <div v-else>
        <div v-if="editable">
          <button class="button-text" @click="edit">
            <span>Modifier</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
table {
  border-collapse: collapse;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
}

td, th {
  border: solid 1px #dcdcdc;
  padding: 10px 40px;
}

td.danger {
  background-color: var(--classic-pink);
}

.actions {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.confirm {
  margin-top: 20px;
}
</style>

<script>
import * as orders from '../../api/orders';
import * as sales from '../../api/sales';
import {hasRole} from '../../auth';
import OrderService from '../../core/OrderService.vue';

function rawSum(s1, s2) {
  return {
    currency: 'EUR',
    value: s1 + s2,
  };
}

function sum(split1, split2) {
  return {
    currency: 'EUR',
    value: (split1 ? split1.value || 0 : 0) + (split2 ? split2.value || 0 : 0),
  };
}

export default {
  props: ['orderProduct'],

  data() {
    return {
      editing: false,
      commissions: [0, 0.024, 0.1, 0.12, 0.15, 0.18, 0.2, 0.25],
      form: {},
      payouts: [],
    }
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.form.dirty = true;
      },
    }
  },

  computed: {
    delivery() {
      return this.orderProduct.delivery;
    },

    editable() {
      if (!(
        this.orderProduct.status === 'PENDING'
        || this.orderProduct.status === 'CONFIRMED'
        || this.orderProduct.status === 'SHIPPED'
        || this.orderProduct.status === 'DELIVERED'
      )) {
        return false;
      }

      if (this.payouts.length) {
        return false;
      }

      if (!(hasRole("ROLE_ADMIN_CHECKOUT_LIGHT") || hasRole("ROLE_ADMIN_CHECKOUT"))) {
        return false;
      }

      return true;
    },
  },

  beforeMount() {
    this.initForm();
  },

  mounted() {
    this.getPayouts();
  },

  methods: {
    sum,
    rawSum,

    initForm() {
      this.form = {
        dirty: false,
        product: {
          seller: this.orderProduct.split.seller.value,
          marketplace: this.orderProduct.split.marketplace.value,
        },
        delivery: {
          seller: this.orderProduct.delivery.split.seller.value,
          marketplace: this.orderProduct.delivery.split.marketplace.value,
        },
        services: {},
      };

      for (const [, e] of Object.entries(this.orderProduct.order_services)) {
        this.form.services[e.id] = {
          seller: e.split.seller.value || 0,
          marketplace: e.split.marketplace.value || 0,
        };
      }
    },

    edit() {
      this.editing = true;
    },

    cancel() {
      this.editing = false;
      this.initForm();
    },

    confirm() {
      this.editing = false;

      const data = {
        product_seller_split: this.form.product.seller,
        product_marketplace_split: this.form.product.marketplace,
        delivery_seller_split: this.form.delivery.seller,
        delivery_marketplace_split: this.form.delivery.marketplace,
        order_services: [],
      };

      for (const [id] of Object.entries(this.form.services)) {
        data.order_services.push({
          id,
          split: {
            seller: {
              value: this.form.services[id].seller,
              currency: 'EUR',
            },
            marketplace: {
              value: this.form.services[id].marketplace,
              currency: 'EUR',
            },
          },
        });
      }

      sales
      .updateSplits(this.orderProduct.id, data)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        alert("échec de la mise à jour");
      });
    },

    getPayouts() {
      orders.getPayouts(this.orderProduct.id).then((payouts) => {
        this.payouts = payouts;
      });
    },

    updateCommission(rate) {
      this.form.product.seller = parseFloat((this.orderProduct.price.value * (1 - rate)).toFixed(2));
      this.form.product.marketplace = parseFloat((this.orderProduct.price.value * rate).toFixed(2));
    },

    switchDelivery() {
      const seller = this.form.delivery.seller;
      this.form.delivery.seller = this.form.delivery.marketplace;
      this.form.delivery.marketplace = seller;
    },

    total(target, id) {
      if (target === 'service') {
        // console.log(this.form.services[id]);
        // return 0;
        return rawSum(this.form.services[id].seller, this.form.services[id].marketplace);
      }

      if (target === 'delivery') {
        return rawSum(this.form.delivery.seller, this.form.delivery.marketplace);
      }

      return rawSum(this.form.product.seller, this.form.product.marketplace);
    },

    danger(target, id) {
      let comp;

      if (target === 'delivery') {
        comp = sum(this.orderProduct.delivery.split.seller, this.orderProduct.delivery.split.marketplace);
      } else if(target === 'service') {
        const service = this.orderProduct.order_services.filter(e => e.id === id)[0];
        comp = sum(service.split.seller, service.split.marketplace);
      } else {
        comp = sum(this.orderProduct.split.seller, this.orderProduct.split.marketplace);
      }

      return this.total(target, id).value !== comp.value;
    }
  },

  components: {
    OrderService,
  },
}
</script>

<template>
  <div>
    <table class="data-table">
      <thead>
        <tr>
          <th
            v-for="(col, name) in cols"
            :key="`col-${name}`"
            :class="`col-head-${name}`">
              <slot :name="`col-head-${name}`" :config="col">
                <span>{{col.title || name}}</span>
              </slot>
            </th>
          <th class="col-head-actions" v-if="actionable">
            <slot :name="`col-head-actions`">
              <span>Actions</span>
            </slot>
          </th>
        </tr>
        <tr v-if="ready && this.searchable">
          <th v-for="(col, name) in cols" :key="`col-search-${name}`">
            <div v-if="col.search">
              <input
                v-if="col.search.type === 'text'"
                type="text"
                v-model="search[name]"
                :placeholder="col.search.placeholder || ''"
                @keyup.enter="submit">
              <select
                v-if="col.search.type === 'select'"
                v-model="search[name]">
                <option></option>
                <option
                  v-for="(v, k) of col.search.options"
                  :key="k"
                  :value="k">{{v}}</option>
              </select>
            </div>
          </th>
          <th v-if="actionable">
            <button class="button-text" @click="submit">🔎</button>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(row, index) in results">
          <tr v-if="row._type !== 'row-helper'" :key="`row-${index}`">
            <td v-for="(col, name) in cols" :key="`row-${index}-${name}`" :class="`col-${name}`">
              <div v-if="name === 'tracking' && 'COCOLIS' === row.delivery.delivery_offer.provider_type">
                <a
                        :href="cocolisUrl"
                        target="_blank"
                >
                  Voir
                </a>
              </div>
<!--              {{ name }}-->
              <slot :name="name" :data="col.getter ? col.getter(row) : (row[name] || '')" v-else>
                <div>{{col.getter ? col.getter(row) : (row[name] || '')}}</div>
              </slot>
            </td>
            <td v-if="actionable" class="col-actions">
              <slot name="actions" :row="row" :helper="() => showHelper(index)"></slot>
            </td>
          </tr>
          <tr v-else-if="shouldShowHelper(index - 1)" :key="`row-helper-${index}`" class="row-helper">
            <td :colspan="colspan">
              <slot :name="`row-helper`" :row="row" :rows="results" :index="(index - 1) / 2"></slot>
            </td>
          </tr>
        </template>
        <!-- <tr v-if="true">
          <td :colspan="Object.entries(cols).length + (actionable ? 1 : 0)">
            <slot name="helper-line-data">
              <span>ABC</span>
            </slot>
          </td>
        </tr> -->
        <tr v-if="noData">
          <td :colspan="colspan">
            <slot name="no-data">
              <span>No data</span>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
    <slot v-if="!ready" name="loading-status" :ready="ready" :errored="errored">
      <div class="center data-table-loading-status">
        <div v-if="!errored">
          <span>Loading</span>
        </div>
        <div v-else>
          <span>Error</span>
        </div>
      </div>
    </slot>
    <slot
      v-if="ready && this.paginate"
      name="pagination"
      :size="results.length"
      :total="pagination.total"
      :next="() => next()"
      :hasNext="hasNext"
      :back="() => back()"
      :hasBack="hasBack">
      <div class="data-table-pagination">
        <div>
          <span>{{results.length}} results</span>
        </div>
        <div>
          <span @click="loadPage">next</span>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import Mousetrap from 'mousetrap';
import * as deliveries from '../api/delivery';

export default {
  props: {
    cols: {
      type: Object,
      required: true,
    },
    data: {
      type: Function,
      required: true,
    },
    error: {
      type: Function,
      required: false,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: true,
    },
    actionable: {
      type: Boolean,
      required: false,
      default: true,
    },
    paginate: {
      type: Boolean,
      required: false,
      default: true,
    },
    scrollTop: {
      type: Boolean,
      required: false,
      default: true,
    },
    helpers: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      gen: null,
      ready: false,
      errored: false,
      results: [],
      deliveryCocolis: null,
      cocolisUrl: '',
      search: {},
      pagination: {
        perPage: 10,
        total: undefined,
        currentPage: 0,
      },
      currentRowHelper: null,
    };
  },

  methods: {
    next() {
      this.pagination.currentPage += 1;
      this.loadPage();
    },

    hasNext() {
      const remaining = this.pagination.total - (this.pagination.currentPage * this.pagination.perPage + this.results.length);
      return remaining > 0;
    },

    hasBack() {
      return this.pagination.currentPage > 0;
    },

    back() {
      this.pagination.currentPage -= 1;
      this.loadPage(true);
    },

    showHelper(index) {
      if (this.currentRowHelper === index) {
        this.currentRowHelper = null;
      } else {
        this.currentRowHelper = index;
      }
    },

    shouldShowHelper(index) {
      return this.currentRowHelper === index
      || this.helpers === 'all'
      || this.currentRowHelper === null && this.helpers === 'last' && index === this.results.length - 2;
    },

    loadPage: async function(reset = false) {
      if (!this.gen || reset) {
        this.gen = this.data(this.pagination.currentPage, this.search);
      }

      this.ready = false;
      this.errored = false;
      this.results = [];

      if (this.scrollTop) {
        window.scroll(0, 0);
      }

      await new Promise(r => setTimeout(() => r(), 50));

      try {
        const {value: res} = await this.gen.next();
        const results = [];
        for (let row of res.rows) {
          if('delivery' in row) {
            if(row.delivery.delivery_offer.provider_type === 'COCOLIS') {
              deliveries.getDelivery(row.delivery.id).then(deliveries => {
                if (Array.isArray(deliveries) && deliveries.length > 0) {
                  this.deliveryCocolis = deliveries[0];
                  this.cocolisUrl = ('/orders/' + this.$route.params.id === this.$route.fullPath) ? this.deliveryCocolis.cocolis_ride.buyer_tracking : this.deliveryCocolis.cocolis_ride.seller_tracking
                }
              })
            }
          }
          results.push(row);
          results.push({_type: 'row-helper'});
        }

        this.results = results;
        this.pagination.total = res.total;
        this.pagination.perPage = res.perPage;
        this.ready = true;
      } catch(err) {
        this.errored = true;

        if (this.error) {
          this.error(err);
        }
      }
    },

    submit() {
      this.loadPage(true);
    },
  },

  computed: {
    noData() {
      return this.ready && this.results.length === 0;
    },

    colspan() {
      return Object.entries(this.cols).length + (this.actionable ? 1 : 0);
    },
  },

  mounted: async function() {
    this.loadPage();

    Mousetrap.bind('right', () => {
      this.hasNext() && this.next();
    });

    Mousetrap.bind('left', () => {
      this.hasBack() && this.back();
    });
  },

  beforeDestroy() {
    Mousetrap.unbind('right');
    Mousetrap.unbind('back');
  },
}
</script>

import Dashboards from '../pages/Dashboards.vue'
import AdminList from '../pages/AdminList.vue'
import Orders from '../pages/Orders.vue'
import OrderProductsRefunds from '../pages/OrderProductsRefunds.vue'
import Order from '../pages/Order.vue'
import Sales from '../pages/Sales.vue'
import Sale from '../pages/sale/Sale.vue'
import Preferences from '../pages/Preferences.vue'
import StripeTool from '../pages/StripeTool.vue'

const ArchiveCategory = () =>
  import(/* webpackChunkName: "ArchiveCategory" */ '../pages/ArchiveCategory.vue')
const ProductsRedirection = () =>
  import(/* webpackChunkName: "ProductsRedirection" */ '../pages/ProductsRedirection.vue')
const TreeManagement = () =>
  import(/* webpackChunkName: "TreeManagement" */ '../pages/TreeManagement.vue')

export default [
  { path: '/dashboards', component: Dashboards },
  { path: '/admins', component: AdminList },
  { path: '/orders', component: Orders },
  { path: '/order-product-refund', component: OrderProductsRefunds },
  { path: '/orders/:id', component: Order },
  { path: '/sales', component: Sales },
  { path: '/orders/:orderId/sales/:orderProductId', component: Sale },
  { path: '/preferences', component: Preferences },
  { path: '/stripe-tool', component: StripeTool },
  { path: '/catalog/products-redirection', component: ProductsRedirection },
  { path: '/catalog/archive-category', component: ArchiveCategory },
  { path: '/catalog/menu', component: TreeManagement },
]

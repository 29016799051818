var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.editable
    ? _c("span", [_vm._v(_vm._s(_vm.tags[_vm.value] || _vm.value))])
    : _c(
        "button",
        {
          class: _vm.active ? "button-primary-alt" : "button-secondary",
          on: { click: _vm.click }
        },
        [_vm._v(_vm._s(_vm.tags[_vm.value] || _vm.value))]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
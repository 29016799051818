<template>
  <div>
    <span>{{address.first_name}}</span>
    <span>{{address.last_name}}</span>
    <br>
    <span v-if="address.company_name">
      <span>{{address.company_name}}</span>
      <br>
    </span>
    <span>
      <a :href="link" target="_blank">{{address.street_name}}</a>
    </span>
    <br>
    <span v-if="address.address_line_2">
      <span>{{address.address_line_2}}</span>
      <br>
    </span>
    <span>{{address.postcode}}</span>
    <span>{{address.city}}</span>
    <br>
    <span>{{flag(address.country)}} {{address.country}}</span>
    <br><br>
    <span v-if="address.phone_number">
      <a :href="`tel:${address.phone_number.international}`">{{address.phone_number.international}}</a>
      <br>
    </span>
    <br>
  </div>
</template>

<script>
import flag from 'country-to-emoji-flag';

export default {
  props: ['address'],

  methods: {
    flag,
  },

  computed: {
    link() {
      const search = [
        this.address.street_name,
        this.address.postcode,
        this.address.city,
        this.address.country,
      ];

      return `https://maps.google.com/?q=${search.join(',')}`;
    }
  },
}
</script>

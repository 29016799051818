var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar", class: _vm.collapsed ? "collapsed" : "" },
    [
      _c("h2", { on: { click: _vm.collapse } }, [
        !_vm.collapsed
          ? _c("img", { attrs: { id: "logo", src: "/assets/img/selency.png" } })
          : _c("img", {
              attrs: { id: "logo", src: "/assets/img/selency_chair.png" }
            }),
        _vm._v(" "),
        _vm.warning
          ? _c("div", { staticClass: "environment-warning" }, [
              _c("span", [_vm._v(_vm._s(_vm.env))])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._l(_vm.routes, function(props, route) {
        return _c("div", { key: "route-" + route }, [
          _vm.hasRoleFor(route)
            ? _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "nav-1",
                    class: _vm.selected === route ? "active" : "",
                    attrs: {
                      value: route,
                      dest: props._dest,
                      destType: props._dest_type
                    },
                    on: { click: _vm.select }
                  },
                  [_vm._v(_vm._s(_vm.collapsed ? route.split(" ")[0] : route))]
                ),
                _vm._v(" "),
                !props._dest && _vm.selected === route && !_vm.collapsed
                  ? _c(
                      "div",
                      _vm._l(props, function(props, route) {
                        return _c("div", { key: "route-" + route }, [
                          route !== "_role"
                            ? _c(
                                "a",
                                {
                                  staticClass: "nav-2",
                                  attrs: {
                                    value: route,
                                    dest: props._dest,
                                    destType: props._dest_type
                                  },
                                  on: { click: _vm.select }
                                },
                                [_vm._v(_vm._s(route))]
                              )
                            : _vm._e()
                        ])
                      }),
                      0
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ])
      }),
      _vm._v(" "),
      !_vm.collapsed
        ? _c("div", { staticClass: "footer" }, [
            _c("a", { on: { click: _vm.logout } }, [_vm._v("déconnexion")])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
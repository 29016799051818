<template>
  <div class="new-split-table">
      <div class="flex-1 title" v-if="type !== 'service'">
        <h3>{{ title }} </h3>
      </div>
      <div class="flex-1 title" v-if="type === 'service'">
        <h3>
          <order-service :service="entity" :price="false"></order-service>
        </h3>
      </div>
      
      <div class="flex-3">
        <div class="table-row header">
          <div class="row repartition"><b>Repartition</b></div>
          <div class="row initial"><b>Initial</b></div>
          <div class="row refund"><b>Remboursements</b></div>
          <div class="row post_refund"><b>Post Remb.</b></div>
        </div>
        <div class="table-row" v-for="(field, name) in fields" :key="`inline-box-${name}`">
          <div class="row repartition">
            {{ field.title }}
          </div>

          <div class="row initial" :class="`${name}`">
            <span v-if="field.initial">{{ field.initial.value }} {{ field.initial.currency }}</span>
          </div>
          <div class="row refund">
            <div v-if="field.refunds">
              <!-- <select name="remb" v-if="options" v-model="form.refunds[`${name}`].comment">
                <option value="">Aucun</option>
                <option v-for="option in options" v-bind:value="option" :key="option">
                  {{ option }}
                </option>
              </select> -->
              <input type="text" v-model.number="form.refunds[`${name}`].value">
            </div>
          </div>
          <div class="row post_refund" :class="`${name}`">
            {{ field.total.value }} {{ field.total.currency }}
          </div>
          <div class="row details" v-if="(field.discounts || []).length > 0 && field.discounts[0] && displayDetails">
            <div class="row" v-for="(discount, key) in field.discounts" :key="`discount-${key}`">
              <b class="title">- {{ discount.comment || translate('FORM.ORDER_PRODUCT.' + discount.type) }}</b>
              <span class="price">{{ discount.refund.value }} {{ discount.refund.currency }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped>
div {
  box-sizing: border-box;
}

select {
  background: #fff; 
  width: 150px;
}

input {
  padding: 5px 10px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: var(--border-radius-small);
  font-size: 15px;
  width: 80px;
}

.new-split-table {
  display: flex;
  margin-bottom: 30px;
}
.resume {
  font-weight: bold;
}

.new-split-table > .title{
  width: 80px;
  display: flex;
  align-items: center;
}

.table-row {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 2px solid #e0e0e0;
  border-collapse: collapse;
  padding-top: 5px;
  padding-bottom: 5px;
}


.row {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.table-row:nth-of-type(odd) {
  background-color:#fff;
}
    
.table-row:nth-of-type(even) {
  background-color:#efefef;
}

.repartition {
  width: 80px;
}
.initial {
  width: 80px;
}
.refund {
  width: 250px;
}
.post_refund {
  width: 100px;
}
.details {
  flex-basis: 100%;
  border-top: solid 1px #cecece;
  /* width: 95%; */
  width: 0;
  padding-left: 10px;
  margin-top: 5px;
}
.details .title {
  width: 80px;
  padding-left: 20px;
  padding-right: 20px;
}
.details .price {
  margin-left: 10px;
}

.table-row.header {
  /* background-color: #e1e1e1; */
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 8px;
}

</style>

<script>
import translator from '../../compat/translator';
import refundOptions from './refund-options.json';
import OrderService from '../../core/OrderService.vue';

function getValue(s, sub = 0) {

  return s ? {
        value: parseFloat(Number(parseFloat((s ? s.value : 0) - sub).toFixed(2)) || 0),
        currency: s.currency
      } : {
        value: 0,
        currency: 'EUR'
      }
}

export default {
  props: {
    entity: {
      type: Object,
      required: true,
    },
    refund: {
      type: Boolean,
      required: false,
      default: true
    },
    displayDetails: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    inputs: {
      type: Array,
      required: false,
      default: () => [],
    },
    headings: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  created () {
    // console.log('Service: ', this.entity);
  },
  data() {
    const data = {
      options: refundOptions[this.type],
      sumRefundable: 0,
      sumToRefund: 0,
      title: this.type === 'delivery' ? 'Livraison' : 'Produit',
      rows: {
        seller:  {
          title: 'Vendeur',
          cols: ['refunds', 'discounts', 'initial', 'total']
        },
        marketplace: {
          title: 'Selency',
          cols: ['refunds', 'discounts', 'initial', 'total']
        },
        resume: {
          title: 'Total',
          cols: ['totalByRows']
        }
      },
      cols: {
        initial: (e, t) => {
          // console.log('initial', this.sumRefundable, e.split[t].value);
          return getValue(e.split[t], this.sumRefundable);
        },
        refunds: (e, t) => (e.refunds || []).map(v => {
          let resp = getValue(v.split[t]);
          this.sumRefundable += resp.value;
          // console.log('refunds', this.sumRefundable);
          return { refund: resp, comment: e.comment }
        }),
        discounts: (e, t) => (e.discounts || []).map(v => {
          let resp = getValue(v.split[t]);
          if(resp.value == 0) {
            return false;
          }

          this.sumRefundable += resp.value;
          this.sumToRefund += resp.value;
          return { refund: resp, comment: e.comment, type: v.type }

        }).filter(arr => arr != null && arr != false ),

        totalByRows: (fields, column = 'initial') => {
          delete fields.resume;
          if(Array.isArray(column) ) {
            var result = {};
            for (let col of column) {
              result[col] = this.cols.totalByRows(fields, col);
            }

            return result;
          }

          return {
            value: Object.keys(fields).reduce((acc, e) => acc + (fields[e][column].value || (Number(e) || 0)), 0),
            currency: 'EUR'
          }
        },
        total: (fields, row) => {
          var sum = Object.keys(fields).map(field => {
            if(['seller', 'marketplace'].includes(field)) {              
              return this.cols.total(fields[field], row) || 0;
            }

            // Array type
            if(['refunds', 'discounts'].includes(field)) {
              if(fields[field].length > 0) {
                return 0;
              }

              return -fields[field].value || 0;
            }
            
            // Object type eg: {value: 0, currency: 'eur'}
            if(field === 'initial') {
              return fields[field].value || 0;
            }

            return 0;
          })
          
          this.sumRefundable = 0;

          return getValue({value: sum.reduce((acc, e) => acc + e, 0), currency: 'EUR'})
        }
      },
      form: {
      },
    };

    for (let e of this.inputs) {
      data.form[`${e}`] = {
        seller: {
          value: 0,
          comment: ''
        },
        marketplace: {
          value: 0,
          comment: ''
        },
      };
    }

    return data;
  },
  methods: {
    translate(field) {
      return translator(field);
    }
  },
  computed: {
    fields() {
      // console.log('Should');
      var fields = {}
      for (let row of Object.keys(this.rows)) {
        if(this.type === 'service' && row === 'seller') {
          continue;
        }

        fields[row] =  {
          title: this.rows[row].title,
          initial: false,
          refunds: false,
          details: {},
          total: 0
        };

        for (let fnc of this.rows[row].cols) {
          if(fnc === 'total') {
            fields[row][fnc] = this.cols[fnc](fields[row], 'row');
            continue;
          }

          if(fnc === 'totalByRows') {
            fields[row] = Object.assign({}, fields[row], this.cols[fnc](fields, ['initial', 'total']));
            continue;
          }
          var resp = this.cols[fnc](this.entity, row);
          fields[row][fnc] = resp;
        }
      }

      return fields;
    }
  },
  watch: {
    'refund': {
      deep: true,
      handler() {
        var types = this.type === 'service' ? ['marketplace'] : ['marketplace', 'seller'];
        types.map(t => {
          
          this.$set(this.fields[t], 'refunds', { value: 0,comment: 0});
          this.$set(this.fields[t], 'total', this.cols.total(this.fields[t], 'row'));

          var k = this.type !== 'service' ? types.filter(o => o !== t) : false;
          var r = k && this.fields[k].total.value < 0 ? this.fields[k].total.value : 0;

          this.form.refunds[t] = {};
          this.form.refunds[t] = this.fields[t].total.value >= 0 ? {
            value: this.fields[t].total.value < 0 ? 0 :  this.fields[t].total.value + r,
            currency: this.fields[t].total.currency || 'EUR'
          } : {
            value: this.fields[t].total.value < 0 ? 0 : this.fields[t].total.value,
            currency: this.fields[t].total.currency || 'EUR'
          };
        })

      }
    },
    'form.refunds': {
      deep: true,
      handler(o, n) {
        this.$set(this.fields.marketplace, 'refunds', { 
          value: n.marketplace.value,
          comment: n.marketplace.comment
        });

        if(this.type !== 'service') {
          this.$set(this.fields.seller, 'refunds', { 
            value: n.seller.value,
            comment: n.seller.comment
          });

          this.$set(this.fields['seller'], 'total', this.cols.total(this.fields['seller'], 'row'));
        }

        this.$set(this.fields['marketplace'], 'total', this.cols.total(this.fields['marketplace'], 'row'));
        // this.$set(this.fields[], 'resume', this.cols.totalByRows(this.fields, ['total', 'initial']));
        this.fields.resume = Object.assign({}, this.fields.resume, this.cols.totalByRows(this.fields, ['total']));

        this.$emit('input', this.form);
      },
    }
  },
  components: {
    OrderService
  },
}
</script>

<template>
  <span v-if="!editable">{{tags[value] || value}}</span>
  <button v-else
    :class="active ? 'button-primary-alt' : 'button-secondary'"
    @click="click">{{tags[value] || value}}</button>
</template>

<script>
import constants from '../constants';

export default {
  props: ['value', 'editable', 'active'],

  data() {
    return {
      tags: constants.tags,
    }
  },

  methods: {
    click() {
      this.$emit('state', {
        tag: this.value,
        active: !this.active,
      });
    },
  },
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    scopedSlots: _vm._u([
      {
        key: "panel-content",
        fn: function() {
          return [
            _c("h3", [
              _vm._v(
                "Ajouter des virements manuels (" +
                  _vm._s(_vm.orderProduct.product.title) +
                  ")"
              )
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.handleSubmit($event)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-wrap": "nowrap",
                      gap: "16px"
                    }
                  },
                  [
                    _c("div", { staticStyle: { width: "50%" } }, [
                      _c("h4", [
                        _vm._v("Seller product "),
                        _c(
                          "button",
                          {
                            staticClass: "button-secondary",
                            staticStyle: { padding: "4px 8px" },
                            attrs: { type: "button" },
                            on: { click: _vm.emptySellerProduct }
                          },
                          [_vm._v("Vider")]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            gap: "8px"
                          }
                        },
                        [
                          _c("label", [_vm._v("Prix")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sellerProduct.amount,
                                expression: "sellerProduct.amount"
                              }
                            ],
                            attrs: { type: "number", step: "any" },
                            domProps: { value: _vm.sellerProduct.amount },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sellerProduct,
                                  "amount",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", [_vm._v("ID de la transaction")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sellerProduct.providerTxId,
                                expression: "sellerProduct.providerTxId"
                              }
                            ],
                            attrs: {
                              type: "text",
                              required: !!_vm.sellerProduct.amount,
                              placeholder: "po_1234abc"
                            },
                            domProps: { value: _vm.sellerProduct.providerTxId },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sellerProduct,
                                  "providerTxId",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "50%" } }, [
                      _c("h4", [
                        _vm._v("Seller delivery "),
                        _c(
                          "button",
                          {
                            staticClass: "button-secondary",
                            staticStyle: { padding: "4px 8px" },
                            attrs: { type: "button" },
                            on: { click: _vm.emptySellerDelivery }
                          },
                          [_vm._v("Vider")]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            gap: "8px"
                          }
                        },
                        [
                          _c("label", [_vm._v("Prix")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sellerDelivery.amount,
                                expression: "sellerDelivery.amount"
                              }
                            ],
                            attrs: { type: "number", step: "any" },
                            domProps: { value: _vm.sellerDelivery.amount },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sellerDelivery,
                                  "amount",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", [_vm._v("ID de la transaction")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sellerDelivery.providerTxId,
                                expression: "sellerDelivery.providerTxId"
                              }
                            ],
                            attrs: {
                              type: "text",
                              required: !!_vm.sellerDelivery.amount,
                              placeholder: "po_1234abc"
                            },
                            domProps: {
                              value: _vm.sellerDelivery.providerTxId
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sellerDelivery,
                                  "providerTxId",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: { "font-size": "12px", "font-style": "italic" }
                  },
                  [
                    _vm._v(
                      '⚠️ Ajouter des virements manuels va annuler tous les virements vendeurs en erreur et passer le produit de la commande en "virements demandés".'
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.errorMessage
                  ? _c("p", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(_vm.errorMessage))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button-primary",
                    attrs: { type: "submit", disabled: _vm.isLoading }
                  },
                  [
                    _vm.isLoading
                      ? _c("span", [_vm._v("Ajout en cours...")])
                      : _c("span", [_vm._v("Ajouter des virements manuels")])
                  ]
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.comments.length > 0
      ? _c("div", { staticClass: "comments" }, [
          _c("table", { staticClass: "data-table" }, [
            _c("thead", [
              _c(
                "tr",
                _vm._l(_vm.cols, function(name) {
                  return _c("th", { key: "col-" + name }, [
                    _c("span", [_vm._v(_vm._s(name))])
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.comments, function(comment, index) {
                return _c("tr", { key: "row-" + index }, [
                  _vm.cols.includes("Sku")
                    ? _c("td", [_vm._v(_vm._s(comment.item.productSku))])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "td",
                    [_c("Date", { attrs: { value: comment.item.createdAt } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(comment.item.createdBy.firstName))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.truncateText(comment.item.content, 110)))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "button-text",
                        on: {
                          click: function($event) {
                            return _vm.viewCommentModal({ row: comment.item })
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 24",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M14.7 12C14.7 13.4912 13.4912 14.7 12 14.7C10.5088 14.7 9.3 13.4912 9.3 12C9.3 10.5088 10.5088 9.30001 12 9.30001C13.4912 9.30001 14.7 10.5088 14.7 12Z",
                                stroke: "#14181F",
                                "stroke-width": "2",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d:
                                  "M3 12C4.44012 8.31239 7.80243 5.70001 12 5.70001C16.1976 5.70001 19.5599 8.31239 21 12C19.5599 15.6876 16.1976 18.3 12 18.3C7.80243 18.3 4.44012 15.6876 3 12Z",
                                stroke: "#14181F",
                                "stroke-width": "2",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ])
                ])
              }),
              0
            )
          ])
        ])
      : _c("div", [
          _c("i", { staticClass: "no-data" }, [_vm._v("Aucun commentaire")])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "country", attrs: { title: _vm.iso2 || "Non défini" } },
    [_vm._v(_vm._s(_vm.iso2 ? _vm.flag : "-"))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div v-if="!loading" class="background"></div>
    <div v-if="!loading" class="login">
      <h1>Backoffice</h1>
      <div v-if="loading">
        <span>Login...</span>
      </div>
      <form v-if="!loading" action="#" @submit="handleLogin">
        <div class="error">
          <span v-if="error">
            <i>Identifiants invalides</i>
          </span>
        </div>
        <div>
          <input
            ref="email"
            v-model="email"
            type="text"
            name="selency-bo-email"
            placeholder="Email"
            autofocus>
        </div>
        <div>
          <input
            v-model="password"
            type="password"
            name="selency-bo-password"
            placeholder="Mot de passe">
        </div>
        <br>
        <div>
          <button class="button-primary" type="submit">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
.background {
  width: 100vw;
  height: 100vh;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  background: url('/assets/img/brocantelab.jpg');
}

.login {
  background: rgba(255, 255, 255, 0.8);
  max-width: 620px;
  margin: auto;
  text-align: center;
  margin-top: 100px;
  padding: 20px;
}

.error {
  height: 20px;
  margin-bottom: 20px;
  color: hsla(0, 100%, 63%, 1);
}

form div {
  margin: 10px 0;
}

form input {
  padding: 10px;
  min-width: 300px;
}
</style>

<script>
import { tryAuth, login } from './auth';

export default {

  data: () => {
    return {
      loading: true,
      error: false,

      email: '',
      password: '',
    };
  },

  mounted() {
    tryAuth().then(() => {
      setTimeout(() => {
        this.$root.$emit('auth.login');
      }, 0);
    }).catch(() => {
      this.loading = false;
    });
  },

  methods: {
    handleLogin(event) {
      event.preventDefault();

      if (!this.email || !this.password) {
        return;
      }

      login(this.email, this.password)
        .then(() => {
          this.$root.$emit('auth.login');
        })
        .catch(() => {
          this.error = true;

          this.email = '';
          this.password = '';

          this.$refs.email.focus();
        });
    }
  }
}
</script>

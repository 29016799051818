var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.target,
          expression: "target"
        }
      ],
      on: {
        change: [
          function($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function(o) {
                return o.selected
              })
              .map(function(o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.target = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
          _vm.change
        ]
      }
    },
    [
      _c("option", { domProps: { value: _vm.current } }, [
        _vm._v(_vm._s(_vm.statuses[_vm.current] || _vm.current))
      ]),
      _vm._v(" "),
      _vm._l(_vm.possibilites, function(status) {
        return _c("option", { key: status, domProps: { value: status } }, [
          _vm._v(_vm._s(_vm.statuses[status] || status))
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
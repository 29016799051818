import { render, staticRenderFns } from "./Panel.vue?vue&type=template&id=789bc3db&scoped=true&"
import script from "./Panel.vue?vue&type=script&lang=js&"
export * from "./Panel.vue?vue&type=script&lang=js&"
import style0 from "./Panel.vue?vue&type=style&index=0&id=789bc3db&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "789bc3db",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/bo/bo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('789bc3db')) {
      api.createRecord('789bc3db', component.options)
    } else {
      api.reload('789bc3db', component.options)
    }
    module.hot.accept("./Panel.vue?vue&type=template&id=789bc3db&scoped=true&", function () {
      api.rerender('789bc3db', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Panel.vue"
export default component.exports
<template>
  <Modal>
    <template v-slot:panel-content>
      <h2 class="text-center">
        <span>Transaction </span>
        <span>{{gateways[transaction.gateway] || transaction.gateway}}</span>
      </h2>
      <!-- <h3>{{transaction ? transaction.id : '-'}}</h3> -->
      <div class="form-wrapper">
        <form @submit="e => e.preventDefault()">
          <div class="main">
            <div class="side">
              <h3>Montant</h3>
              <input type="text" :value="transaction.amount.value" disabled>
              <h3>Statut</h3>
              <select disabled>
                <option>{{statuses[transaction.status] || transaction.status}}</option>
              </select>
            </div>
            <div class="side">
              <h3>Nouveau montant</h3>
              <input type="number" step="0.01" v-model="form.amount">
              <h3>Nouveau statut</h3>
              <select v-model="form.status">
                <option v-for="(status, key) of statuses" :value="key" :key="key">{{status}}</option>
              </select>
            </div>
          </div>
          <div class="submit">
            <DangerousButton @click="submit" :timeout="3" :disabled="!dirty">Mettre à jour</DangerousButton>
          </div>
        </form>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
.form-wrapper {
  display: flex;
}

form {
  width: 100%
}

form div.main {
  display: flex;
}

form div.side {
  width: 50%;
  text-align: center;
}

form div.submit {
  width: 50%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 20px;
  text-align: center;
}

input, select {
  width: 250px;
}
</style>

<script>
import constants from '../../constants';
import {updateTransaction} from '../../api/transactions';

export default {
  props: ['transaction'],

  data() {
    return {
      dirty: false,

      form: {
        amount: this.transaction.amount.value,
        status: this.transaction.status,
      },

      gateways: constants.paymentGateways,
      statuses: constants.transactionStatuses,
    };
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.dirty = true;
      }
    }
  },

  methods: {
    submit() {
      updateTransaction(this.transaction.id, {
        ...this.form,
        amount: {
          currency: this.transaction.amount.currency,
          value: parseFloat(this.form.amount),
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        alert('erreur de la mise à jour');
      });
    }
  }
}
</script>

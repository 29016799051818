export default {

  orderStatuses: {
    "paid": "Payée",
    "pending_payment": "En cours de paiement",
    "canceled": "Annulée",
    "expired": "Expirée",
  },

  orderProductStatuses: {
    "PENDING": "En attente",
    "CONFIRMED": "Disponible",
    "UNAVAILABLE": "Indisponible",
    "SHIPPED": "Expédié",
    "DELIVERED": "Délivré",
    "CASHOUT_PREPARED": "Virement disponible",
    "TRANSFER_REQUESTED": "Virement demandé",
    "TRANSFER_PROCESSED": "Virement effectué",
    "PAYOUTS-REQUESTED": "Virements demandés",
    "PAYOUTS-IN-TRANSIT": "Virements effectués",
    "ABORTED": "Annulé",
    "STAND_BY": "Mise en attente"
  },
  refundReason: {
    "Produit indisponible (Vendeur)": "Produit indisponible (Vendeur)",
    "Code promo (Selency)": "Code promo (Selency)",
    "Négociation (Vendeur)": "Négociation (Vendeur)",
    "Geste co (Vendeur)": "Geste co (Vendeur)",
    "Rétractation acheteur avant expédition": "Rétractation acheteur avant expédition",
    "Rétractation acheteur après expédition": "Rétractation acheteur après expédition",
    "Non-conformité": "Non-conformité",
    "Casse / perte Cocolis": "Casse / perte Cocolis",
    "Casse / perte Selency": "Casse / perte Selency",
    "Casse / perte Vendeur": "Casse / perte Vendeur",
    "Retard de livraison (Selency)": "Retard de livraison (Selency)",
    "Retard de livraison (Vendeur)": "Retard de livraison (Vendeur)",
    "Livraison impossible (Selency)": "Livraison impossible (Selency)",
    "Livraison impossible (Vendeur)": "Livraison impossible (Vendeur)"
  },
  refundReasonOld: {
    "Rétractation avant": "Rétractation avant exp. -24h post cmd",
    "Rétractation avant exp": "Rétractation avant exp. +24h post cmd",
    "Non-satisfaction": "Non-satisfaction",
    "Casse refusée / Perte": "Casse refusée / Perte",
    "Rétractation/Indispo": "Rétractation/Indispo",
    "Casse/perte": "Casse/perte",
    "Non-conformité": "Non-conformité",
    "Dégradation avant expédition": "Dégradation avant expédition",
    "Baisse de prix": "Baisse de prix",
    "Modération": "Modération",
    "Code promo": "Code promo",
    "Changement de livraiso": "Changement de livraison (Acheteur)",
    "Changement de livraison": "Changement de livraison (Vendeur)",
    "Report de date ": "Report de date ",
    "Livraison impossible": "Livraison impossible",
    "Délai de livraison": "Délai de livraison (Vendeur)",
    "Délai de livraiso": "Délai de livraison (Selency)",
    "Annulation délai de livraiso": "Annulation délai de livraison (Vendeur)",
    "Annulation délai de livraison": "Annulation délai de livraison (Selency)",
    "Service de livraison": "Service de livraison (Vendeur)",
    "Service de livraiso": "Service de livraison (Selency)",
    "Refus de livraison ": "Refus de livraison ",
    "Casse acceptée": "Casse acceptée"
  },

  paymentGateways: {
    "stripe": "Stripe",
    "oney-direct": "Oney",
    "oney": "Oney (via Hipay)",
    "hipay": "Hipay",
    "sepa-ct": "Virement",
    "buybox": "Buybox",
    "emv": "EMV",
    "paypal": "PayPal",
  },

  transactionStatuses: {
    "pending": "⏳ En attente",
    "success": "Validée",
    "failure": "Échouée",
    "error": "Erreur technique",
  },

  tags: {
    'Team pro': 'Commande B2B 💼',
    'fraudulent': 'Fraude 🕵️',
    'celebrity': '#celebrities 📸',
  },

};

<template>
  <div>
        <h3>📦 Livraison Colissimo <span v-if="isColissimoPSA()">PSA</span></h3>
        <div v-if="delivery">
            <div class="row" style="padding-bottom:20px">
                <div class="col-xs-5 col-xs-offset-1">

                    <p>
                        <b>Assurance : </b>
                        <span v-if="delivery.delivery_offer.insurance">✔️</span>
                        <span v-else>❌</span>
                    </p>
                    <p>
                        <b>Emballage : </b>
                        <span v-if="delivery.delivery_offer.packing_cost && delivery.delivery_offer.packing_cost.value">
                            {{ delivery.delivery_offer.packing_cost.value}} {{ delivery.delivery_offer.packing_cost.currency}}
                        </span>
                        <span v-else>❌</span>
                    </p>
                    <div>
                        <b>Packages : </b>
                        <span v-if="delivery.delivery_offer.packages.length === 0">⚠️ 0 found ⚠️</span>
                        <div v-for="pckg in delivery.delivery_offer.packages" v-bind:key="pckg">
                            -
                            <b>Depth :</b>  {{ pckg.depth }} cm
                            <b>Height :</b> {{ pckg.height }} cm
                            <b>Width :</b>  {{ pckg.width }} cm
                            <b>Weight :</b> {{ pckg.weight }} kg
                        </div>
                    </div>
                    <p>
                        <b>Destination : </b>
                        <span v-if="delivery.delivery_offer.destination_country">
                            Pays -> {{ delivery.delivery_offer.destination_country }}
                        </span>
                        <span v-else-if="delivery.delivery_offer.destination_continent">
                            Continent -> {{ delivery.delivery_offer.destination_continent }}
                        </span>
                        <span v-else>Monde</span>
                    </p>

                    <a
                        v-if="Array.isArray(delivery.tracking_links) && delivery.tracking_links.length > 0"
                        :href="delivery.tracking_links[0]"
                        target="_blank"
                    >
                        <button class="button-secondary">
                            🔎 Suivi sur le site de Laposte
                        </button>
                    </a>

                </div>

                <div class="col-xs-5" v-if="isColissimoPSA()">
                    <div class="simple-wrapper">
                        <div class="simple-label">
                            <span>PSA</span>
                        </div>
                        <div class="simple-content">
                            <p v-if="delivery.delivery_note_blocked">
                                ⚠️ Un problème a été détecté sur cette étiquette.<br>

                                <span v-if="isShippingCountryNotValid()">
                                    --> Le pays de livraison n'est pas géré par notre prestataire d'étiquette
                                </span>
                                <span v-else>
                                    Il est en cours de résolution.
                                </span>
                            </p>
                            <div v-if="!delivery.delivery_note_blocked">
                                <p>
                                    ✔️ Aucun problème détecté sur cette étiquette
                                </p>
                            </div>
                            <button class="button-secondary" @click="toogleIssueForm()" disabled>
                                📢 Remonter un problème <i>[feature à venir]</i>
                            </button>
                            <div v-if="displayIssueForm">
                                <hr>
                                <p style="font-size: 0.85em">
                                    ℹ️ Ce formulaire permet de remonter un message pour informer l'équipe technique d'un problème spécifique sur l'étiquette en question
                                </p>

                                <p>
                                    <select v-model="form.reason">
                                        <option selected hidden value=""><i>Selectionner un motif</i></option>
                                        <option >L'étiquette a expirée</option>
                                        <option >L'adresse de livraison doit être changée</option>
                                        <option >Un problème detecté sur l'étiquette n'est toujours pas résolu</option>
                                        <option >Autre</option>
                                    </select>
                                </p>

                                <textarea v-model="form.message" placeholder="Détailler ici si nécessaire"/>

                                <button class="button-secondary" @click="sendIssue()">
                                    Envoyer
                                </button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div class="row" v-if="showAdminInfo">
                <div class="col-xs-10 col-xs-offset-1">
                    <div class="simple-wrapper">
                        <div class="simple-label">
                            <span>Data helper</span>
                        </div>
                        <div class="simple-content">

                            <div class="row">
                                <div class="col-xs-5 col-xs-offset-1">
                                    <p><b>Delivery Id :</b> {{ delivery.id }}</p>
                                    <p><b>Status :</b> {{ delivery.status }}</p>
                                </div>
                                <div class="col-xs-5 col-xs-offset-1">
                                    <p><b>Delivery Offer Id :</b> {{ delivery.delivery_offer.id }}</p>
                                </div>
                            </div>
                            <div class="row" v-if="shippingDate">
                                <p class="col-xs-offset-1"><b>Date d'envoi :</b> {{ shippingDate }}</p>
                            </div>
                            <div class="row" v-if="deliveryDate">
                                <p class="col-xs-offset-1"><b>Date de livraison :</b> {{ deliveryDate }}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

  </div>
</template>

<style scoped>
.simple-wrapper {
  border: dashed 3px rgba(0, 0, 0, 0.2);
}

.simple-wrapper .simple-label {
  display: block;
  padding: 5px 10px;
  padding-bottom: 8px;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.575);
  background: rgba(0, 0, 0, 0.1);
}

.simple-wrapper .simple-content {
  padding-left: 10px;
  padding-right: 10px;
}

textarea {
  width: 100%;
  height: 100px;
  resize: none;
  font-family: monospace;
  padding: 10px;
  margin-bottom: 10px;
  overflow-y: scroll;
}
</style>

<script>

import * as deliveries from '../api/delivery';
import { hasRole } from '../auth';

export default {
    props: ['deliveryId', 'shippingAddress'],

    data() {
        return {
            delivery: null,
            showAdminInfo: hasRole("ROLE_ADMIN"),
            displayIssueForm: false,
            form : {
                reason: "",
                message: ""
            },
            tracking: [],
        }
    },

    computed: {
        deliveryDate() {
            let deliveryDate = null;
            for (const trackingStep of this.tracking) {
                if (trackingStep.status_code === 'DELIVERED') {
                    deliveryDate = (new Date(trackingStep.timestamp * 1000)).toLocaleString();
                    return deliveryDate;
                }
            }

            return deliveryDate;
        },
        shippingDate() {
            let transitDates = [];
            this.tracking.forEach(trackingStep => {
                if (trackingStep.status_code === 'IN_TRANSIT') {
                    transitDates.push(trackingStep.timestamp);
                }
            })

            if (!transitDates.length) {
                return null;
            }

            return (new Date(Math.min(...transitDates) * 1000)).toLocaleString();
        },
    },

    created() {
        deliveries.getDelivery(this.deliveryId).then(response => {
            if (Array.isArray(response) && response.length > 0) {
                this.delivery = response[0];
                if (
                    this.delivery.delivery_note
                    && this.delivery.delivery_note.metadata
                    && this.delivery.delivery_note.metadata.packlink_shipments
                    && Array.isArray(this.delivery.delivery_note.metadata.packlink_shipments.shipments)
                    && this.delivery.delivery_note.metadata.packlink_shipments.shipments[0].shipment_reference
                )
                {
                    deliveries.getPacklinkTracking(this.delivery.delivery_note.metadata.packlink_shipments.shipments[0].shipment_reference)
                        .then(response => {
                            this.tracking = response
                        });
                }
            }
        });
    },

    methods: {
        isColissimoPSA() {
            return (this.delivery && this.delivery.delivery_offer.auto_price);
        },

        isShippingCountryNotValid() {
            return (this.shippingAddress && (
                this.shippingAddress.country === "GB" ||
                this.shippingAddress.country === "CH" ||
                this.shippingAddress.country === "NC"
            ));
        },

        toogleIssueForm() {
            this.displayIssueForm = !this.displayIssueForm;
        },

        sendIssue() {
            console.log("-- send msg --");
            console.log('motif : ' + this.form.reason);
            console.log('msg : ' + this.form.message);
        }
    },
}
</script>

<template>
  <button class="button-secondary" @click="click">
    <img src="/assets/img/airtable.svg" height="16">
    <span>{{title}}</span>
  </button>
</template>

<script>
import FormModal from './FormModal.vue';

const tables = {
  'payments': {
    title: 'Problème de paiement',
    url: 'https://airtable.com/embed/shrAkHGQXCWNkI212',
  },
  'logistics': {
    title: 'Problème de logistique',
    url: 'https://airtable.com/embed/shrALsfunLVMlLIPp',
  },
  'allo-la-tech': {
    title: "#allo-la-tech",
    url: 'https://airtable.com/embed/shr6pkfNV3PUtFtPq',
  }
};

export default {
  props: ['table', 'prefill'],

  methods: {
    click() {
      const fields = {};

      for (let [key, value] of Object.entries(this.prefill || {})) {
        fields[`prefill_${key}`] = value;
      }

      const query = new URLSearchParams(fields);

      this.$modal.show('airtable-form', {
        url: `${tables[this.table].url}?${query}`,
      });
    }
  },

  computed: {
    title() {
      return tables[this.table].title;
    }
  },

  beforeMount() {
    this.$modal.register('airtable-form', FormModal);
  },
}
</script>

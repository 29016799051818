import config from '../config';
import * as axios from 'axios';

function getOrders(offset = 0, limit = 30, search = {}) {
  const p = new Promise((resolve, reject) => {
    const query = {
      ...search,
      offset,
      limit,
    };

    if (query.order_number) {
      query.reference = query.order_number;
      delete query.order_number;
    }

    const params = new URLSearchParams(query);

    axios
    .get(config.url(`/api/v1/orders?${params}`))
    .then((res) => {
      // reject();
      // return;

      resolve({
        list: res.data.slice(0, res.data.length - 1),
        total: res.data.pop(),
      });
    })
    .catch((err) => {
      reject(err.response);
    });
  });

  return p;
}

function getOrder(id) {
  const p = new Promise((resolve, reject) => {
    axios
    .get(config.url(`/api/v1/orders/${id}`))
    .then(res => {
      resolve(res.data.content);
    })
    .catch(err => {
      reject(err);
    });
  });

  return p;
}

function cancelOrderEntry(id) {
  const p = new Promise((resolve, reject) => {
    axios
    .put(config.monolithApi(`/order-entries/${id}/cancel`))
    .then(res => resolve(res && res.data && res.data.item || {}))
    .catch(err => reject(err));
  });

  return p;
}

function restoreOrderEntry(id) {
  const p = new Promise((resolve, reject) => {
    axios
    .put(config.monolithApi(`/order-entries/${id}/restore`))
    .then(res => resolve(res && res.data && res.data.item || {}))
    .catch(err => reject(err));
  });

  return p;
}

function getPayouts(id) {
  const p = new Promise((resolve, reject) => {
    axios
    .get(config.monolithApi(`/order-entries/${id}/payouts`))
    .then(res => resolve(res && res.data && res.data.items || []))
    .catch(err => reject(err));
  });

  return p;
}

function getPayoutHistory(id) {
  const p = new Promise((resolve, reject) => {
    axios
    .get(config.monolithApi(`/payouts/${id}/history`))
    .then(res => resolve(res && res.data && res.data.items || []))
    .catch(err => reject(err));
  });

  return p;
}

function createManualPayouts(id, data) {
  const p = new Promise((resolve, reject) => {
    axios
    .patch(config.monolithApi(`/order-entries/${id}/manual-payouts`), data)
    .then(res => resolve(res && res.data && res.data.items || []))
    .catch(err => reject(err.response));
  });

  return p;
}

function requestPayouts(id) {
  const p = new Promise((resolve, reject) => {
    axios
    .put(config.monolithApi(`/order-entries/${id}/request-payouts`))
    .then(res => resolve(res && res.data && res.data.item || {}))
    .catch(err => reject(err));
  });

  return p;
}

function generatePayouts(id, readyAt) {
  const p = new Promise((resolve, reject) => {
    axios
    .post(config.monolithApi(`/order-entries/${id}/payouts`), { readyAt: readyAt })
    .then(res => resolve(res && res.data && res.data.items || []))
    .catch(err => reject(err.response));
  });

  return p;
}

function getOrderProductRefund(offset = 0, limit = 30, search = {}) {
  console.log('Billionaire: ', config.url(`/api/v1/refund`));

  const params = new URLSearchParams({
    ...search,
    offset,
    limit
  });

  const p = new Promise((resolve, reject) => {
    axios
    .get(config.url(`/api/v1/refund?${params}`))
    .then((res) => {
      resolve({
        list: res.data.listing.elements,
        total: res.data.headers.count,
      });
    })
    .catch((err) => {
      reject(err.response);
    });
  });

  return p;
}

function updateOrder(id, data) {
  const p = new Promise((resolve, reject) => {
    axios
    .patch(config.url(`/api/v1/orders/${id}`), data)
    .then(() => {
      resolve();
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

function createRefund(orderId, data) {
//   {
//     'orderProducts': orderProducts,
//     'orderServices': orderServices,
//     'deliveries': deliveries
// }
  const p = new Promise((resolve, reject) => {
    axios.post(config.url(`/api/v1/orders/${orderId}/refund`), data)
    .then(() => {
      resolve();
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

function updateRefund(refundId, data) {

  const p = new Promise((resolve, reject) => {
    axios.put(config.url(`/api/v1/refund/${refundId}`), data)
    .then(() => {
      resolve();
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

function getAudit(orderId) {
  const p = new Promise((resolve, reject) => {
    axios
    .get(config.url(`/api/v1/orders/${orderId}/audit`))
    .then(res => {
      resolve(res.data.content.listing.elements);
    })
    .catch(() => {
      reject();
    })
  });

  return p;
}

function requestPayment(orderId) {
  const p = new Promise((resolve, reject) => {
    axios.post(config.url(`/api/v1/orders/${orderId}/payment-requests`))
    .then(() => {
      resolve();
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

export {
  getOrders,
  getOrder,
  getAudit,
  cancelOrderEntry,
  restoreOrderEntry,
  getPayouts,
  getPayoutHistory,
  createManualPayouts,
  requestPayouts,
  generatePayouts,
  createRefund,
  updateRefund,
  updateOrder,
  requestPayment,
  getOrderProductRefund
};

import config from '../config';
import * as axios from 'axios';

function updateStatus(data) {
  const p = new Promise((resolve, reject) => {
    axios.put(config.url(`/api/v1/products`), data)
    .then(() => {
      resolve();
    })
    .catch(() => {
      reject();
    });
  });

  return p;
}

export {
  updateStatus,
};

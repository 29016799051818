<template>
  <Modal>
    <template v-slot:panel-content>
      <p>Commentaire <small style='color: gainsboro'>#{{ orderId ? orderId : '' }}
        {{ orderProductId ? '- ' + orderProductId : '' }}</small></p>
      <textarea
        v-model='comment'
        placeholder='Ajouter un commentaire'
        rows='5'
        cols='106'
        maxlength='5000'
      ></textarea>
      <div class='global-actions'>
        <button
          :class='`button-primary ${(comment ? "btn-submit" : "btn-submit disabled")}`'
          @click.once='addComment'
          :disabled='!comment || loading'
        >
          {{ loading ? 'Envoi...' : 'Ajouter' }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
textarea {
    width: 100%;
    height: 100px;
    padding: 12px 20px;
    box-sizing: border-box;
}

.global-actions {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: right;
}

.btn-submit {
    padding: 16px 24px 16px 24px;
    width: 242px;
    height: 52px;
    left: 568px;
}
</style>

<script>
import * as monolithClient from '../../api/monolith'
import Mousetrap from 'mousetrap'

export default {
  props: {
    orderId: {
      type: String,
      required: true,
    },
    orderProductId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      comment: '',
      loading: false,
    }
  },
  methods: {
    async addComment() {
      try {
        this.loading = true
        await monolithClient.addComment(this.orderId, this.comment, this.orderProductId)
        this.$root.$emit('comment-added')
        Mousetrap.trigger('esc')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<template>
  <div v-if="shouldDisplayGeneratePayoutsButton">
    <button
      class="button-secondary"
      @click="generatePayouts(null)"
    >
      Générer les payouts
    </button>
    <button
      class="button-secondary"
      @click="generatePayoutsPlus14Days"
    >
      Générer les payouts + 14 jours
    </button>
    <p v-if="generatePayoutErrorMessage" style="color: red;">{{ generatePayoutErrorMessage }}</p>
  </div>
  <table v-else>
    <thead>
      <tr>
        <th>Statut</th>
        <th>Type</th>
        <th>Moyen de paiement</th>
        <th>Bénéficiaire</th>
        <th>Réf</th>
        <th>Montant</th>
        <th>Dispo le</th>
        <th>Prochain essai le</th>
        <th>Créé le</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody v-if="payouts.length">
      <tr
        v-for="payout in payouts"
        :key="payout.id"
      >
        <td :title="generateStatusTitle(payout)">
          {{ payout.item.status || '' }}
          <sup
            v-if="generateStatusTitle(payout)"
            :style="{
              fontSize: '10px',
              border: '1px solid red',
              borderRadius: '9999px',
              padding: '0px 2px',
            }"
          >❓</sup>
        </td>
        <td>{{ payout.item.type || '' }}</td>
        <td>{{ payout.item.provider || '' }}</td>
        <td>{{ payout.item.recipientName || '' }}</td>
        <td>
          <span v-if="payout.item.providerTransferTxId">{{ payout.item.providerTransferTxId }}</span>
          <span v-if="payout.item.providerTxId"> / {{ payout.item.providerTxId }}</span>
        </td>
        <td>{{ payout.item.price.amount || '' }} {{ payout.item.price.currency || '' }}</td>
        <td>{{ payout.item.readyAt ? new Date(payout.item.readyAt).toLocaleString(undefined,{ dateStyle: 'short', timeStyle: 'short' }) : '' }}</td>
        <td>
          <span>{{ payout.item.retryAt ? new Date(payout.item.retryAt).toLocaleString(undefined,{ dateStyle: 'short', timeStyle: 'short' }) : '' }}</span>
          <span v-if="payout.item.retryComment"> / {{ payout.item.retryComment }}</span>
        </td>
        <td>{{ payout.item.createdAt ? new Date(payout.item.createdAt).toLocaleString(undefined,{ dateStyle: 'short', timeStyle: 'short' }) : '' }}</td>
        <td>
          <button
            class="button-secondary"
            @click="openPayoutHistoryModal(payout.item.id)"
          >
            Histo.
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import * as orders from '../../api/orders';
import CashoutPaymentV2History from './CashoutPaymentV2History.vue';

export default {
  props: {
    payouts: {
      type: Array,
      default: () => ([]),
    },
    shouldDisplayGeneratePayoutsButton: {
      type: Boolean,
      default: false,
    },
    orderProductId: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      generatePayoutErrorMessage: null,
    }
  },
  mounted() {
    this.$modal.register('show-payout-history', CashoutPaymentV2History);
  },
  methods: {
    generateStatusTitle(payout) {
      const status = payout.item.status;
      const provider = payout.item.provider;
      const isPayoutTypeSupported = ['seller_product', 'seller_delivery'].includes(payout.item.type)
      let title = null;

      if (!isPayoutTypeSupported) {
        return title;
      }

      if (status === 'pending') {
        title  = 'Le payout n’a pas été réclamé par le vendeur. Vous pouvez utiliser le bouton “Demander les virements” pour le faire à sa place';
      } else if (status === 'ready') {
        if (new Date(payout.item.readyAt).getTime() > new Date().getTime()) {
          title = 'Le virement sera disponible le ' + new Date(payout.item.readyAt).toLocaleString(undefined,{ dateStyle: 'short', timeStyle: 'short' });
        } else {
          title = 'Le virement va être traité d\'ici le prochain quart d\'heure (:00, :15, :30, :45)'
        }
      } else if (status === 'processed') {
        if (provider === 'bank') {
          title = 'Le virement sera inclus dans le prochain batch à 17h';
        } else if (provider === 'stripe') {
          title = 'Le virement a été envoyé via Stripe';
        }
      } else if (status === 'settled') {
        if (provider === 'bank') {
          title = 'Le virement a été envoyé dans le batch' + payout.item.providerTxId;
        } else if (provider === 'stripe') {
          title = 'Le virement a bien été reçu par le vendeur';
        }
      } else if (status === 'error') {
        title = `Le payout n’a pas pu être envoyé: ${payout.item.retryComment}.`;

        if (payout.item.retryAt) {
          title += ` Une nouvelle tentative sera effectuée le ${payout.item.retryAt}`;
        }
      } else if (status === 'cancel') {
        title = 'Le virement a été annulé, il ne sera pas envoyé.'
      }

      return title;
    },
    generatePayouts(readyAt) {
      this.generatePayoutErrorMessage = null;

      orders.generatePayouts(this.orderProductId, readyAt)
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          this.generatePayoutErrorMessage = 'Une erreur est survenue (' + error.data.message + ')';
        });
    },
    generatePayoutsPlus14Days() {
      const readyAt = new Date();
      readyAt.setDate(readyAt.getDate() + 14);

      const dateWithoutMilliseconds = new Date(readyAt).toISOString().split('.')[0]+"Z";

      this.generatePayouts(dateWithoutMilliseconds);
    },
    openPayoutHistoryModal(payoutId) {
      this.$modal.show('show-payout-history', { payoutId }); 
    },
  },
}
</script>

<style scoped>
table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0px;
}

td, th {
  padding: 14px 4px;
  text-align: center;
  overflow-wrap: break-word;
}

thead {
  border-bottom: 1px solid gray;
}

tbody tr {
  border-bottom: 1px solid gray;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

tbody tr:last-child {
  border-bottom: none;
}
</style>
<template>
  <span class="tip" :title="text">?</span>
</template>

<style scoped>
span.tip {
  display: inline-block;
  background: #e0e0e0;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 18px;
  font-size: 13px;
  font-weight: bold;
  color: #a2a2a2;
  user-select: none;
  cursor: help;
}
</style>

<script>
export default {
  props: ['text'],
}
</script>
import * as axios from 'axios'

const state = {
  locale: 'fr',
  displayTreePopin: null,
  formType: null,
  itemUrlType: null,
  loading: false,

  loadingSlider: false,
  currentCategory: null,
}

// getters
const getters = {
  isDisplayPopinTree: state => {
    return state.displayTreePopin
  },
  getFormType: state => {
    return state.formType
  },
  getItemUrlType: state => {
    return state.itemUrlType
  },
  getFormContent: state => {
    return state.form
  },
}

// actions
const actions = {
  // displaySidebar: ({ dispatch, commit, state }, isDisplay) => {
  //   commit('commitDisplaySidebar', { isDisplay });
  // },

  getItemsMenu: ({ dispatch, commit, state }, isDisplay) => {
    commit('commitItemsMenu', { isDisplay })
  },
}

// mutations
const mutations = {
  commitDisplaySidebar(state, isDisplay) {
    state.isDisplaySidebar = isDisplay
  },
  displayTreePopin(state, isDisplay) {
    state.displayTreePopin = isDisplay
  },
  setFormType(state, type) {
    state.formType = type
  },
  setItemUrlType(state, type) {
    state.itemUrlType = type
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

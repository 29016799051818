<template>
    <div>
        <div class="row margin-top-update-delivery">
            <div class="col-xs-12 col-md-6">
                <h4>Split</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <div class="bg-color-grey">
                    <p class="label inline-block">
                        Acheteur
                    </p>
                    <p class="label inline-block price-right">
                        {{ getSplitBuyer() }} €
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <div>
                    <p class="label inline-block">
                        Vendeur
                    </p>
                    <p class="label inline-block price-right">
                        {{ getSplitSeller() }} €
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <div class="bg-color-grey">
                    <p class="label inline-block">
                        Selency
                    </p>
                    <p class="label inline-block price-right">
                        {{ getSplitMarketPlace() }} €
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.inline-block {
    display: inline-block;
}

.label {
    padding: 10px;
    margin: 2px;
}
</style>

<script>

export default {
  props: {
    entity: {
      type: Object,
      required: true,
    },
    deliveryFrom: {
      type: Boolean
    },
  },

  methods: {
    getSplitSeller() {
      if(this.isDeliveryMarketPlace()) {
        return 0 - this.getDeliveryPriceTurbo()
      }

      if(this.isCocolisProvider()) {
          return 0
      }

      return this.getPriceDelivery()
    },
    getSplitBuyer() {
      if(this.isDeliveryMarketPlace()) {
        if(this.isHandling()) {
            return 0 - ((this.getPriceDelivery() + this.getDeliveryPriceTurbo()) - this.getDeliveryPriceTurbo() + this.getHandling())
        }
        return 0 - ((this.getPriceDelivery() + this.getDeliveryPriceTurbo()) - this.getDeliveryPriceTurbo())
      }

      if(this.isCocolisProvider()) {
          return 0 - this.getPriceDelivery()
      }

      return 0 - this.getSplitSeller();
    },
    getSplitMarketPlace() {
      if(this.isDeliveryMarketPlace()) {
          if(this.isHandling()) {
              return this.getPriceDelivery() + this.getDeliveryPriceTurbo() + this.getHandling()
          }
          return this.getPriceDelivery() + this.getDeliveryPriceTurbo()
      }

      if(this.isCocolisProvider()) {
          return this.getPriceDelivery()
      }

      return 0
    },
    isDeliveryMarketPlace() {
      return this.deliveryFrom
    },
    isCocolisProvider() {
      return 'COCOLIS' === this.entity.delivery.delivery_offer.provider_type
    },
    isTurboActivated() {
      return this.entity.delivery.delivery_offer.booster
    },
    getDeliveryPriceTurbo() {
      if(this.isTurboActivated()) {
        return this.entity.delivery.delivery_offer.booster.price.value
      }

      return 0
    },
    getPriceDelivery() {
      return this.entity.delivery.delivery_offer.price.value
    },

    isHandling() {
      var data = false
      if(this.entity.order_services && this.entity.order_services.length > 0) {
        this.entity.order_services.forEach(function (element) {
        if(element.delivery_service.name === 'HANDLING') {
          data = true
        }
      })
    }

    return data
    },

    getHandling() {
      var data = 0
      if(this.entity.order_services && this.entity.order_services.length > 0) {
        this.entity.order_services.forEach(function (element) {
          if(element.delivery_service.name === 'HANDLING') {
            data = element.price.value
          }
        })
      }

      return data
    },
  },
}
</script>

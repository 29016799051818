<template>
  <span>
    <!-- <span>{{offer.id}}</span> -->
    <span>{{title}} :</span>
    <Price :price="offer.price"></Price>
  </span>
</template>

<style scoped>

</style>

<script>
import label from './label';

export default {
  props: {
    offer: {
      type: Object,
      required: true,
    }
  },

  computed: {
    title() {
      try {
        const title = label(this.offer);
        console.log(this.offer, title)

        return title;
      } catch (e) {
        console.log(e);

        return "Titre invalide";
      }
    }
  },
}
</script>

<template>

  <div class='frame'>
    <div class='all_dashboard'>

      <!-- LOOP OVER ALL GROUP -->
      <div class='group' v-for="group in groups" v-bind:key="group">

        <div class='groupTitle font'>{{ group.name }}</div>

        <!-- CREATE GRID COMPONENT -->
        <div class='grid_container'>

          <!-- LOOP THROUGH EACH DASHBOARDS WITHIN GROUP -->
          <div v-for="dashboard in getGroup(group.code_name)" v-bind:key="dashboard">

            <!-- DISPLAY CARD INFOS -->
            <a class='card_link' :href="dashboard.link" target="_blank">
              <div class="card">
                  <!--TOP -->
                  <div class='top_info_card'>
                    <div class='title_card font'>{{ dashboard.name }}</div>
                    <img class='logo_card' :src="'/assets/img/' + dashboard.image + '.svg'">
                  </div>
                  <!--BOTTOM -->
                  <div class="bottom_info_card">
                    <div class='team_text font'>Team: {{ dashboard.team }}</div>
                  </div>
              </div>
            </a>

          </div>

        </div>

      </div>

      <!--FOOTER -->
      <div class="bottom_bar">
        <span class='bottom_bar_font font'>Selency 2022</span>
        <span class='bottom_bar_font font'>Created with
          <span style="color: #e25555;">&#9829;</span>
        </span>
        <a class='font caption airtable_link' href="https://airtable.com/shrZMxziWfl48eC9L" target="_blank">You have a question?</a>
      </div>

    </div>
  </div>

</template>

<script>

  export default {
    data() {
      return {

        groups:
        [
          {
             "id":"01",
             "name": "",
             "code_name": "groupGeneral"
          },
          {
             "id":"02",
             "name": "MARKETING",
             "code_name": "groupMarketing"
          },
          {
             "id":"03",
             "name": "SERVICE PRO",
             "code_name": "groupServicePro"
          },
          {
             "id":"04",
             "name": "SOURCING",
             "code_name": "groupSourcing"
          },
          {
             "id":"05",
             "name": "LOGISTICS",
             "code_name": "groupLogistics"
          },
          {
             "id":"05",
             "name": "CUSTOMER HAPPINESS",
             "code_name": "groupCusto"
          },
          {
             "id":"05",
             "name": "OPERATIONS",
             "code_name": "groupOperations"
          },
          {
             "id":"06",
             "name": "SELECTION",
             "code_name": "groupSelection"
          },
          {
             "id":"07",
             "name": "RETAIL & BOOK",
             "code_name": "groupRetailAndBook"
          },
          {
             "id":"08",
             "name": "PRODUCT",
             "code_name": "groupProduct"
          },
          {
             "id":"09",
             "name": "AB TEST",
             "code_name": "groupABTest"
          },
          {
             "id":"10",
             "name": "TECH",
             "code_name": "groupTech"
          },
        ],

        dashboards :
        [
          // GENERAL
          {
             "id":"01",
             "name": "GENERAL",
             "link": "https://datastudio.google.com/u/0/reporting/1UtOtoJ671opK_FptNlOToF5XhfKywIcg/page/JtnY",
             "image": "team",
             "team": "Everyone",
             "group": "groupGeneral"
          },
          {
             "id":"01",
             "name": "CONVERSION RATE",
             "link": "https://lookerstudio.google.com/u/0/reporting/873fdfcd-4968-4860-9887-ae7e7f4e5568/page/jUPuB",
             "image": "funnel",
             "team": "Everyone",
             "group": "groupGeneral"
          },
          {
             "id":"01",
             "name": "ARTISANAT",
             "link": "https://lookerstudio.google.com/u/0/reporting/286ab74e-7160-474c-be42-8b6043f4733f/page/jUPuB",
             "image": "heart",
             "team": "Everyone",
             "group": "groupGeneral"
          },
          {
             "id":"01",
             "name": "MOBILE APP",
             "link": "https://datastudio.google.com/u/0/reporting/df7c63e0-8b0d-40a1-a300-b64637a2f48a/page/OZ09",
             "image": "iphone",
             "team": "Everyone",
             "group": "groupGeneral"
          },
          {
             "id":"01",
             "name": "COHORTS",
             "link": "https://datastudio.google.com/u/0/reporting/6711c93e-59b9-4c72-ab0f-cddb768a1633/page/jUPuB",
             "image": "cohort",
             "team": "Everyone",
             "group": "groupGeneral"
          },
          {
             "id":"02",
             "name": "CARBON FOOTPRINT",
             "link": "https://datastudio.google.com/u/0/reporting/4691d004-151a-495b-8dee-3c50769e1068/page/pfzqB",
             "image": "carbon",
             "team": "Everyone",
             "group": "groupGeneral"
          },
          {
             "id":"04",
             "name": "COMMISSION OPERATIONS",
             "link": "https://datastudio.google.com/u/0/reporting/d92c9617-56f4-4dba-96b6-d654c37c9de8/page/YgetB",
             "image": "commission",
             "team": "Everyone",
             "group": "groupGeneral"
          },

          // MARKETING
          {
             "id":"03",
             "name": "PAID MARKETING",
             "link": "https://datastudio.google.com/u/0/reporting/8ac57d1b-59ad-48e7-8050-a6fd06af85b5/page/OZ09",
             "image": "pie",
             "team": "Growth",
             "group": "groupMarketing"
          },
          {
             "id":"04",
             "name": "CRM",
             "link": "https://lookerstudio.google.com/u/0/reporting/e899855b-8497-4669-9247-15cefdeaad15/page/jUPuB",
             "image": "alert",
             "team": "Growth / Product",
             "group": "groupMarketing"
          },
          // SERVICE PRO
          {
             "id":"08",
             "name": "SERVICE PRO",
             "link": "https://lookerstudio.google.com/u/1/reporting/68361734-ba4a-48f4-bdb6-1b9fb439a274/page/xtzqB",
             "image": "growth",
             "team": "Service Pro",
             "group": "groupServicePro"
          },
          {
             "id":"08",
             "name": "SELENCY PRO",
             "link": "https://datastudio.google.com/u/0/reporting/8e4f6051-265d-42b7-a8f3-276950c0dcfb/page/YgetB",
             "image": "growth",
             "team": "Service Pro",
             "group": "groupServicePro"
          },
          {
             "id":"08",
             "name": "B2B OPÉRATIONNEL",
             "link": "https://lookerstudio.google.com/u/1/reporting/2411527b-c10c-40be-9ab5-7bb78aa9b697/page/p_s7ke707zbd",
             "image": "star",
             "team": "Service Pro",
             "group": "groupServicePro"
          },
          {
             "id":"09",
             "name": "PRO DELIVERY LABEL",
             "link": "https://pro-delivery-label-sz5o7gxxuq-od.a.run.app/",
             "image": "email",
             "team": "Service Pro / Logistics",
             "group": "groupServicePro"
          },

          // SOURCING
          {
             "id":"05",
             "name": "SOURCING",
             "link": "https://datastudio.google.com/u/0/reporting/1WHdlYHw1MFtIlubXTMDWHVLJrqwkQSOA/page/gVBX",
             "image": "cube",
             "team": "Sales",
             "group": "groupSourcing"
          },
          {
             "id":"06",
             "name": "SELLER PERF",
             "link": "https://datastudio.google.com/u/0/reporting/1IN-CD52bkdNEEdJz6wUJe4k6LTw88aHu",
             "image": "indicator",
             "team": "Sales",
             "group": "groupSourcing"
          },
          {
             "id":"07",
             "name": "TURBO",
             "link": "https://datastudio.google.com/u/0/reporting/1ZkbZaXtdNEyG_03OfPG4MI_l8Om35bCr/page/DdVXB",
             "image": "lighting",
             "team": "Sales",
             "group": "groupSourcing"
          },
          {
             "id":"07",
             "name": "AM INBOUND",
             "link": "https://datastudio.google.com/u/0/reporting/d10af037-c5ce-41fd-b480-51636f1d04ba/page/OZ09",
             "image": "star",
             "team": "Sales",
             "group": "groupSourcing"
          },
          {
             "id":"07",
             "name": "AM INBOUND INDIV",
             "link": "https://datastudio.google.com/u/0/reporting/710edeb3-879e-402b-9387-e055f5ad6e5e/page/OZ09",
             "image": "star",
             "team": "Sales",
             "group": "groupSourcing"
          },

          // LOGISTICS
          {
             "id":"09",
             "name": "LOGISTIC",
             "link": "https://datastudio.google.com/u/0/reporting/1SLt2dE8SoMorXpctfLa20BA_qdmIAGTX/page/JtnY",
             "image": "truck",
             "team": "Logistics",
             "group": "groupLogistics"
          },
          {
             "id":"10",
             "name": "LOGISTIC DISPATCH",
             "link": "https://datastudio.google.com/u/0/reporting/b89d9fb1-c654-4c76-b596-59652b847323/page/DdVXB",
             "image": "shippers",
             "team": "Logistics",
             "group": "groupLogistics"
          },
          {
             "id":"10",
             "name": "SHIPMENT ORGANIZATION",
             "link": "https://datastudio.google.com/u/0/reporting/1c641355-64d6-4af8-b75a-a8e1471ad76d/page/bcsrB",
             "image": "truck",
             "team": "Logistics",
             "group": "groupLogistics"
          },
          {
             "id":"10",
             "name": "DELIVERY QUOTATION",
             "link": "https://datastudio.google.com/u/0/reporting/e11e003a-602e-46a1-a19c-00ce879c344f/page/YgetB",
             "image": "truck",
             "team": "Logistics",
             "group": "groupLogistics"
          },
          {
             "id":"10",
             "name": "LOG PERF INDIV",
             "link": "https://datastudio.google.com/u/0/reporting/83b05c2e-c78d-4061-a0ee-0641eccb7eac/page/YgetB",
             "image": "truck",
             "team": "Logistics",
             "group": "groupLogistics"
          },
          {
             "id":"18",
             "name": "COCOLIS",
             "link": "https://datastudio.google.com/u/0/reporting/824dab7c-ce16-4427-aabb-ffd84e27600e/page/YgetB",
             "image": "cocolis",
             "team": "Logistics",
             "group": "groupLogistics"
          },

          // CUSTOMER HAPPINESS
          {
             "id":"14",
             "name": "OPS INBOUND",
             "link": "https://datastudio.google.com/u/0/reporting/1rMC937tb2RUbNiidSEh2ugtnrLE2dFpA/page/OZ09",
             "image": "star",
             "team": "Customer Happiness",
             "group": "groupCusto"
          },
          {
             "id":"15",
             "name": "OPS INBOUND INDIV",
             "link": "https://datastudio.google.com/u/0/reporting/1yNoqYbWC1_uWWBUndp5GtO8fo69gBqeq/page/OZ09",
             "image": "star",
             "team": "Customer Happiness",
             "group": "groupCusto"
          },

          // OPERATIONS
          {
             "id":"16",
             "name": "REFUNDS",
             "link": "https://datastudio.google.com/u/0/reporting/c39922ba-1d3c-4621-9143-8182a6b3db8b/page/LjIGC",
             "image": "refunds",
             "team": "Ops",
             "group": "groupOperations"
          },
          {
             "id":"16",
             "name": "AVIS VÉRIFIÉS",
             "link": "https://datastudio.google.com/u/0/reporting/d13af837-0a6d-4fa4-8252-1c8c3459402d/page/uiA1B",
             "image": "star",
             "team": "Ops",
             "group": "groupOperations"
          },

          // SELECTION
          {
             "id":"13",
             "name": "SELECTION",
             "link": "https://datastudio.google.com/u/0/reporting/b37e5a4c-29ac-4a08-a326-6cc501c043fd/page/JtnY",
             "image": "check",
             "team": "Selection",
             "group": "groupSelection"
          },
          {
             "id":"13",
             "name": "CATEGORIES",
             "link": "https://datastudio.google.com/u/0/reporting/fa901b91-e9a3-4e25-b6a8-bda5f62cee31/page/BZeDB",
             "image": "categories",
             "team": "Selection",
             "group": "groupSelection"
          },
          {
             "id":"18",
             "name": "CAT MERCH",
             "link": "https://datastudio.google.com/u/0/reporting/0ed8771b-bb16-4d12-b794-471b4eddcd5d/page/YgetB",
             "image": "categories",
             "team": "Selection",
             "group": "groupSelection"
          },
          {
             "id":"31",
             "name": "VISUAL DASHBOARD",
             "link": "https://visual-dashboards-sz5o7gxxuq-ew.a.run.app",
             "image": "eye_pink",
             "team": "Selection",
             "group": "groupSelection"
          },
          {
             "id":"31",
             "name": "VISUAL DASHBOARD - PICTURES",
             "link": "https://visual-dashboard-add-pictures-sz5o7gxxuq-ew.a.run.app",
             "image": "add_picture",
             "team": "Selection",
             "group": "groupSelection"
          },

          // BHV & RETAIL
          {
             "id":"17",
             "name": "BHV",
             "link": "https://datastudio.google.com/u/0/reporting/1PimbXVsr0RYBSvVD5TyOGdjI3mmo7axb/page/xxum",
             "image": "bag",
             "team": "BHV",
             "group": "groupRetailAndBook"
          },
          {
             "id":"17",
             "name": "RETAIL",
             "link": "https://datastudio.google.com/u/0/reporting/7c88f76f-a9ae-47b6-a4cb-e073a770566c/page/YgetB",
             "image": "bag",
             "team": "BHV",
             "group": "groupRetailAndBook"
          },
          {
             "id":"25",
             "name": "GOODIES SELENCY",
             "link": "https://datastudio.google.com/u/0/reporting/1u__GapyEj5-QfDwWy81C-YL5KRuKnD9x/page/gVBX",
             "image": "cost",
             "team": "Logistics",
             "group": "groupRetailAndBook"
          },

          // PRODUCT
          {
             "id":"18",
             "name": "SERVICE FEES",
             "link": "https://datastudio.google.com/u/0/reporting/d71d54a8-9931-443f-b3ac-397012295bfc/page/pfzqB",
             "image": "cost",
             "team": "Product",
             "group": "groupProduct"
          },
          {
             "id":"18",
             "name": "SAVE A SEARCH",
             "link": "https://lookerstudio.google.com/u/0/reporting/366da80c-67db-4982-af9a-7d8e3e45ab99/page/jUPuB",
             "image": "alert",
             "team": "Product",
             "group": "groupProduct"
          },
          {
             "id":"18",
             "name": "ACCESSIBILITY",
             "link": "https://datastudio.google.com/u/0/reporting/ea110250-7eec-4fa7-8486-2a57bda9f592/page/jUPuB",
             "image": "cost",
             "team": "Product",
             "group": "groupProduct"
          },
          {
             "id":"18",
             "name": "PRIVATE MESSAGING",
             "link": "https://datastudio.google.com/u/0/reporting/15xN0cZoZSbghaHOkWuRFUfEbGbB3msNI/page/JtnY",
             "image": "messages",
             "team": "Product",
             "group": "groupProduct"
          },
          {
             "id":"18",
             "name": "PSA",
             "link": "https://datastudio.google.com/u/0/reporting/1eTY0dvGA9GWqW7bGHcsp-hktkjwMwYYN/page/UompB",
             "image": "postal_shipping",
             "team": "Product",
             "group": "groupProduct"
          },

          // DATA
          {
             "id":"26",
             "name": "BIGQUERY",
             "link": "https://datastudio.google.com/u/0/reporting/9f5ff7c9-68e6-4429-adbf-bbe033e10e0b/page/JtnY",
             "image": "bigquery",
             "team": "Data",
             "group": "groupTech"
          },
        ]
      }
    },
    methods: {
      getGroup: function(group_name) {
        return this.dashboards.filter(d => d.group === group_name)
      }
    }
  }

</script>

<style scoped>

body {
  margin: 0;
  padding: 0;
  background-color: #FFFFFF;
}

.frame {
  padding: 10px 20px 20px 20px;
  background-color: #FFFFFF;
}

.all_dashboard {
  background-color: #FFFFFF;
}

.font {
  font-family: 'Be Vietnam';
}

.group {
  margin-bottom: 20px;
}

.groupTitle {
  color: #000;
  font-weight: 800;
  margin-bottom: 10px;
}

.caption {
  text-transform: uppercase;
}

a {
  color: #000;
  text-decoration: none;
}

a:visited {
  color: #000;
  text-decoration: none;
}

a:focus {
  color: #000;
  text-decoration: none;
}

.all_dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottom_bar {
  background-color: #FFF;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px 0px 30px;
  bottom: 0px;
}

.bottom_bar_font {
  font-size: 12px;
}

#logo {
  width: 115px;
  height: 30px;
}

.airtable_link{
  background-color: none;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  transition: all 0.2s;
}

.airtable_link:hover {
  background-color: #DFC28D;
  border-radius: 5px;
  padding: 10px;
  color: #FFF;
}

.grid_container {
  display: grid;
  /* grid-template-columns: auto auto; */
  grid-template-columns: repeat( auto-fill, minmax(260px, 5fr) );
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-left: 10px;
}
.card {
  width: auto;
  height: auto;
  font-size: 26px;
  text-align: center;
  background: #F3F3F3;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s;
}

.card:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.top_info_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.title_card {
  width: 160px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  vertical-align: middle;
  display: flex;
  text-align: left;
  color: #434343;
}

.logo_card{
  width: 50px;
  height: 50px;
}

.bottom_info_card {
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.team_text {
  font-style: normal;
  color: #434343;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
}

.refresh_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #AAAAAA;
}

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        on: {
          change: function($event) {
            return _vm.emitData()
          }
        }
      },
      [
        _c("div", { staticClass: "row margin-top-label" }, [
          _c("div", { staticClass: "col-xs-12 col-md-6" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v("\n                    Titre\n                ")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.title,
                  expression: "form.title"
                }
              ],
              attrs: { type: "text", placeholder: "Titre" },
              domProps: { value: _vm.form.title },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "title", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-md-6" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v("\n                    Prix\n                ")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.price,
                  expression: "form.price"
                }
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.form.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "price", $event.target.value)
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row margin-top-label" }, [
          _c("div", { staticClass: "col-xs-12 col-md-4" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v(
                "\n                    Continent de livraison\n                "
              )
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.continent,
                    expression: "form.continent"
                  }
                ],
                attrs: { name: "continent", id: "continent" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form,
                      "continent",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v("--Please choose an option--")
                ]),
                _vm._v(" "),
                _vm._l(_vm.continents, function(continent, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: index } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(continent) +
                          "\n                    "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-md-4" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v(
                "\n                    Pays de livraison\n                "
              )
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.country,
                    expression: "form.country"
                  }
                ],
                attrs: { name: "country", id: "country" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form,
                      "country",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v("--Please choose an option--")
                ]),
                _vm._v(" "),
                _vm._l(_vm.countries, function(country, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: index } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(country) +
                          "\n                    "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-md-4" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v(
                "\n                    Région de livraison\n                "
              )
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.region,
                    expression: "form.region"
                  }
                ],
                attrs: { name: "region", id: "region" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form,
                      "region",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v("--Please choose an option--")
                ]),
                _vm._v(" "),
                _vm._l(_vm.regions, function(region, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: index } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(region) +
                          "\n                    "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <!-- <div
      v-for="(transaction, index) in transactions"
      :key="index">
      <span>{{transaction.id}}</span>
    </div> -->
    <DataTable
      :data="getData" :cols="cols"
      :searchable="false"
      :actionable="true"
      :paginate="false">
        <template v-slot:amount="col">
          <Price :price="col.data"></Price>
        </template>

        <template v-slot:txn="col">
          <a :href="txnLink(col.data)" target="_blank">{{col.data.txn}}</a>
        </template>

        <template v-slot:created_at="col">
          <Date :value="col.data"></Date>
        </template>

        <template v-slot:type="col">
          <span>{{col.data}}</span>
        </template>

        <template v-slot:gateway="col">
          <span>{{gateways[col.data] || col.data}}</span>
        </template>

        <template v-slot:actions="data">
          <button
            class="button-secondary"
            v-if="editable(data.row)"
            @click="open(data.row)">Modifier</button>
          <span v-else>-</span>
        </template>
      </DataTable>
  </div>
</template>

<script>
import DataTable from '../data-table/DataTable.vue';
import TransactionModal from './order/TransactionModal.vue';
import constants from '../constants';

export default {
  props: ['transactions'],

  components: {
    DataTable,
  },

  data() {
    return {
      gateways: constants.paymentGateways,
      cols: {
        status: {
          title: "Statut",
        },
        id: {
          title: "Id",
        },
        txn: {
          title: "Ref",
          getter(row) {
            return {
              txn: row.txn,
              gateway: row.gateway,
            };
          }
        },
        type: {
          title: "Type",
        },
        amount: {
          title: "Montant",
        },
        created_at: {
          title: "Date",
        },
        gateway: {
          title: "Moyen de paiement",
        },
      },
    };
  },

  beforeMount() {
    this.$modal.register('transaction', TransactionModal);
  },

  methods: {
    getData: async function* () {
      yield {
        rows: this.transactions,
      };
    },

    txnLink(data) {
      if (data.gateway === 'stripe') {
        return `https://dashboard.stripe.com/search?query=${data.txn}`;
      }
    },

    editable(transaction) {
      return ['pending', 'error', 'failure'].indexOf(transaction.status) > -1;
    },

    open(transaction) {
      this.$modal.show('transaction', {
        transaction,
      });
    },
  },
}
</script>


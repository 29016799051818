var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tabs-wrapper" }, [
    _c("div", { staticClass: "tabs" }, [
      _c(
        "ul",
        _vm._l(_vm.list, function(text, key) {
          return _c(
            "li",
            {
              key: key,
              class: _vm.active === key ? "active" : "",
              on: {
                click: function($event) {
                  return _vm.click(key)
                }
              }
            },
            [_c("span", [_vm._v(_vm._s(text))])]
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "helper" }, [_vm._t("helper")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="user-wrapper">
    <div :class="hasRole('ROLE_ADMIN') ? 'admin':'user'">
      <!-- <span>{{user.user_id}}</span> -->
      <div>
        <a :href="`/#!/clients/list/edit/${safeUser.user_id}`">
          <span v-if="show('firstname')">{{safeUser.firstname}} </span><span v-if="show('lastname')">{{safeUser.lastname}}</span>
        </a>
      </div>
      <div>
        <span v-if="full && safeUser.email">{{safeUser.email}}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.user-wrapper {
  display: inline-block;
  /* padding: 4px 8px; */
  /* color: var(--classic-rust);
  border: solid 1px var(--classic-rust);
  border-radius: var(--border-radius-small); */
}

.admin {
  color: var(--classic-rust);
}

.admin a {
  text-decoration: none;
}
</style>

<script>
import {getUser} from '../api/users';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    only: {
      type: Array,
      required: false,
    },
    full: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      safeUser: this.user,
    };
  },

  methods: {
    show(field) {
      if (!this.only) {
        return true;
      }

      return this.only.indexOf(field) > -1;
    },

    hasRole(role) {
      if (!this.safeUser.roles) {
        return false;
      }

      return this.safeUser.roles.indexOf(role) > -1;
    },
  },

  async mounted() {
    if (!this.safeUser.firstname === undefined || !this.safeUser.user_id) {
      return;
    }

    try {
      const user = await getUser(this.safeUser.user_id);
      this.safeUser = user;
    } catch(e) {}
  },
}
</script>
